@use "colors-legacy" as *;

$box_size: 15px;

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &:hover {
    input ~ .checkmark {
      background: darken($very_light_grey, 8);
    }

    .checkmark:after {
      border: solid $medium_blue;
      border-width: 0 3px 3px 0;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  .checkbox {
    position: relative;
    height: $box_size;
    width: $box_size;
    margin: 0;
    display: block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
      border: 1px solid $slightly_light_blue;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    input:checked ~ .checkmark,
    input:hover ~ .checkmark {
      background-color: $slightly_light_blue;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: $box_size;
      width: $box_size;
      background-color: $color_white;
      border-radius: 3px;
      border: 1px solid $slightly_light_blue;

      &:after {
        content: '';
        position: absolute;
        display: none;

        left: 4px;
        top: 1px;
        width: 5px;
        height: 8px;
        border: solid $color_white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  .prepend {
    margin-right: 10px;
  }

  .append {
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
  }
}
