@use "ui-kit-old" as *;

.wrapper {
  margin-top: 8px;
  width: 100%;
}

.emptyPromotion {
  width: 100%;
  padding: 10px 12px;

  .emptyText {
    @include resetMP;
    font-size: 14px;
    font-weight: 400;
    color: $midGrayThree;
  }
}