@use "ui-kit-old" as *;

.gridContainer {
  flex: 0 0 auto !important;

  .gridHeader {
    background-color: $darkBlue !important;

    p {
      color: $blueMist !important;
    }

    &.isCancelled {
      background-color: $gray !important;

      p {
        color: $darkBlue !important;
      }
    }
  }

  .loaderRow {
    padding: 0 5px !important;
  }

  .row {
    padding-left: 0;
  }

  .headerRow {
    padding-left: 0;
  }

  .rowFold {
    box-sizing: border-box;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    opacity: 1;
    pointer-events: none;
    display: none;
    border-top: 1px solid $lightBorder;
    border-bottom: 1px solid $lightBorder;
    background-color: $white;

    &.open {
      pointer-events: all;
      display: flex;
    }

    .playerInformationContainer {
      padding: 15px;
      flex: 1 1 100%;

      height: 100%;

      .text {
        margin: 0;
        padding: 0;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 600;

        .value {
          font-weight: 400;
        }
      }
    }

    .adjustments {
      min-height: 120px;
      flex: 0 0 500px;
      height: 100%;
    }
  }

  .coupon {
    width: 35px;
    height: 40px;
    padding-left: 5px !important;
    padding-right: 10px !important;

    .ticketIcon {
      width: 20px;
      margin-top: 2px;
    }
  }

  .toggle {
    width: 16px;
    height: 40px;
    padding-left: 5px !important;
    padding-right: 0 !important;

    &:hover {
      cursor: pointer;
    }

    .icon {
      width: 11px;
      height: auto;

      transform: rotate(-90deg);

      transition: transform 0.2s;

      &.open {
        transform: rotate(-180deg);
      }

      path {
        fill: $darkBlue;
      }
    }
  }

  .name {
    width: 150px;
    position: relative;
    padding-left: 5px !important;

    svg {
      margin-right: 8px;
    }

    div {
      font-weight: 600 !important;
    }

    .infoText {
      font-weight: 700;
    }

    .addPlayerFakeInput {
      margin: 0;
      padding: 0;
      font-weight: 600;
      font-style: italic;
      font-size: 14px;

      border-radius: 3px;
      border-width: 1px;
      border-color: rgba($blue, 0);
      border-style: solid;

      height: 28px;
      width: 100%;
      line-height: 28px;
      display: block;
      padding: 0 10px;
      box-sizing: border-box;
      padding-left: 5px;
      margin-left: -5px;

      overflow: visible;
      position: relative;

      color: rgba($darkBlue, 1);

      z-index: 99;

      transition: border-color 0.2s, color 0.2s;

      &:hover {
        cursor: pointer;
        border-color: rgba($blue, 0.5);
      }

      &.active {
        border-color: rgba($blue, 1);
        color: rgba($darkBlue, 0);
      }

      &.isCancelled {
        &:hover {
          border-color: rgba($blue, 0);
          cursor: not-allowed;
        }

        &.active {
          border-color: inherit;
          color: inherit;
        }
      }
    }

    .selectPlayerPopup {
      // position: absolute;
      // top: 0;
      // left: 0;
      // transform: none;
      z-index: 999;
    }
  }

  .voucherNameHeader {
    width: 250px;
    margin-left: 40px !important;
  }

  .voucherNameCell {
    width: 250px;
    position: relative;
    margin-left: 5px !important;
  }

  .code {
    width: 200px;
  }

  .type {
    width: 100px;

    .onHoverContainer {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      display: flex;

      .typeIcon {
        margin-right: 0;
      }

      >div {
        display: flex;
        align-items: center;
      }
    }

    .typeIcon {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .product {
    width: 145px;
    margin-left: 40px !important;
  }

  .time {
    width: 52px;
  }

  .arWrapper {
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .arContainer {
      background-color: lighten($darkBlue, 10);
      border-radius: 5px;
      margin: 5px 0;
      height: 20px;
      width: 20px;
      box-shadow: $boxShadow;

      &.isCancelled {
        box-shadow: none;
        background-color: unset;
      }
    }

    .arIcon {
      margin: 0 0 2px 4px;
      transform: scale(1);
      transition: transform 0.2s;
      height: 12px;
      width: 12px;

      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }

      svg {
        height: 14px;
        width: 14px;
      }

      path {
        fill: $white;
      }

      &.isCancelled {
        pointer-events: none;

        path {
          fill: $darkBlue;
        }
      }
    }

    .inputWrapperClassName {
      margin-bottom: 0;
    }
  }

  .hcp {
    width: 50px;
  }

  .golfId {
    width: 100px;
  }

  .phone {
    width: 130px;
  }

  .email {
    width: 150px;
  }

  .homeClub {
    width: 140px;
  }

  .price {
    flex: 1 1 140px !important;
    width: 140px;
    padding-right: 7px !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    justify-content: flex-end !important;
    text-align: right !important;
    box-sizing: border-box;

    &.withToggle {
      cursor: pointer;
    }

    &.withoutRemove {
      padding-right: 35px !important;
    }

    .editPen {
      width: 15px;
      height: 10px;
      margin-right: 8px;
      transform: scale(1);
      transition: transform 0.2s;

      &:hover {
        cursor: pointer;
        transform: scale(1.5);
      }
    }
  }

  .gitHomeClub {
    width: 140px;
  }

  .golfCartWrapper {
    width: 120px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    button {
      margin: 0;
    }

    .golfCartIcon {
      height: 20px;
      width: auto;
    }
  }

  .membershipsWrapper {
    width: 170px;
  }

  .partnerTypeWrapper {
    width: 130px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    button {
      margin: 0;
    }
  }

  div.removeContainer {
    width: 40px;

    .removeWrapper {
      flex: 0 0 18px;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      path {
        fill: $blueMist;
      }

      .deleteIcon {
        transform: scale(1);
        transition: transform 0.2s;

        path {
          fill: $silverBlue;
        }

        &:hover {
          cursor: pointer;
          transform: scale(1.1);
        }

        &.disabled {
          cursor: not-allowed;

          &:hover {
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

.infoText {
  font-size: 15px;
  margin: 0;
  padding: 0;
  font-weight: 500;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.bottomRow {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 21px;
  box-sizing: border-box;

  .playerControls {
    flex: 0 0 auto;
    width: auto;
  }
}

.selectPlayerPopupContainer {
  position: absolute;
  top: 0;
  left: 0;

  .selectPlayerPopup {
    transform: translateY(0);
    bottom: unset;
    top: 5px;
    left: 5px;
  }
}

.confirmOverrideContainer {
  display: flex;
  flex-direction: column;
  margin: -20px 0 0 -5px;

  .membershipName {
    color: $midGray;
    font-weight: $semiBold;
    margin-bottom: 5px;
  }


  .policyContainer {
    .policyName {
      font-weight: $semiBold;
    }

    ul {
      margin-left: 20px;
    }

    .violation {
      span {
        position: relative;
        left: -5px;
      }
    }
  }

  .confirmOverride {
    align-self: flex-end;
    justify-self: flex-end;
    margin-bottom: -20px;
    font-weight: $semiBold;
  }
}