@use "ui-kit-old" as *;
@use "component-classes/formElements" as *;

.checkboxRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.redDotContainer {
  width: auto !important;
  flex: 0 0 auto !important;
}

.checkboxContainer {
  height: 15px;
  width: 15px;
  flex: 0 0 15px;
  position: relative;
  overflow: hidden;
  
  border-width: 1px;
  border-style: solid;
  border-color: rgba($blue, 1);
  
  border-radius: 3px;
  background-color: rgba($blue, 0);

  color: $white;
  background-color: $white;

  margin-right: 9px;

  transition: background-color .2s;

  cursor: pointer;

  * {
    color: $white;
  }
}
.checkboxChecked {
  background-color: rgba($blue, 1);
}

.checkboxDisabled {
  border: 1px solid rgba($blueMist, 1);
  cursor: not-allowed;
}

.checkboxChecked.checkboxDisabled {
  background-color: rgba($blueMist, 1);
}

.checkboxError {
  border-color: rgba($orange, 1);
}

.checkboxInput {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 0px;
  height: 0px;
}

// Checkmark ICon
.checkmarkIcon {
  position: absolute;
  top: 4px;
  left: 2px;

  opacity: 0;
  color: $white;
  height: 6px;
  width: auto;

  transition: opacity .2s;
}
.checkmarkIconVisible {
  opacity: 1;
}