@use "ui-kit-old" as *;

.mobileWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 100;
  transition: background-color 0.75s;
}

.container {
  height: auto;
  width: auto;
  background-color: $white;
  border-radius: 4px;
  padding-top: 12px;

  box-shadow: 0px 4px 24px rgba($black, 0.1);

  transform-origin: top;
  transform: translateY(-100%);
  opacity: 0;

  transition: transform 0.75s, opacity 0.75s;
  pointer-events: none;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }
}

.animateIn {
  animation-name: animateInKey;
  animation-duration: 0.75s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.animateOut {
  animation-name: animateOutKey;
  animation-duration: 0.75s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: forwards;
}

@media (max-width: 1024px) {
  .mobileContainer {
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    max-height: 60vh; // Max height for mobile views
    opacity: 0;
    transform: translateY(100%);
    border-radius: 11px 11px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .visible {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@keyframes animateInKey {
  from {
    max-height: 0;
  }
  to {
    max-height: 100%;
  }
}
@keyframes animateOutKey {
  from {
    max-height: 100%;
  }
  to {
    max-height: 0;
  }
}
