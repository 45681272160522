@use "ui-kit-old" as *;


.textInput {
  flex: 1 1 100%;
  width: 100%;
  box-shadow: none;
  border-radius: 3px;
  padding: 10px;
  background-color: $white;

  font-size: 15px;
  line-height: 15px;
  font-weight: 400;
  color: $darkBlue;

  &::placeholder {
    font-weight: 300;
  }
}
.textInputDisabled {
  color: rgba($darkBlue, .8);
  cursor: not-allowed;

  &::placeholder {
    font-weight: 300;
    color: rgba($darkBlue, .5);
  }
}

.hasPrefix {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.hasSuffix {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
