@use "colors-legacy" as *;

.container {
  * {
    color: $text_color;
    font-size: 14px;
    font-family: Roboto;
  }

  .bold {
    font-weight: 600;
  }

  .header1 {
    border-right: 0 !important;
    border-left: 1px solid $light_grey !important;
    display: flex;
    align-items: center;
    min-width: 150px !important;

    >div {
      text-align: left;

      >div {
        font-weight: 500;
        padding-left: 5px;
      }
    }
  }

  .header2 {
    border: none !important;
    display: flex;
    align-items: center;

    >div {
      >div {
        font-size: 11px;
        padding: 4px 0;
      }
    }
  }

  .cell1 {
    border-right: 0 !important;
    border-left: 1px solid $light_grey !important;
    font-size: 13px;
    padding-left: 10px;
    color: $color_black;
    min-width: 150px !important;
  }

  .cell2 {
    border: none !important;
    text-align: center;
    font-size: 13px;
    color: $color_black;
  }

  .cellCategory {
    @extend .cell2;
    padding-right: 18px !important;
  }

  .printBtn {
    color: $medium_blue;

    &:hover,
    &:active,
    &:focus {
      color: $color_white;
    }
  }
}