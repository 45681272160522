@use "ui-kit-old" as *;

.container {
  width: 100%;
  height: auto;
  padding: 16px 24px;
  box-sizing: border-box;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;

  .title {
    @include resetMP;

    font-size: 18px;
    font-weight: 600;
    color: $blueMist;

    margin-bottom: 16px;
  }

  .newContainer {
    position: relative;
    margin-bottom: 16px;

    .dropdownContainer {
      position: relative;

      .dropdown {
        min-width: 200px;
      }
    }
  }
}
