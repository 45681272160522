@use "ui-kit" as *;

.container {
  width: 100%;
  height: auto;
  background-color: $white;
  max-height: 0;
  opacity: 0;

  padding: 0 !important;
  margin-bottom: 0 !important;

  transition: max-height 0.2s, opacity 0.2s, padding 0.2s, margin-bottom 0.2s;
  overflow: hidden;

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
  }

  &.open {
    max-height: 800px;
    opacity: 1;
    overflow: visible;
    border: none;
    padding: 24px 0 !important;
  }
  .ownerInformationContainer {
    padding: 8px 0;
    margin-top: 8px;
  }
  .ownerDataContainer {
    margin-top: 0;
  }

  .header,
  .ownerInformationContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;

    margin-bottom: 0;

    transition: margin-bottom 0.2s;

    &.dropdownIsOpen {
      margin-bottom: 12px;
    }

    .title {
      color: $black;
      font-size: 16px;
      margin: 0;
      padding: 0;
      font-weight: 700;
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .dropdownArrow {
        margin-right: 10px;
        transform: rotate(0);
        transition: transform 0.2s;

        &.open {
          transform: rotate(180deg);
        }
      }

      .ownerName,
      .ownerData {
        @include resetMarginPadding;
        color: $black;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 12px;

        svg {
          path {
            color: $sweetspotBlack;
          }
        }

        span {
          color: $sweetspotBlack;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .ownerData {
        margin-right: 16px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;

      .sweetspotUser {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .hcp {
        @include resetMarginPadding;
        color: $dark;
        text-transform: lowercase;
      }

      .lockContainer {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $strokeGray;
      }
    }
  }

  .ownerInformation {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 7px;

    max-height: 0;
    opacity: 0;
    overflow: hidden;

    transition: max-height 0.2s, opacity 0.2s;

    &.open {
      opacity: 1;
      max-height: 300px;
      overflow: visible;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        @include resetMarginPadding;
      }

      .property {
        font-size: 12px;
        color: $iconGray;
        margin-right: 20px;
      }

      .value {
        font-size: 12px;
        color: $dark;
      }
    }
  }
}
