@use "colors-legacy" as *;

.wrapper {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  border-radius: 4px;
  padding: 25px;
  padding-top: 10px;
  overflow: auto;

  div {
    margin-top: 0;
  }

  form {
    max-width: 600px;
    margin-top: 30px;
  }

  ul {
    list-style-type: none;
  }
}

.formControl {
  display: flex;
  justify-content: space-between;
}

.formButtons {
  width: 20%;
  margin: 40px 20px 0 0;
}
