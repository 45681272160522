@use "./styles.module.scss";
@use 'ui-kit-old' as old;
@use "ui-kit" as *;

.mediumSize {
  height: 32px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 15px;

  &.rounded {
    border-radius: 14px;
  }

  .spinner {
    width: 17px;
    height: 17px;
  }

  .pulseLoaderDot {
    height: 9px;
    width: 9px;
  }

  .dropdownItem {
    padding: 2.5px 10px;
    font-size: 15px;

    &:first-of-type {
      padding-top: 5px !important;
    }
    &:last-of-type {
      padding-bottom: 5px !important;
    }
  }
}

.largerSize {
  font-size: 16px;
  font-weight: 500;
  height: 32px;
  padding-top: 3px;

  .spinner {
    width: 15px;
    height: 15px;
  }

  .pulseLoaderDot {
    height: 9px;
    width: 9px;
  }
}

.largeSize {
  padding: 0 27px;
  padding-bottom: 4px;
  height: 48px;
  font-size: 20px;
  font-weight: 500;

  &.rounded {
    border-radius: 14px;
  }
}

.defaultTheme {
  border-color: $sweetspotBlack !important;
  color: $white !important;
  background-color: $sweetspotBlack !important;

  &.separator {
    background-color: lighten(old.$blueWebApp, 5);
  }

  &:hover {
    background-color: old.$hoverBlueWebApp;
    border-color: old.$hoverBlueWebApp;
  }

  &:active {
    background-color: lighten(old.$activeBlueWebApp, 0);
    border-color: lighten(old.$activeBlueWebApp, 0);
  }
}

.dangerTheme {
  border-color: $red !important;
  background-color: $red !important;
  color: $white;

  &.separator {
    background-color: darken($red, 10);
  }

  &:hover {
    background-color: darken($red, 10);
    border-color: darken($red, 10);
  }

  &:active {
    background-color: lighten($red, 10);
    border-color: lighten($red, 10);
  }
}
.dangerTheme .text {
  &:hover {
    color: $white;
  }
}

// Default theme outline
.defaultThemeOutline {
  border-color: old.$lightGrayWebApp !important;
  background-color: transparent !important;
  color: $black !important;

  &:hover {
    background-color: old.$hoverBlueWebApp;
    border-color: old.$hoverBlueWebApp;
    color: $white;

    .spinner {
      stroke: $white;
    }

    .pulseLoaderDot {
      background-color: $white;
    }

    .dropdownCaret {
      * {
        color: $white;
        fill: $white;
      }
    }
  }

  &:active {
    background-color: lighten(old.$activeBlueWebApp, 0);
    border-color: lighten(old.$activeBlueWebApp, 0);
    color: $white;
  }

  .spinner {
    stroke: old.$lightGrayWebApp;
  }
  .pulseLoaderDot {
    background-color: old.$lightGrayWebApp;
  }
}
.defaultThemeOutline .text {
  &:hover {
    color: $white;
  }
}

// White outline theme
.whiteOutlineTheme {
  border-color: $white !important;
  background-color: transparent !important;
  color: $white !important;

  &.separator {
    background-color: lighten($white, 5);
  }

  .dropdownCaret {
    * {
      color: $white;
      fill: $white;
    }
  }

  &:hover {
    background-color: old.$blueWebApp;
    border-color: old.$blueWebApp;
  }

  &:active {
    background-color: old.$activeBlueWebApp;
    border-color: old.$activeBlueWebApp;
  }

  .spinner {
    stroke: $white;
  }
  .pulseLoaderDot {
    background-color: $white;
  }
}
.whiteOutlineTheme .text {
  &:hover {
    color: $blue;
  }
}
