@use "ui-kit-old" as *;
@use "component-classes/formElements" as *;

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $white;
}

.calendarContainer {
  position: absolute;

  bottom: -4px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  overflow: hidden;

  z-index: 99;

  transform: translateY(100%);

  transition: opacity 0.2s;

  pointer-events: none;
}

.calendarContainerVisible {
  opacity: 1;
  pointer-events: all;
}

.loginColorTheme {
  background-color: transparent;
}
