@use "colors-legacy" as *;

.container {
  position: relative;
  display: flex;
  flex-direction: row;

  div {
    width: 100%;
    font-weight: 500 !important;
  }

  span {
    display: inline-block;
    margin-right: 6px;
    margin-top: -12px;
    font-size: 26px;
  }

  &.hide {
    div {
      visibility: hidden;
    }
  }

  &.active {
    span {
      color:  $confirm_green;
    }
  }

  &.inActive {
    span {
      color:  $price_color_red_medium;
    }
  }

  &.neutral {
    margin-left: 18px;

    span {
      color:  $text_color;
    }
  }

  &.new {
    span {
      color:  $tee_sheet_dark_blue;
    }
  }

  &.bordered {
    span {
      display: none;
    }
    div {
      width: auto;
      background: $text_color_light;
      color: $color_white;
      padding: 2px 8px;
      border-radius: 5px;
    }

    * {
      display: inline;
    }
  }
}

