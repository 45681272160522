@use "colors-legacy" as *;

.panel {
  position: relative;
  box-shadow: $box_shadow;
  display: grid;
  grid-template-rows: auto 1fr;
  border-radius: 8px;
  border: none;
  background: white;
  padding: 10px 15px;

  .x_title {
    border-bottom: 2px solid $light_grey;
    padding: 1px 5px 6px;
    margin-bottom: 10px;

    .title {
      div {
        font-size: 18px;
      }
    }
  }

  .content {
    font-size: 16px;
    min-width: 0;
  }
}
