@use "colors-legacy" as *;
@use "./custom.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  // color: $text_color;
  box-sizing: border-box;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

img {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}

body,
html,
#root {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea:focus,
input:focus {
  outline: none;
}

button {
  position: relative;
}

input {
  // z-index: 1;
  -webkit-touch-callout: default;
  /* iOS Safari */
  -webkit-user-select: auto;
  /* Safari */
  -moz-user-select: auto;
  /* Firefox */
  -ms-user-select: auto;
  /* Internet Explorer/Edge */
  user-select: auto;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}

input[type="number"] {
  -moz-appearance: textfield;
}

input:not(.no-override)::-webkit-outer-spin-button,
input:not(.no-override)::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

label {
  margin-bottom: 0;
}