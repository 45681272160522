@use "colors-legacy" as *;

.wrapper {
  padding: 20px 25px 25px 35px;
  overflow: visible;

  .content {
    display: flex;
    flex-direction: row;
      
    button {
      div {
        font-weight: 500;
      }
    }
    .sideMenu {
      max-width: 300px;
      margin-right: 25px;

      .holeSelect {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        align-items: center;
        
        .dropDown {
          margin-left: 25px;
        }
        div {
          font-weight: 500;
          font-size: 18px;
          max-width: 75px;
        }
      
        input {
          font-size: 18px;
          border: 1px solid darken($semi_medium_grey, 10%);
          background-color: white;
        }
        input:focus {
           border: 1px solid $tee_sheet_blue;
        }
      }

      .courseSelect {
        margin-top: 15px;
        input {
          font-size: 18px;
          border: 1px solid darken($semi_medium_grey, 10%);
          background-color: white;
        }
      }

      .statusToggle {
        margin-top: 25px;
        margin-bottom: 25px;

        button {
          width: 100px;
        }
      }

      .addImages {
        .label {
          margin-bottom: 7px;
        }
        .imageUpload {
          .imageBank {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(6, 1fr);
            grid-gap: 3px;
            padding: 6px;
            height: 400px;
            width: 210px;
            background-color: darken($very_light_grey, 1%);
            border-radius: 8px;
            overflow: auto;

            img {
              border-radius: 8px;
              box-shadow: $box_shadow;
              cursor: pointer;

              &:hover {
                filter: brightness(85%);
              }
            }
          }
        }
        button {
          background-color: white;
          border: 1px solid $slightly_light_blue;
          border-radius: 4px;
          width: 210px;
          font-weight: 500;
          color: $slightly_light_blue;
          font-size: 15px;
        
            &:hover:not([disabled]), &:focus:not([disabled]) {
              background-color: $slightly_light_blue;
              border-color: $slightly_light_blue;
              color: white;
            }
        }   
      }
    }

    .holeGrid {
      display: grid;
      height: 100%;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr;
      grid-gap: 20px;

      .holeImage {
        background-color: darken($very_light_grey, 1%);
        height: 200px;
        width: 110px;
        border-radius: 8px;

        img {
          height: 200px;
          width: 110px;
          box-shadow: $box_shadow;
          border-radius: 8px;
          cursor: pointer;

          &:hover {
            filter: brightness(90%);
          }
        }
      }
    }

    .imageModal {
      width: 400px;
      height: 700px;
      background: white;
      box-shadow: $box_shadow;
      padding: 20px;
      position: relative;
      border-radius: 8px;
      display: grid;
      grid-template-rows: 40px 1fr 40px;

      .modalHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

  
        .removeButton {
          margin-left: 10px;
        }

        div {
          font-weight: 500;
        }

        input {
          font-size: 16px;
          border: 1px solid darken($semi_medium_grey, 10%);
        }
      }

      .mainContent {
        display: flex;
        flex-direction: row;
        justify-items: space-around;
        align-items: center;

        img {
          width: 80%;
          height: 97%;
          margin: auto;
        }

        .arrowContainer {
          height: 30px;
          width: 20px;
          cursor: pointer;
          font-size: 24px;
        }
      }
    }
  }

  .holeNumber {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 2px;
    margin-left: 3px;
    div {
      font-weight: 500;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    .label {
      div {
        display: inline-block;
        padding: 0 0 12px 0;
        font-size: 24px;
        font-weight: 600;
        color: darken($semi_medium_grey, 10%);
      }
    }
  }
}