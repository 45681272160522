@use "colors-legacy" as *;

.container {
  .header {
    height: 60px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 0 20px;
    border-bottom: 1px solid $semi_medium_grey;

    button {
      margin-right: 20px;
    }

    .searchInput {
      margin-left: auto;
    }
  }

  .overlay {
    width: 600px;
    min-height: 600px;
    background: $color_white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px -4px $color_black;
    padding: 20px 25px;
    overflow-y: auto;
    margin: 10px auto !important;
  }

  .bottom {
    width: 100%;
    height: 200px;
    overflow-y: auto;
    padding: 15px 20px;
    font-size: 14px;
    color: $color_black;
    border-top: 2px solid $light_grey;
    position: absolute;
    bottom: 0;
    background: $color_white;
    white-space: pre-wrap;
  }
}

.row {
  display: flex;
  font-size: 15px;
  padding: 5px 15px;

  &.tableHeader {
    background: $very_light_green;
    padding: 8px 15px;

    * {
      font-weight: 500;
      font-size: 15px;
    }
  }

  &.active {
    background: $slightly_light_blue;

    * {
      color: $color_white;
    }
  }

  > div {
    display: inline-block;
  }

  .date {
    width: 100px;
  }

  .period {
    width: 150px;
  }

  .status {
    width: 100px;
  }

  .dateTime {
    width: 140px;
  }

  .title {
    flex: 1;
    min-width: 220px;
  }

  .buttons {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    svg {
      cursor: pointer;
      font-size: 12px;
    }

    .tooltip {
      background: $color_white;
      border: 1px solid $semi_medium_grey;
      border-radius: 3px;
      padding: 0 4px !important;
      z-index: 100;

      &::after {
        display: none;
      }

      * {
        color: $text_color;
        font-size: 11px;
      }
    }
  }
}

.listView {
  height: calc(100% - 270px);
  overflow-y: auto;
}

.courseList {
  height: 50px;
  overflow-y: auto;
  margin-top: 10px;

  * {
    font-weight: 500;
  }

  > div {
    display: inline-block;
    background: $light_grey;
    font-size: 14px;
    padding: 2px 10px;
    margin: 0 10px 5px 0;
    border-radius: 5px;

    * {
      font-weight: 400;
    }

    span {
      font-size: 10px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
