@use "colors-legacy" as *;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 0;

  .error {
    margin-bottom: 20px;
    color: $danger_red;
  }

  .filtersContainer {
    padding: 0 23px 20px;
  }
}
