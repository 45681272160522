@use "ui-kit-old" as *;

.container {
  width: 100%;
  height: auto;
  background-color: $white;
  border-radius: 3px;
  border: 1px solid $lightGrayThree;

  margin-bottom: 15px;

  position: relative;

  .pulse {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .leftSide {
      padding: 0 24px;
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:hover {
        cursor: pointer;
      }
    }
    .rightSide {
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .removeIconContainer {
        .removeIcon {
          width: 15px;
          height: 15px;
        }

        svg path {
          transition: fill .2s;
        }

        &:hover {
          cursor: pointer;
          svg path {
            fill: $red;
          }
        }

        &.disabled:hover  {
          cursor: not-allowed;
        }
      }
    }

    .caretContainer {
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .caretDown {
        width: 8px;
        height: auto;
        transform: rotate(-90deg);

        transition: transform .2s;

        * {
          fill: $darkBlue;
        }

        &.isOpen {
          transform: rotate(0deg);
        }
      }
    }

    .headerTitle {
      @include resetMP;
      font-size: 14px;
      font-weight: 600;
      color: $darkBlue;
    }
  }

  .foldContainer {

    max-height: 0;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: top;

    transition: transform .2s;

    &.isOpen {
      overflow: visible;
      max-height: none;
      transform: scaleY(1);
    }
  }

  .innerContainer {
    padding: 16px 24px;
    padding-top: 0;
    box-sizing: border-box;

    .innerTitle {
      @include resetMP;
      font-size: 12px;
      font-weight: 600;
      color: $activeBlue;
      margin-bottom: 16px;
    }


    .rowsContainer {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      .row {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;

        margin-bottom: 8px;

        padding-right: 21px;


        &.styled {
          border-radius: 5px;
          border: 1px solid $gray;
          border-left: 6px solid $blueMist;
          border-left-color: $blueMist;
          border-bottom: none;
          box-shadow: 0px 2px 3px rgba($black, .17);
          padding: 4px 17px 4px 6px;

          transition: border-left-color .2s;

          &:hover {
            border-left-color: $darkBlue;
          }
        }

        .textAlignRight {
          text-align: right;
        }

        .firstInput {
          flex: 0 0 calc(25% + 12px);
          width: calc(25% + 12px);
        }

        .limitationTypeFiller {
          width: 25%;
          flex: 0 0 25%;
        }

        .minFeeContainer {
          width: 100px;
          flex: 0 0 100px;

          &.label {
            align-self: flex-end;
          }
        }
        .removeRowContainer {
          width: 30px;
          flex: 0 0 30px;

          display: flex;
          align-items: center;
          justify-content: flex-end;

          .removeIcon {
            width: 15px;
            height: 15px;

            path {
              transition: fill .2s;
            }

            &:hover {
              cursor: pointer;
              path {
                fill: $red;
              }
            }
          }
        }
      }
    }

    .addRowButton {
      margin-top: 8px;
    }
  }
}
