@use "ui-kit-old" as *;

.container {
  display: inline-block;
  width: auto;
  min-width: 250px;

  background: $white;
  border-radius: 3px;
  z-index: 100;
  box-shadow: $boxShadow;


  height: 100%;
  max-height: 300px;


  * {
    user-select: none;
  }

  .content {
    width: 100%;
    overflow: hidden;

    height: 100%;

    display: grid;
    grid-template-rows: auto auto 1fr auto;

    .searchField {
      display: grid;
      grid-template-columns: 15px 1fr;
      padding: 0 5px;
      //justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid $lightGrayThree;

      .searchIcon {
        height: 12px;

      }
      path {
        fill: $darkBlue;
      }

      .searchInput {
        border-radius: 0;
        border: none;
      }
      * {
        margin-bottom: 0 !important;
      }

      input {
        font-size: 14px !important;
        font-weight: 500 !important;
        border: none;
        border-radius: 0px !important;
      }
    }

    .loadingSearchContainer {
      width: 100%;
      border-radius: 4px;
      height: 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      overflow: hidden;
      pointer-events: none;

      transition: height .2s;

      &.visible {
        overflow: visible;
        pointer-events: all;
        height: 30px;
      }
    }

    .checkboxContainer {
      width: 100%;
      border-radius: 4px;
      align-items: center;
      white-space: nowrap;

      height: auto;
      overflow-y: scroll;

      >div:first-child {
        border-bottom: 1px solid $lightGrayThree;
      }

      &.singleSelectOnly {
        >div:first-child {
          border-bottom: none;
        }
      }

      .checkbox {
        display: flex;
        align-items: center;
        padding: 4px 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          background: $lightBlue;
          cursor: pointer;
        }

        &.disabled {
          &:hover {
            cursor: not-allowed;
          }
        }
      }

      > div >div:last-child .checkbox {
        border: none;
      }

      * {
        margin-bottom: 0 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      label {
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 19px;
        font-weight: 500;
      }
    }

    .buttons {
      width: 100%;
      padding: 0 5px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      border-top: 1px solid $lightGrayThree;

      height: auto;

      * {
        margin-left: 5px;
      }
    }
  }
}
