@use "shared-legacy" as *;

.wrapper {
  display: grid;
  grid-template-rows: auto;
  padding: 20px 25px 25px 35px;
  min-width: 500px;

  .title > div {
    display: inline-block;
    padding: 0 0 5px 0;
    font-size: 24px;
    font-weight: 600;
    margin-right: 15px;
    color: darken($semi_medium_grey, 10%);
  }

  form {
    margin-top: 20px;
    max-width: 620px;

    input {
      height: 32px;
    }

    .inputOptionsSelect {
      div > div:first-of-type {
        height: 32px;
      }
    }

    input[type='file'] {
      height: unset;
      font-size: 15px;
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      padding: 10px 15px;
      margin: 5px 0 10px 0;
    }

    .imageError {
      div {
        color: red;
      }
    }

    > div label,
    > div label > div {
      font-size: 15px;
      font-weight: 600;
    }

    .formButtons {
      display: flex;
      justify-content: flex-end;
      margin: 30px 0;
    }

    .golfCourseImage {
      margin-bottom: 25px;
    }

    .customPayOnSite {
      width: 75%;
    }
  }

  .syncTeesWrapper {
    .teesTable {
      margin-top: 3px;
      border: 1px solid $light_grey;
      border-radius: 3px;
      width: 100%;
      overflow: hidden;
      font-size: 14px;

      table {
        width: 100%;
      }
      th {
        padding: 4px 8px;
        > div {
          font-size: 15px;
          font-weight: 600;
        }
      }
      tbody > tr:nth-child(odd) {
          background: $very_light_grey;
        }

      td {        
        padding: 4px 10px;
      }
    }

    .grayBold {
      color: $text_color_light;
      font-size: 15px;
      font-weight: $semi_bold;
      margin: 3px 0;
    }

    .lastSync {
      font-size: 14px;
      margin: 2px 0 0 0;

      &.failed {
        color: $button_red;
      }


    }

    button {
      font-size: 14px;
    }
    .syncIcon {
      margin: 2px 7px 0 0;
  
      svg {
        height: 12px;
        width: 12px;
      }
  
      path {
        fill: $medium_blue;
      }
    }
  }
}
