@use "colors-legacy" as *;

.container {
  background: $color_white;
  width: 500px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px -4px $color_black;
  padding: 40px 30px;
  text-align: center;
  font-size: 18px;

  > div:nth-of-type(2) {
    width: 80%;
    margin: 30px auto 10px;
  }

  .buttons {
    margin-top: 50px;

    * {
      display: inline-block;
      padding: 0 15px;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}