@use "ui-kit" as *;

.popup {
  overflow: visible;
}

.pillsContainer {
  min-height: 28px;
}

.allCoursesText {
  @include resetMarginPadding;
  font-weight: 500;
  color: $dark;
  font-family: $fontRoboto;
  font-size: 14px;
}