@use "ui-kit" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;

  .typeDropdown {
    flex: 0 0 calc(50% - 5px);
    display: flex;
    height: 48px;

    > div > div > div {
      height: 48px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    input {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      height: 48px;
    }
    span {
      font-size: 24px;
    }
  }

  .searchInputRow {
    display: flex;
    flex: 0 0 calc(50% - 5px);
    height: 48px;

    > div:first-child  {
      height: 100%;
      > div:first-child {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        height: 100%;
      }
    }
    input {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      height: 100%;
      &::placeholder {
        font-style: italic;
        font-size: 14px;
      }
    }
    span {
      font-size: 20px;
    }
  }
  .stubInputsRow {
    flex: 1 1 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;

    .inputContainer {
      flex: 0 0 calc(50% - 5px);
      display: flex;
      flex-direction: column;
      position: relative;

      .input {
        height: 48px;
        border-radius: 8px;
      }

      label {
        color: $sweetspotBlack;
        font-weight: 700;
        font-size: 14px;
      }
    }

    .addManuallyApplyButton {
      height: 48px;
      margin-top: 23px;
    }
  }

  .applyButton {
    flex: 0 0 auto;
    margin: 20px 0 0 auto;
    width: auto;
    padding: 8px 24px;
    height: 40px;
    min-width: 40px;
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    border-radius: 8px;

    &.disabled {
      background-color: $waDefaultHighlightColor !important;
      color: #D1D5DB !important;
    }
  }
}
