@use "colors-legacy" as *;

.container {
  width: 300px;
  padding: 5px 0;
  background: white;
  border-radius: 8px;
  position: absolute;
  z-index: 100;
  top: 60px;
  right: 20px;
  overflow-y: auto;
  box-shadow: 0px 2px 6px 1px rgba(0,0,0,.2);

  * { 
    user-select: none;
  }

  .content {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;

    .titleContainer {
      div {
        color: $medium_blue;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .checkboxContainer {
      border-radius: 4px;
      overflow: auto;
      align-items: center;

      .checkbox {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $light_grey;
        padding: 8px 12px;

        &:hover {
          background: $medium_light_blue;
          cursor: pointer;
        }

        &.disabled {
          span {
            background: $text_color;
            border: 1px solid $text_color;
          }
        }

        &.activeDrag {
          background: $medium_light_blue;
          border-bottom: 2px solid $medium_blue !important;

          * {
            fill: $slightly_light_blue;
          }
        }

        .image {
          width: 15px;
          margin-right: 10px;
        }
      }

      > div >div:last-child .checkbox {
        border: none;
      }
      
      * {
        font-size: 15px;
        font-weight: 500;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;

      button {
        width: 100px;
        margin-left: 10px;
      }

      div {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}