@use "colors-legacy" as *;

.content {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
  grid-gap: 4px;
  overflow: hidden;
  padding-bottom: 5px;

  >div {
    background: $color_white;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);

    &:first-of-type {
      border-radius: 10px 0 0 10px;
    }

    &:last-of-type {
      height: 100%;
      border-radius: 0 10px 10px 0;
      position: relative;
    }
  }

  .sideMenu {
    height: 100%;
    // border-right: 3px solid $very_light_grey;
    padding: 10px 0 10px;
    position: relative;
    display: table;

    .label {
      margin: 10px 0 20px 40px;

      div {
        font-size: 20px;
        font-weight: 600;
        color: $text_color_light;
      }
    }

    .sideMenuTitle {
      position: absolute;
      height: 40px;
      font-size: 18px;
      padding: 10px;
    }

    .sideMenuContent {
      overflow-y: auto;
      height: calc(100% - 190px);

      a {
        text-decoration: none;
      }
    }

    .menuItem {
      height: 40px;
      padding: 0 40px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: $medium_light_blue;
      }

      &.active {
        background: $medium_light_blue;
      }

      &.disabled {
        opacity: 0.5;
        cursor: default;

        &:hover {
          background: transparent;
        }
      }

      * {
        font-size: 16px;
      }
    }
  }
}