@use "colors-legacy" as *;

.wrapper {
  padding: 25px;
  padding-top: 10px;
  width: 50%;
  max-width: 750px;
  min-width: 500px;
}

.label {
  div {
    display: inline-block;
    font-size: 24px;
    font-weight: 600;
    color: darken($semi_medium_grey, 10%);
    padding-bottom: 10px;
  }
}

.input {
  margin: 15px 0;
}

.btnGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.overlay {
  padding: 30px 80px;
  background: white;
  max-width: 500px;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  min-width: 450px;
  min-height: 150px;

  .confirmBtnGroup {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 0;

    > div {
      color: transparentize($color_black, .45);
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}