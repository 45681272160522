@use "shared-legacy" as *;

.container {
  height: auto;
  width: 100%;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;

  align-items: flex-start;
  justify-content: flex-start;

  min-height: 100px;
}

.row {
  width: 100%;
  height: 0 0 auto;

  display: flex;
  align-items: center;
  justify-items: flex-start;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.headerRow {
  margin: 0;
  margin-bottom: 8px;
}

.courseRow {
  margin: 4px 0px;

  &:first-child {
    margin-top: 8px;
  }
}

.column {
  margin: 0;
  padding: 0;
  flex: 0 0 40%;
  &:last-child {
    flex: 0 0 20px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.courseLabel {
  font-size: 16px;
  font-weight: 600;
}
.trashIcon {
  padding-right: 7px;
}
.trashIconDisabled {
  opacity: 0;
  pointer-events: none;
}
.clearIcon {
  padding-right: 0;
  &:hover {
    cursor: pointer;
  }
}
.clearIconDisabled {
  opacity: 0;
  pointer-events: none;
}


