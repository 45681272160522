@use "ui-kit-old" as old;
@use "ui-kit" as *;

.container {
  display: inline-block;
  overflow: hidden;
  z-index: 100;
  background: $white;
  border-radius: 8px 8px 0 0;

  .headerContainer {
    height: 48px;
    background: $white;
    display: grid;
    grid-template-columns: 48px 1fr 48px;

    .arrowContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: $red;
      font-size: 16px !important;

      .arrow {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $black;
        font-size: 16px !important;

        path {
          color: $black;
        }

        &:hover {
          path {
            color: $black;
          }
        }
      }
    }

    .monthContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .month {
        font-size: 16px;
        text-align: center;
        color: $black;
        padding-bottom: 2px;
        text-transform: capitalize;
      }
    }
  }

  .calendarContainer {
    background: $white;

    .calendarLabels {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      background: $white;
      color: $black;
      border-bottom: 1px solid hsla(0, 0%, 89.4%, .6);
      border-radius: 0;
      text-align: center;
      padding-bottom: 24px;
      padding-right: 8px;
      padding-left: 8px;
      height: 22px;
      font-size: 16px !important;

      @include upToDesktop {
        grid-template-columns: repeat(7, 45px);
        justify-content: center;
      }

      p {
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        margin-block-start: 0em;
        margin-block-end: 0em;
      }

      div {
        font-size: 14px;
        text-align: center;
        font-weight: 600;
      }
    }

    .calendarDays {
      padding: 10px;
      display: grid;
      grid-template-columns: repeat(7, 45px);
      grid-template-rows: repeat(6, 45px);
      font-size: 16px !important;

      .dayPanel {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-bottom: 1px;
        cursor: pointer;
        color: $black;

        &:hover {
          background: old.$gray;
          border-radius: 360px;
        }

        &.inactive {
          div {
            color: $iconGray;
          }
        }

        &.disabled {
          cursor: default;
          div {
            color: $iconGray;
          }
        }

        &.selected {
          background: old.$darkBlue;
          div {
            color: $white;
          }
          border: 1px solid transparentize(old.$black, .7);
          border-radius: 50%;
        }

        &.today {
          border: 0;
          border-radius: 50%;
          background-color: rgba($iconGray, 0.2);
          .dayText {
            font-weight: 500;
            color: old.$brown_dark;
          }
        }

        .dayText {
          text-align: center;
        }
      }
    }
  }
}

.container {
  &.blueStyle {
    margin-top: 3px;
    border: 1px solid $black;
    .headerContainer {
      background: white;
      .month {
        color: $black;
      }
      .arrow {
        path {
          color: $black;
        }
      }
    }
    .calendarContainer {
      background: white;
      color: $black;

      .calendarLabels {
        p {
          color: $black;
        }
        background: white;
        border-bottom: 1px solid transparentize(old.$lightGray, 0.4);
      }
      .dayPanel {
        &:hover {
          background: transparentize($black, 0.8);
          border-radius: 50%;
          border: none;
          div {
            color: $blue;
          }
        }

        &.selected {
          background: transparentize($black, 0.8);
          border-radius: 30px;
          border: none;
          div {
            color: $white !important;
          }
        }
      }
    }
  }
}

.bottomBorder {
  border-bottom: 1px solid hsla(0, 0%, 89.4%, 0.6) !important;
}
