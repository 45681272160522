@use "colors-legacy" as *;

.wrapper {
  padding: 20px 25px 25px 35px;
  overflow: visible;
  width: 50%;
  max-width: 750px;
  min-width: 500px;

  div {
    margin-top: 0;
  }
  form {
    margin-top: 30px;
  }
}

.formButtons {
  display: flex;
  margin: 30px 0 0 auto;
}

.label {
  div {
    display: inline-block;
    font-size: 24px;
    font-weight: 600;
    color: darken($semi_medium_grey, 10%);
    padding-bottom: 10px;
  }
}

.noAccess {
  div {
    font-weight: bold;
    font-size: 22px;
  }
}

