@use "ui-kit-old" as *;


.container {
  .dropdownWrapper {
    position: relative;
    margin-top: 4px;
  }
}

.dropdownContainer {
  pointer-events: none;
  opacity: 0;

  z-index: 99;

  max-width: 160px;
  height: auto;
  border-radius: 3px;
  background-color: $white;
  box-shadow: $boxShadow;

  transition: opacity .2s;

  padding: 8px;
  box-sizing: border-box;

  &.visible {
    pointer-events: all;
    opacity: 1;
  }
}