@use "shared-legacy" as *;

.container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 2px 18px 0;

  > div {
    padding: 10px 0;
    overflow: visible;
    z-index: 99;

    > h2 {
      display: none;
    }
  }
}
