@use "colors-legacy" as *;
@use "sizes-legacy" as *;

.container {
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: 15px;

  .content {
    height: 100%;
    display: grid;
    grid-template-rows: 60px 1fr;

    .gridHeader {
      position: relative;
      margin-top: 5px;
      padding: 0 25px 0 10px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr auto auto auto;
      grid-template-rows: 1fr;
      align-items: center;

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .playersColumnSelector {
        min-width: 133px;
      }

      .pageTitle {
        display: inline-block;
        margin-bottom: 2px;

        div {
          font-size: 19px;
          font-weight: $semi-bold;
          margin: 0 30px 0 15px;
        }
      }

      .searchInput {
        margin-left: 10px;
        display: flex;
        flex-direction: row;

        .searchBtn {
          button {
            height: 30px;

            div {
              color: $text_color;
              font-weight: 500;
            }
          }
        }
      }

      button {
        margin-left: 10px;
        height: 30px;
      }

      .actionsDropdown {
        button {
          margin-top: 0;
        }
      }

      .selectBox {
        width: 200px;
        margin-left: 40px;

        > div {
          * {
            font-size: 14px !important;
            font-weight: 500;
          }
        }
      }
    }
  }

  .image {
    margin-left: 12px;
    transform: rotate(90deg);

    path {
      fill: $slightly_light_blue;
    }
  }

  .inlineText {
    display: flex;
    flex-direction: row;
    align-items: center;

    div {
      font-weight: 500;
    }

    .number {
      font-weight: 600 !important;
    }
  }

  .filters {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 85px);
    width: 275px;
    border-right: 6px solid darken($soft_grey, 10%);
    padding: 10px 25px 10px 25px;
    user-select: none;
    overflow: auto;

    .filtersTitle {
      margin: 12px 0 25px 0;
      div {
        font-size: 18px;
        font-weight: 600;
        color: $text_color_light;
        text-align: left;
      }
    }

    .filtersList {
      flex: 1;
      overflow: auto;
      margin-right: -25px;
      padding-right: 25px;
    }

    .filter {
      margin-bottom: 10px;

      &.hasDivider {
        border-top: 1px solid $semi_medium_grey;
        padding-top: 10px;

        .filterName {
          font-size: 16px;
        }
      }

      .filterName {
        margin-bottom: 10px;
        div {
          font-weight: 600;
          color: $text_color;
        }
      }
    }

    button {
      margin-top: 15px;
      width: 169px;
    }
  }
}

.showMore {
  width: 105px !important;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
