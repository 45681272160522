@use "../abstract/sizes" as *;
@use "../abstract/colors" as *;
@use "../abstract/fontVariables" as *;
@use "../abstract/mixins" as *;

h1, h2, h3, h4, h5, h6, p, span, label {
  // Utils
  &.reset {
    @include resetMarginPadding;
  }

  // Font family
  &.font-sofia {
    font-family: $fontSofia;
  }
  &.font-roboto {
    font-family: $fontPrimary;
  }

  // Font size
  &.md-10 {
    font-size: #{$md10}px;
  }
  &.md-12 {
    font-size: #{$md12}px;
  }
  &.md-14 {
    font-size: #{$md14}px;
  }
  &.md-16 {
    font-size: #{$md16}px;
  }
  &.md-18 {
    font-size: #{$md18}px;
  }
  &.md-20 {
    font-size: #{$md20}px;
  }
  &.md-22 {
    font-size: #{$md22}px;
  }
  &.md-24 {
    font-size: #{$md24}px;
  }
  &.md-26 {
    font-size: #{$md26}px;
  }
  &.md-32 {
    font-size: #{$md32}px;
  }
  &.md-36 {
    font-size: #{$md36}px;
  }
  &.md-48 {
    font-size: #{$md46}px;
  }

  // Colors
  &.black {
    color: $black;
  }
  &.white {
    color: $white;
  }
  &.dark {
    color: $dark;
  }

  // Weight
  &.w-100 {
    font-weight: 100;
  }
  &.w-200 {
    font-weight: 200;
  }
  &.w-300 {
    font-weight: 300;
  }
  &.w-400 {
    font-weight: 400;
  }
  &.w-500 {
    font-weight: 500;
  }
  &.w-600 {
    font-weight: 600;
  }
  &.w-700 {
    font-weight: 700;
  }
  &.w-800 {
    font-weight: 800;
  }
  &.w-900 {
    font-weight: 900;
  }

  // Display
  &.block {
    display: block;
  }
  &.inline-block {
    display: inline-block;
  }

  &.center {
    text-align: center;
  }
}