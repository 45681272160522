@use "ui-kit" as *;

.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row nowrap;
  overflow: scroll;
  position: relative;
  padding-bottom: 50px;

  .timelineColumn {
    flex: 0 0 100px;
    height: auto;
    border-right: 1px solid $strokeGray;
    background-color: $white;

    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-flow: column nowrap;

    position: sticky;
    z-index: 15;
    top: 0;
    left: 0;
    bottom: 0;

    .header {
      height: 77px;
      padding: 8px 24px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      box-sizing: border-box;
    }

    .timelineSlot {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      padding-right: 20px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba($white, 0);

      .hourString {
        margin: 0;
        padding: 0;
        font-family: $fontSofia;
        font-weight: 500;
        font-size: 14px;
        text-align: right;
        color: $black;
        transform: translateY(-11px);
      }
    }
  }

  .horizontalTimeline {
    position: absolute;
    left: 100px;
    height: 1px;
    background-color: rgba($red, .6);
    z-index: 5;

    .circle {
      border-radius: 50%;
      width: 7px;
      height: 7px;
      background-color: rgba($red, 1);
      position: absolute;
      left: -4px;
      top: -3px;
    }
  }
}
