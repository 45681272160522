@use "ui-kit-old" as *;

.container {
  width: auto;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin-right: 10px;
  box-sizing: border-box;

  .itemWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    .icon {
      margin-right: 7px;
    }
    .addIcon {
      width: 13px;
      height: 13px;
    }
    .copyIcon {
      width: 16px;
      height: 16px;
    }
  }
}