@use "ui-kit-old" as *;

.parentContainer {
  opacity: 0;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

.container {
  border-radius: 8px;
  background-color: $white;
  box-shadow: $boxShadow;
  margin-top: 50px;
  position: relative;
  box-sizing: border-box;
  pointer-events: none;
  opacity: 0;
  transition: height 0.3s, opacity 0.3s, transform 0.3s;
  overflow: hidden;

  &.fixed {
    overflow: visible;
  }

  @include touch {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    transform: translateY(100%);

    max-height: 100vh;
  }

  @include fromDesktop {
    min-width: 500px;
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
    max-height: calc(100vh - 100px);

    @include touch {
      transform: translateY(0);
    }
  }
}

.heightDefault {
  @include fromDesktop {
    height: 75vh;
  }

  @include upToDesktop {
    height: 90vh;
  }
}

.heightAuto {
  @include fromDesktop {
    height: auto;
  }

  @include upToDesktop {
    height: auto;
  }
}

.heightFull {
  @include fromDesktop {
    height: calc(100vh - 80px);
  }
}

.heightFullOr75 {
  @include fromDesktop {
    height: 75vh;
  }

  @include upToDesktop {
    position: fixed;
    top: 0px;
    min-height: 100%;
    border-radius: 0;
  }
}

.widthAuto {
  width: auto;
  max-width: 100%;
}

.widthThreeQuarter {
  width: 75%;
  max-width: 980px;

  @include upToWideDesktop {
    width: 85%;
  }

  @include upToTablet {
    max-width: 100%;
    width: 100%;
  }
}

.widthHalf {
  width: 50%;

  @include upToWideDesktop {
    width: 75%;
  }

  @include upToTablet {
    width: 100%;
  }
}

.widthThird {
  width: 33%;

  @include smallDesktop {
    width: 50%;
  }

  @include upToDesktop {
    width: 75%;
  }

  @include upToTablet {
    width: 100%;
  }
}

.widthQuarter {
  width: 25%;

  @include smallDesktop {
    width: 50%;
  }

  @include upToDesktop {
    width: 75%;
  }

  @include upToTablet {
    width: 100%;
  }
}

.widthTwoFifth {
  width: 40%;

  @include smallDesktop {
    width: 50%;
  }

  @include upToDesktop {
    width: 75%;
  }

  @include upToTablet {
    width: 100%;
  }
}

// Layouts
.defaultLayout {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "inner"
    "footer";
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.sidebarLayout {
  display: grid;
  grid-template-columns: 174px 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "sidebar header"
    "sidebar inner"
    "sidebar footer";
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

// Containers
.headerContainer {
  grid-area: header;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  border-radius: 8px;

  &.webApp {
    flex-direction: row;
    justify-content: center;
    height: 72px;
    background-color: $black;
  }

  &.webAppLogin {
    flex-direction: row;
    justify-content: center;
    height: 72px;
    background-color: #f3f2ee;
  }

  &.wizard {
    align-items: flex-start;
    background-color: $white !important;
  }
}

.sidebarContainer {
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
}

.footerContainer {
  grid-area: footer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.innerContainer {
  grid-area: inner;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;

  &.fixed {
    overflow: visible;
  }
}

// The rest
.scrollableInner {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &.fixed {
    overflow: visible;
  }
}

.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($white, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.2s;
  z-index: 49;
}

.closeButtonRow {
  flex: 0;
  width: 100%;
  padding: 15px;
  padding-bottom: 5px;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 19;

  @supports (-webkit-touch-callout: none) {
    min-height: 50px;
    padding: 35px 15px 15px;
  }

  .rightSide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: fit-content;
  }

  .headerButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 12px;

    button {
      margin: 0 4px;
    }
  }

  .closeButton {
    svg {
      width: 14px;
      height: 14px;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }

  &.webApp {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;
    background-color: $black;
    padding: 0 20px;

    svg,
    path {
      fill: $white;
      color: $white;
    }
  }

  &.webAppLogin {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;
    background-color: #f3f2ee;
    padding: 0 20px;
  }

  &.wizard {
    padding: 10px 16px;
    height: 50px;

    svg,
    path {
      fill: $white;
      color: $white;
    }
  }
}

.closeButton {
  width: 14px;
  height: 14px;
  margin-left: 10px;
  transform: scale(1);
  transform-origin: center;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }

  &.webApp {
    height: 17px;
    width: 17px;
  }

  &.wizard {
    margin-left: 0;
  }
}