@use "colors-legacy" as *;

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header {
    h3 {
      font-size: 18px;
      line-height: 18px;
      color: $text_color;
      margin-bottom: 8px;
      font-weight: 700;
    }

    label {
      font-size: 13px;
      margin-bottom: 4px;
    }

    > div {
      input {
        height: 30px;
        background: $color_white !important;
      }

      svg {
        top: 12px;
      }
    }
  }

  > hr {
    border-width: 2px;
    margin: 12px 0;
  }

  .filter {
    h3 {
      font-size: 16px;
      line-height: 16px;
      color: $text_color;
      margin-bottom: 10px;
      font-weight: 600;
    }

    > div {
      > span {
        margin-bottom: 4px;
      }
    }
  }

  .content {
    flex: 1;
    background: white;
    margin-top: 10px;
  }
}