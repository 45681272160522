@use "colors-legacy" as *;
@use "ui-kit-old" as *;

.miniContainer {
  width: 100%;
  margin-bottom: 10px;

  .miniRow {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  p {
    @include resetMP;
    font-size: 14px;
    color: black;
    line-height: 17px;

    &.miniHeader {
      color: #4f5b6d;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }
}

.innerTitle {
  @include resetMP;
  font-size: 12px;
  font-weight: 600;
  color: $activeBlue;
  margin-bottom: 15px;
}

.container {
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  .unitWrapper {
    display: flex;
    flex-direction: row;
    width: 175px;

    div {
      margin-bottom: 0;
    }

    .timeUnitSelect {
      margin-left: -2px;
      max-width: 100px;
    }
  }

  .fromTimeWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 250px;
    margin-left: -20px;

    div {
      margin-bottom: 0;
    }

    .secondaryLabel {
      position: absolute;
      bottom: 48px;
      font-size: 13px;
      font-weight: 600;
      color: $text_color_light;
      margin-top: 6px;
    }


    .fromTimeToggle {
      margin-bottom: 0;
      margin-right: -25px;
    }
  }
}