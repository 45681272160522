@use "ui-kit-old" as *;

.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  width: 100%;
}

.miniContainer {
  width: 100%;
  margin-bottom: 10px;

  .miniRow {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  p {
    @include resetMP;
    font-size: 14px;
    color: black;
    line-height: 17px;

    &.miniHeader {
      color: #4f5b6d;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }
}