@use "ui-kit-old" as *;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .spacesTable {
    height: 420px;
    overflow: hidden;
    padding: 15px 10px;
    border: 1px solid $lightGrayThree;
    border-radius: 4px;

    .tableHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      height: 20px;
      font-size: 14px;
      font-weight: $medium;
      margin-bottom: 10px;
      
      span {
        width: 150px;
      }

      .objectLabel {
        width: 190px;
      }
    }

    .tablebody {
      height: 100%;
      overflow: auto;
      padding-bottom: 200px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-flow: column nowrap;
      padding-right: 10px;
      
      .spaceRow {
        flex: 0 0 auto;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 3fr;
        grid-template-rows: 1fr;
        justify-content: space-between;

        box-sizing: border-box;
        
        margin-bottom: 5px;
        min-height: 44px;

        background: $lightGrayTwo;
        border: 1px solid $lightGrayThree;
        border-radius: 4px;
        padding: 10px;

        .objectSelect {
          justify-self: end;
          .dropdown {
            margin-bottom: 0;
          }
        }

        span {
          font-size: 14px;
        }
      }
    }
  }

  .button {
    align-self: flex-end;
  }
}