@use "ui-kit-old" as *;

.filterQueriesContainer {
  margin-top: 23px;
  width: 100%;

  .innerTitle {
    @include resetMP;
    font-size: 12px;
    font-weight: 600;
    color: $activeBlue;
    margin-bottom: 16px;
  }

  .noFiltersText {
    font-size: 14px;
    margin-bottom: 12px;
    color: $blueMist;
    font-weight: 600;
  }

  .newFilterContainer {
    position: relative;

    margin-top: 16px;

    .addRow {
      margin-top: 0px;
    }

    .dropdownContainer {
      position: relative;

      .newFilterDropdown {
        min-width: 200px;
      }
    }
  }
}