@use "ui-kit-old" as *;
@use "ui-kit-old" as *;

.componentContainer {
  flex: 0 0 auto;
  width: auto;

  padding: 0 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($gray, 1);

  &.loginTheme {
    background: none;
    background-color: transparent;
    border: none;
  }

  &.webAppTheme {
    background: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $black;
  }

  &.voucherA {
    background: none;
    height: 24px;
    pointer-events: none;
  }
  &.voucherB {
    height: 24px;
    pointer-events: none;
    font-weight: normal;
    p {
      font-size: 14px;
    }
  }
}

.prefix {
  border-right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.suffix {
  border-left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.text {
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.calendarPrefix {
  height: 38px;
}
