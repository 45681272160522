@use "ui-kit-old" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  flex: 0 0 auto;

  .iconWrapper {
    flex: 0 0 20px;
    width: 30px;
    margin-right: 13px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 30px;
      height: auto;
      opacity: 0;

      path {
        fill: $blueMist;
      }

      transition: opacity .2s;

      &.visible {
        opacity: 1;
      }
    }
  }

  .button {
    justify-content: space-between;
    flex: 0 0 200px;
    width: 200px;

    &.loading {
      justify-content: center;
    }
  }

}
