
@use "colors-legacy" as *;

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  * {
    font-weight: 500;
  }
  
  .inline {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .courseLabel {
    background: $text_color_light;
    border-radius: 5px;
    padding: 2px 5px;
    margin-left: 5px;
    color: $color_white;
    cursor: pointer;
  }

  .courseRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 25px;
  }

  .courseList {
    * {
      color: white;
    }
  }
}

