.filterContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;

  .dateInput {
    min-width: 130px;
    align-items: flex-start;

    label {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .timeInputContainer {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 0;

    .label {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  .link {
    color: #3B82F6;

    &:hover {
      text-decoration: none;
    }
  }
}
