@use "colors-legacy" as *;

.container {
  background: $color_white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 550px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px -4px $color_black;
  padding: 50px;

  h5 {
    margin-bottom: 20px;

    > div {
      font-size: 20px;
      font-weight: 500;
      display: inline;
    }
  }

  .sendLink {
    display: grid;
    grid-template-columns: 25px 1fr;
    margin-bottom: 30px;

    > div:first-of-type {
      margin-top: 3px;
    }
  }

  .choiceContainer {
    min-height: 10em;
    min-width: 100%;
  }

  .duration {
    margin-bottom: 15px;

    h4 {
      font-size: 20px;
      margin-bottom: 6px;

      > div {
        font-weight: 500;
      }
    }

    > div {
      margin-bottom: 0;

      > div {
        margin-bottom: 2px;

        label {
          font-size: 14px;
          font-weight: 500 !important;

          div {
            font-size: 14px;
            font-weight: 500 !important;
          }
        }
      }
    }
  }
  .durationPeriod {
    @extend .duration;
    display: flex;
    flex-wrap: nowrap;
    > div {
      &:first-child {
        margin-right: 8px;
      }
      &:last-child {
        margin-left: 8px;
      }
    }
  }
}
