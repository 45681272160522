@use "colors-legacy" as *;

.container {
  width: 100%;
  height: 100%;
  overflow: auto;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px 8px 20px;
    background: $very_light_green;

    div {
      font-size: 15px;
      font-weight: 500;
    }
  }

  .listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 6px 20px;

    &:nth-of-type(even) {
      background: $very_light_grey;
    }

    > div {
      font-size: 14px;
    }

    img {
      cursor: pointer;
    }
  }
}

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;

  div {
    max-width: 70%;
    color: $color_light_black;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
  }

  svg {
    width: 90px;
    height: 90px;

    circle {
      fill: $input_field_gray;
    }
  }
}