@use "ui-kit-old" as *;

.container {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  background-color: $white;
  padding: 5px;


  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: 10px;
    padding-right: 4px;
    box-sizing: border-box;

    .separator {
      width: 100%;
      height: 1px;
      margin: 0;
      padding: 0;
      border: 0;
      margin-left: auto;
      background-color: rgba($silverBlue, .6);
    }

    .colOne {
      flex: 1 1 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .input {
        margin: 0;
      }
    }
    .colTwo {
      flex: 0 0 150px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .colThree {
      flex: 0 0 38px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
    }

    .rowName {
      font-size: 15px;
      margin: 0;
      padding: 0;
      font-weight: 400;
      text-align: right;

      &.bold {
        text-decoration: underline;
        font-weight: 600;
      }
    }
    .editPen {
      width: 18px;
      height: 10px;
      padding-right: 8px;
      flex: 0 0 18px;
      transform: scale(1);
      transition: transform .2s;

      &:hover {
        cursor: pointer;
        transform: scale(1.5);
      }
    }
    .editPriceInput {
      flex: 0 1 70px;
      padding-right: 0;
      margin: 0;
    }
    .rowPrice {
      text-align: right;
      font-size: 15px;
      margin: 0;
      padding: 0;
      font-weight: 400;

      &.bold {
        font-weight: 700;
      }
    }
    .removeIcon {
      width: 15px;
      height: 15px;
      transform: scale(1);
      transition: transform 0.2s;
      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }
    }
    .addDiscountButton {
      margin: 0;

      .icon {
        margin-right: 7px;
      }
      .addIcon {
        width: 13px;
        height: 13px;
      }
    }
  }
}

.dropdownContainer {
  padding: 5px 0;
  padding-bottom: 0;
  width: 100%;

  .dropdownCategoryContainer {
    padding: 5px 0;
    padding-bottom: 0;
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;

    .dropdownCategoryTitle {
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 400;
      color: $blueMist;
      padding-left: 17px;
      margin-bottom: 5px;
    }
    .dropdownCategoryItemsContainer {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;

      .dropdownItemLabel {
        margin: 0;
        padding: 5px 0;
        font-size: 14px;
        font-weight: 400;
        color: $darkBlue;
        padding-left: 30px;
        padding-right: 10px;
        text-align: left;
        width: 100%;
        background-color: $white;

        transition: background-color 0.2s;

        &:hover {
          cursor: pointer;
          background-color: lighten($blue, 25);
        }
      }
    }
  }
}
