@use "colors-legacy" as *;

.header {
  padding: 12px 15px;
  position: relative;
  background: $text_color;
  border-radius: 10px 10px 0 0;

  > div {
    font-weight: 500;
    color: $color_white;
  }

  .closeIcon {
    position: absolute;
    right: 15px;
    top: 17px;
    cursor: pointer;

    path {
      fill: $color_white;
    }
  }
}

.body {
  padding: 10px 15px 0;
  overflow: visible;

  .label * {
    font-size: 13px;
  }

  input {
    padding: 0.25rem 0.75rem !important;
  }
}

.footer {
  padding: 15px 15px 0;
}

.loading {
  > div {
    > div > div > div {
      background-color: $text_color_light;
    }
  }
}

.actionGroup {
  display: flex;
  justify-content: space-between;

  button {
    margin-top: 0;
  }

  .btnGroup {
    display: flex;
    margin-left: auto;

    .cancel {
      > div {
        font-size: 15px;
        font-weight: 500;
        padding-top: 5px;
        margin-right: 20px;
        color: $darkBlue;
        cursor: pointer;
      }
    }
  }
}
