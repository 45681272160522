.container {
  position: relative;

  .positioner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .content {
      position: relative;
      height: 100%;
    }
  }
}