@use "colors-legacy" as *;

.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color_white, .9);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity .2s;
  z-index: 99;
}