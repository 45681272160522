@use "colors-legacy" as *;
@use "sizes-legacy" as *;

.container {
  .addOverride {
    padding: 0 10px;

    .columnContainer {
      font-size: 13px;
      padding: 0 5px;
      max-width: 75px;

      input {
        font-size: 14px;
        font-weight: 400;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        padding: 0.2rem;
        margin-top: 4px;
      }
    }
    .lg {
      max-width: 85px;
    }

    .addOverrideBtn {
      display: flex;
      align-items: flex-end;
    }
  }

  .override {
    font-size: 18px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 10px;
  }

  .overrideList {
    > div:first-of-type {
      font-size: 16px;
      margin: 10px 0;
      font-weight: bold;
      color: transparentize($color_black, 0.44);
    }

    .timeRange {
      display: flex;
      justify-content: space-between;
      width: 100px;
    }

    .priceValue {
      text-align: right;
      max-width: 80px;
    }

    > div:last-child {
      display: block;
      font-size: 14px;
      max-height: 120px;
      overflow-y: auto;

      > div {
        padding: 3px 0;
        width: 100%;
      }

      span {
        color: $color_black;
        font-weight: 500;
      }

      svg {
        cursor: pointer;
        font-size: 16px;

        path {
          color: $semi_medium_grey;
        }
      }
    }
  }
}
