@use 'colors-legacy' as *;
@use 'sizes-legacy' as *;

.ReactTable.-striped {
  border: none;
  font-size: 14px;

  .rt-tr.-odd {
    background: transparentize($color_black, 0.95) !important;
  }

  .rt-table {
    overflow: visible;
    flex: unset;
  }

  .rt-tbody {
    overflow: visible;
  }

  .rt-thead.-header {
    box-shadow: none;

    .rt-th {
      display: flex;
      align-items: center;
      border-right: none;
      text-align: left;

      * {
        font-weight: $font_weight_semibold;
        color: transparentize($color_black, 0.34);
      }
    }
  }

  .rt-tbody .rt-tr-group .rt-td,
  .rt-tbody .rt-tr-group .rt-td {
    border-right: none;
    font-weight: $font_weight_semibold;
    color: transparentize($medium_grey, 0.2);
    display: flex;
    align-items: center;
    overflow: visible;

    svg {
      font-size: 12px;

      path {
        color: $slightly_light_blue;
      }
    }

    svg.removeIcon {
      font-size: 15px;
      cursor: pointer;

      path {
        color: transparentize($medium_grey, 0.5);
      }
    }

    span {
      cursor: pointer;
      font-weight: $font_weight_semibold;
      color: transparentize($medium_grey, 0.3);
      display: flex;
      align-items: center;
      overflow: visible;
    }
  }

  .rt-tbody .rt-td.tableDropdown > div {
    input,
    div {
      font-size: $default_font_size;
    }
  }

  .rt-tbody .rt-tr-group .rt-td.toTime {
    color: transparentize($medium_grey, 0.3);
  }

  .rt-noData {
    background: unset;
    color: transparentize($color_black, 0.7);
    margin-top: 25px;
  }
}

.rectangle {
  width: 14px;
  height: 20px;
  margin-right: 5px;
}

/**
 * teetime override colors
 */

.open {
  background: $tee_sheet_green;
}

.members_only {
  background: $tee_sheet_dark_blue;
}

.start_forbidden {
  background: $tee_sheet_red;
}

.on_site {
  background: $light_purple;
}

.group_booking {
  background: $light_yellow;
}

.tournament {
  background: $tee_sheet_orange;
}

.blocked {
  background: $tee_sheet_dark_grey;
}

textarea {
  resize: none;
}

#grid-tooltip {
  max-width: 400px;
  pointer-events: none;

  .tooltip-inner {
    padding: 3px 15px 6px;
    background: $text_color !important;
  }

  .arrow::before {
    border-bottom-color: $text_color !important;
    border-top-color: $text_color !important;
  }

  * {
    color: $color_white;
  }

  .show {
    opacity: 1 !important;
  }

  .courseList {
    min-width: 100px;
    text-align: left;

    > div {
      margin: 3px 0;
    }
  }

  .rentalList {
    padding: 5px 0;
    text-align: left;
  }
}

#clipboard-tooltip {
  margin-top: 10px;

  .tooltip-inner {
    padding: 3px 15px 6px;
    background: $text_color !important;
  }

  .arrow::before {
    border-bottom-color: $text_color !important;
    border-top-color: $text_color !important;
  }

  * {
    color: $color_white;
  }
}

.tooltip,
.show {
  opacity: 1 !important;
}

.bs-tooltip-left .arrow::before {
  border-left-color: $text_color !important;
  left: -5px;
  transform: rotate(45deg);
}

#period-tooltip {
  width: 250px;

  .tooltip-inner {
    padding: 3px 15px 6px;
    background: $text_color !important;
  }

  .arrow::before {
    border-right-color: $text_color !important;
  }
}
