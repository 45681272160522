@use "ui-kit-old" as *;

.dropdownContainer {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: auto;
  min-width: 100%;
  max-height: 300px;
  overflow: auto;

  opacity: 0;
  pointer-events: none;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 3px;
  box-shadow: 0px 3px 6px rgba($black, .16);

  transition: opacity .2s;

  transform: translateY(100%);

  background-color: $white;

  &.active {
    opacity: 1;
    pointer-events: all;
    z-index: 99;
  }

  .optionRow {
    padding: 3.5px 7px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    min-width: 100%;

    box-sizing: border-box;

    background-color: $white;
    transition: background-color .2s;

    &:hover {
      cursor: pointer;
      background-color: darken($white, 5);
    }
    
    &:first-child {
      padding-top: 7px;
    }
    &:last-child {
      padding-bottom: 7px;
    }

    .icon {
      margin-right: 8px;
      width: auto;
      height: 18px;
    }

    .optionLabel {
      @include resetMP;
      font-size: 14px;
      color: $darkBlue;
    }

    &.disabled {
      &:hover {
        background-color: $white;
        cursor: not-allowed;
      }
      .optionLabel {
        color: $silverBlue;
      }
    }

  }
}