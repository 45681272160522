@import "../abstract/sizes";
@import "../abstract/colors";
@import "../abstract/fontVariables";

// Base
%_default-button {
  border-radius: 4px;
  padding: 0 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  font-weight: 500;
  font-size: 14px;
  font-family: inherit;
  outline: none;
  border: none;
  min-height: 0;
  text-wrap: nowrap;

  cursor: pointer;

  transition: color 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

button.system-button {
  @extend %_default-button;

  // Sizes
  &.md-36 {
    height: #{$md36}px;
  }
  &.md-32 {
    height: #{$md32}px;
  }
  &.md-24 {
    height: #{$md24}px;
  }

  &.ss-block-button {
    display: block;
    width: 100%;
  }

  &.ss-round-button {
    border-radius: 30px;
  }

  // Themes
  &.primary {
    color: $white;
    background-color: $mainBlue;

    &:hover {
      background-color: $blue;
    }

    &:active {
      background-color: darken($blue, 20);
    }

    &.disabled,
    &:disabled {
      background-color: $strokeGray;
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.primary-outline {
    color: $dark;
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: $strokeGray;

    &:hover {
      color: $mainBlue;
      border-color: rgba($blue, 0.2);
    }

    &:active {
      color: darken($mainBlue, 20);
      border-color: darken($blue, 20);
    }

    &.disabled,
    &:disabled {
      border-color: $strokeGray;
      color: $strokeGray;
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.secondary {
    color: $white;
    background-color: #6e86ab;

    &:hover {
      background-color: #6e86ab;
    }

    &:active {
      background-color: darken(#6e86ab, 20);
    }

    &.disabled,
    &:disabled {
      background-color: $strokeGray;
      pointer-events: none;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.light {
    color: $dark;
    background-color: $backgroundGray;

    &:hover {
      background-color: $backgroundGrayTwo;
    }

    &:active {
      background-color: darken($backgroundGrayTwo, 20);
    }

    &.disabled,
    &:disabled {
      background-color: $strokeGray;
      pointer-events: none;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.light-outline {
    color: $dark;
    background-color: $backgroundGray;
    border-width: 1px;
    border-style: solid;
    border-color: $backgroundGrayTwo;

    &:hover {
      color: $dark;
      background-color: darken($backgroundGray, 10);
      border-color: darken($backgroundGrayTwo, 10);
    }

    &:active {
      color: $dark;
      background-color: darken($backgroundGray, 10);
      border-color: darken($backgroundGrayTwo, 10);
    }

    &.disabled,
    &:disabled {
      border-color: $strokeGray;
      color: $strokeGray;
      pointer-events: none;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.info-outline {
    color: $mainBlue;
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: $mainBlue;

    &:hover {
      color: $white;
      background-color: $mainBlue;
      border-color: $mainBlue;
    }

    &:active {
      color: $white;
      background-color: $mainBlue;
      border-color: $mainBlue;
    }

    &.disabled,
    &:disabled {
      border-color: $strokeGray;
      color: $strokeGray;
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.danger {
    color: $white;
    background-color: $red;

    &:hover {
      background-color: darken($red, 20);
    }

    &:active {
      background-color: darken($red, 20);
    }

    &.disabled,
    &:disabled {
      background-color: $strokeGray;
      pointer-events: none;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.danger-outline {
    color: $red;
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: $red;

    &:hover {
      color: lighten($red, 20);
      border-color: lighten($red, 20);
    }

    &:active {
      color: darken($red, 20);
      border-color: darken($red, 20);
    }

    &.disabled,
    &:disabled {
      border-color: $strokeGray;
      color: $strokeGray;
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
    }

    &.no-border {
      border-color: rgba($red, 0);
    }
  }

  &.warning {
    color: $white;
    background-color: $orange;

    &:hover {
      background-color: darken($orange, 20);
    }

    &:active {
      background-color: darken($orange, 20);
    }

    &.disabled,
    &:disabled {
      background-color: $strokeGray;
      pointer-events: none;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.warning-outline {
    color: $orange;
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: $orange;

    &:hover {
      color: lighten($orange, 20);
      border-color: lighten($orange, 20);
    }

    &:active {
      color: darken($orange, 20);
      border-color: darken($orange, 20);
    }

    &.disabled,
    &:disabled {
      border-color: $strokeGray;
      color: $strokeGray;
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
    }

    &.no-border {
      border-color: rgba($orange, 0);
    }
  }

  &.with-border {
    border: 1px solid #cbcbcb;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

// Web app base
button.webapp-button {
  @extend %_default-button;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.1s;
  height: 3em;
  border-radius: 11px;

  // Sizes
  &.md-36 {
    height: #{$md36}px;
  }
  &.md-32 {
    height: #{$md32}px;
  }
  &.md-24 {
    height: #{$md24}px;
  }

  // Themes
  &.primary {
    color: $white;
    background-color: $sweetspotBlack;

    &:hover {
      background-color: lighten($sweetspotBlack, 10);
    }

    &:active {
      background-color: lighten($sweetspotBlack, 20);
    }

    &.disabled,
    &:disabled {
      background-color: $waDisabledGray;
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.primary-yellow {
    @extend .primary;
    background-color: $sweetspotYellow;
    color: $sweetspotBlack;

    &:hover {
      background-color: darken($sweetspotYellow, 15);
      border: 1px solid $sweetspotBlack;
    }

    &:disabled {
      color: $white;
    }
  }

  &.clubColors {
    @extend .primary;
    color: inherit;
    background-color: inherit;
    border: 1px solid;
    height: 2em;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 17px;
    padding: 0 0.5em;

    &:hover {
      background-color: inherit;
      filter: brightness(105%);
    }

    &:active {
      background-color: inherit;
      filter: brightness(95%);
    }

    &.rounded {
      border-radius: 1em;
    }
  }

  &.primary-outline {
    color: $dark;
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: rgba($dark, 0.4);

    &:hover {
      color: darken($dark, 20);
      border-color: darken(rgba($dark, 0.4), 20);
    }

    &:active {
      color: lighten($dark, 20);
      border-color: lighten(rgba($dark, 0.4), 20);
    }

    &.disabled,
    &:disabled {
      border-color: $waDisabledGray;
      color: $waDisabledGray;
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.danger-outline {
    color: $red;
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: $red;

    &:hover {
      color: lighten($red, 20);
      border-color: lighten($red, 20);
    }

    &:active {
      color: darken($red, 20);
      border-color: darken($red, 20);
    }

    &.disabled,
    &:disabled {
      border-color: $strokeGray;
      color: $strokeGray;
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
    }

    &.no-border {
      border-color: rgba($red, 0);
    }
  }

  &.ipad-align {
    @media (max-width: 1279px) {
      margin: 0 0 0.5rem 0;
    }
  }

  &.shadow-outline {
    color: $black;
    background-color: $white;
    box-shadow: 0px 1px 6px rgba($black, 0.16);

    &:hover {
      box-shadow: 0px 1px 6px rgba($black, 0.4);
    }

    &:active {
    }

    &.disabled,
    &:disabled {
      border-color: $waDisabledGray;
      color: $waDisabledGray;
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

.ss-btn-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  button.system-button {
    position: relative;
    flex: 1 1 auto;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
  }
}
