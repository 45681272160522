@use "ui-kit-old" as *;

$header_height: 40px;
$day_height: 28px;
$day_width: 32px;

$hover_background: transparentize($lightGrayPartner, .8);

.container {
  display: inline-block;
  border: 1px solid $lightGrayPartner;
  border-radius: 3px;
  overflow: hidden;
  background: white;
  z-index: 100;
  user-select: none;

  .headerContainer {
    height: $header_height;
    background: $medium_green;
    display: grid;
    grid-template-columns: $header_height 1fr $header_height;

    .arrowContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      cursor: pointer;

      .arrow {
        font-size: 20px;
        text-align: center;

        path {
          color: white;
        }

        &:hover {
          path {
            color: $dark_blue;
          }
        }
      }
    }

    .monthContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .month {
        font-size: 16px;
        text-align: center;
        color: white;
        padding-bottom: 2px;
      }
    }
  }

  .calendarContainer {
    background: transparentize($medium_green, .95);

    .calendarLabels {
      padding: 4px;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      background: transparentize($medium_green, .5);

      div {
        font-size: 14px;
        text-align: center;
        font-weight: 600;
      }
    }

    .calendarDays {
      padding: 4px;
      display: grid;
      grid-template-columns: repeat(7, $day_width);
      grid-template-rows: repeat(6, $day_height);

      .dayPanel {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-bottom: 1px;
        cursor: pointer;

        &:hover {
          background: $hover_background;
          border: 1px solid transparentize($lightGrayPartner, .4);
          border-radius: 4px;
        }

        &.inactive {
          div {
            color: transparentize($medium_grey, .2);
          }
        }

        &.disabled {
          cursor: not-allowed;
          div {
            color: transparentize($medium_grey, .2);
          }
          &:hover {
            color: transparentize($medium_grey, .2);
            background: white;
          }
        }

        &.selected {
          background: $hover_background;
          border: 1px solid transparentize($lightGrayPartner, .4);
          border-radius: 4px;
        }

        &.today {
          .dayText {
            font-weight: 500;

          }
        }

        .dayText {
          text-align: center;
        }
      }
    }
  }

}

.container {
  &.blueStyle {
    margin-top: 3px;
    .headerContainer {
      background: white;
      .month {
        color: $blue;
      }
      .arrow {
        path {
          color: $blue;
        }
      }
    }
    .calendarContainer {
      background: white;
      .calendarLabels {
        background: white;
        border-bottom: 1px solid transparentize($lightGrayPartner, .4);
      }
      .dayPanel {
        &:hover {
          background: transparentize($blue, .8);
          border-radius: 50%;
          border: none;
          div {
            color: $blue;
          }
        }

        &.selected {
          background: transparentize($blue, .8);
          border-radius: 30px;
          border: none;
          div {
            color: $blue;
          }
        }
      }
    }
  }
}
