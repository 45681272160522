@use "colors-legacy" as *;
@use "sizes-legacy" as *;

.container {
  height: 100%;
  float: left;
  padding: 0;

  .content {
    height: 100%;
    margin-top: 20px;
  }

  .header {
    text-align: center;
    font-size: 20px;
    font-weight: $font_weight_semibold;
    color: transparentize($color-black, .71);
  }
}