.container {
  position: relative;

  .textContainer {

  }

  .arrowContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
}