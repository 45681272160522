@use "colors-legacy" as *;

.container {
  display: inline-block;
  margin-top: 20px;
  font-size: 15px;

  .formField {
    position: relative;
    display: inline-block;

    label {
      color: $market_grey;
      margin-bottom: 5px;
      font-size: 15px;
      font-weight: 600;
    }

    > div {
      display: inline-block;
    }

    .btnGroup {
      position: absolute;
      right: 0;
      bottom: -10px;
      display: inline-flex;

      button {
        display: inline;
        margin: 0;
        margin-right: 6px;
        width: unset;
        flex: unset;
        z-index: 10;
      }
    }

    .colorList {
      display: flex;
      align-items: center;

      &.disabled {
        opacity: 0.5;

        .outer div {
          cursor: default;
        }
      }

      .outer {
        padding: 2px;
        margin-right: 5px;
        border-radius: 6px;
        border: 2px solid transparent;

        &.active {
          border: 2px solid $text_color;
        }

        > div {
          width: 18px;
          height: 18px;
          border-radius: 3px;
          cursor: pointer;
        }
      }
    }
  }

  .displaySettings {
    background: $very_light_green;
    padding: 10px 16px;
    border-radius: 3px;
    margin-top: 20px;
    max-width: 500px;

    > label {
      font-size: 15px;
      font-weight: 600;
      color: $text_color;
    }

    > p {
      color: $market_grey;
      margin-bottom: 3px;
      font-weight: 600;
      font-size: 13px;
      margin-top: 16px;
    }

    > div {
      margin-bottom: 0;

      > div {
        margin-bottom: 3px;
      }

      label {
        font-size: 14px;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 3px;
      }
    }

    .secondSetting {
      > div {
        display: flex;
        margin-top: 10px;

        label {
          margin-right: 10px;

          &:last-of-type {
            margin-top: -2px;
          }
        }
      }
    }

    .formField {
      margin-bottom: 20px;

      .btnGroup {
        bottom: -30px;
      }
    }
  }
}
