@use "ui-kit-old" as *;

.container {
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 0px;
  opacity: 0;
  height: auto;
  overflow: hidden;
  z-index: 99;
  background-color: $white;

  border-radius: 3px;

  overflow-y: auto;
  
  box-shadow: 0px 3px 6px rgba($black, .16);

  transform: translateY(100%);

  transition: max-height .1s, opacity .1s;
}
.containerVisible {
  opacity: 1;
  max-height: 200px;
}

.itemContainer {
  flex: 0 0 auto;
  width: 100%;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: rgba($white, 1);

  transition: background-color .2s;

  &:hover {
    background-color: rgba($lightBlue, 1);
    cursor: pointer;
  }
}
.itemContainerDisabled {
  background-color: rgba($lightGray, .7);
  &:hover {
    background-color: rgba($lightGray, .7);
    cursor: not-allowed;
  }
}
.itemContainerNoAvailable {
  background-color: rgba($white, 1);
  &:hover {
    background-color: rgba($white, 1);
    cursor: default;
  }
}

.itemLabel {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 400;
  color: $darkBlue;
}