@use "ui-kit-old" as *;

.container {
  width: 0px;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  transition: opacity 0.2s, width 0.2s;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;

  &.visible {
    opacity: 1;
    width: 600px;
  }

  .header {
    border-radius: 8px;
    width: 100%;
    position: relative;
    background-color: $darkBlue;
    padding: 15px 12px 12px 35px;
    box-sizing: border-box;
    margin-bottom: 9px;

    box-shadow: $hardBoxShadow;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .starIcon {
      position: absolute;
      top: 23px;
      left: 13px;
      width: 10px;
      height: 10px;
    }

    .closeIcon {
      position: absolute;
      top: 14px;
      right: 14px;
      width: 12px;
      height: 12px;
    }

    .buttonRow {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;

      .reserveNew {
        justify-content: flex-start;
        padding: 0;
        margin-right: 5px;
        p {
          font-weight: 700;
          flex: 0 0 100%;
          width: 100%;
          text-align: left;
          margin-bottom: 5px;
        }
      }

      .printButton {
        height: 28px;
        margin-left: auto;
        margin-bottom: 0;
        margin-right: -55px;
        align-self: flex-end;
      }
    }
  }

  .bookings {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column nowrap;

    .bookingBox {
      margin-bottom: 5px;
      width: 100%;
    }
  }
}

.confirmText {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;

  .bold {
    font-weight: 700;
  }
  &.bold {
    font-weight: 700;
  }
}

.bottomContainer {
  margin-top: 5px;
  border: 1px solid rgba(210, 213, 218, 0.5);
}

.notesContainer {
  margin: 0;
}

.notes {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.addNoteContainer {
  transition: none;
}

.inputContainer {
  textarea {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
