@use "ui-kit-old" as *;

.container {
  padding: 12px;
  width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $lightGraySix;

  &:last-child {
    border-bottom: none;
  }

  .column {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column nowrap;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }


  .row {
    height: auto;
    width: calc(50% + 6px);
    min-width: 350px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.firstRow {
      margin-bottom: 12px;
    }

    &.bookedSlots {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.gray {
      p {
        color: $blueMist;
      }
    }

    .bookedSlotsWrapper {
      .column {
        margin-bottom: 10px;
      }

      &:last-child {
          margin-bottom: 0; 
      }
    }

    .range {
      font-weight: $semiBold;
      color: $black;
    }

    .innerRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .lastItem {
      text-align: right;
    }
  }
}