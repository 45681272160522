@use "ui-kit-old" as *;

.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  width: 100%;

  .spacesContainer {
    width: 100%;
    background: none;
    
    &::first-child {
      width: 100%;
    }
  }
}