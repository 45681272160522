@use "ui-kit-old" as *;
@use "component-classes/formElements" as *;

.wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  height: 28px;
  border-radius: 5px;
  overflow: hidden;
  flex-flow: row nowrap;
}

.containerDisabled {
  &:hover {
    cursor: not-allowed;
  }
}


.option {
  flex: 1 1 auto;
  text-align: center;

  border-width: 1px;
  border-style: solid;
  border-color: $silverBlue;

  background-color: rgba($white, 0);

  transition: border-color .2s, background-color .2s;

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &:hover {
    cursor: pointer;
    background-color: lighten($activeBlue, 5);
    border-color: lighten($activeBlue, 5);
  }
  &:active {
    background-color: darken($activeBlue, 10);
    border-color: darken($activeBlue, 10);
  }

}
.optionSelected {
  background-color: $activeBlue;
  border-color: $activeBlue;
}
.optionDisabled {
  pointer-events: none;
  &:hover {
    cursor: not-allowed;
    background-color: rgba($white, 0);
    border-color: $silverBlue;
  }
  &:active {
    background-color: rgba($white, 0);
    border-color: $silverBlue;
  }
}
.selectedAndDisabled {
  &:hover {
    cursor: not-allowed;
    background-color: $activeBlue;
    border-color: $activeBlue;
  }
  &:active {
    background-color: $activeBlue;
    border-color: $activeBlue;
  }
}

.label {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 3px;
  color: $silverBlue;

  transition: color .2s;

  &:hover {
    color: $white;
  }
}
.labelSelected {
  color: $white;
}