@use "colors-legacy" as *;
@use "ui-kit" as *;

.container {
  position: relative;
  width: 140px;

  .clearButton {
    position: absolute;
    width: 8px;
    right: 23px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    cursor: pointer;
  }

  input {
    border: 1px solid transparent;
    background: transparent !important;
    padding: 2px 35px 2px 10px;
    font-size: 14px;
    overflow: hidden;
    height: 32px;
    text-overflow: ellipsis;

    &:hover {
      cursor: pointer;
    }

    &::placeholder {
      font-weight: 400;
    }

    &.sm {
      height: 25px;
    }
  }

  .orange {
    color: $light_orange;
  }

  .red {
    color: $light_red;
  }

  .green {
    color: $light_green;
  }

  .grey {
    color: transparentize($medium_grey, 0.3);
    font-size: 13px;
  }

  .bold {
    font-weight: 600;
  }

  &.blueStyle {
    border-color: $medium_blue !important;

    input {
      border: none !important;
    }
  }

  &.open {
    input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid $input_field_gray;
    }
  }

  &.disabled {
    cursor: not-allowed;
    border: 1px solid rgba($darkBlue, 0.8);
    color: rgba($darkBlue, 0.8);
  }

  &.noStrikethroughWhenDisabled {
    border: none;
  }

  &.disabledStyled {
    cursor: not-allowed !important;
    background-color: $backgroundGray;

    input {
      color: $strokeGray;
    }
  }

  &.hideArrow {
    svg {
      display: none;
    }
  }

  &.showBorder {
    border: 1px solid $input_field_gray;
    border-radius: 4px;

    &.open {
      border-color: $medium_blue;
    }

    input {
      border: none;
    }
  }

  svg {
    position: absolute;
    top: 12px;
    right: 14px;
    transform: rotate(90deg);

    &.sm {
      top: 10px;
      right: 12px;
    }
  }

  .selectedDesc {
    position: absolute;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    font-size: 14px;
    font-weight: 600;

    .desc {
      display: flex;
      gap: 6px;
    }

  }


  .listContainer {
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    right: 0;
    max-height: 225px;
    border: 1px solid $light_grey;
    border-top: none;
    background: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: $box_shadow;

    &.noMargin {
      top: calc(100% - 5px);
    }

    &.anchorTop {
      top: unset;
      bottom: calc(100% + 1px);
    }

    .list {
      height: 100%;
      overflow: scroll;
      overflow-x: hidden;
      overflow-y: auto;

      .listItem {
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        cursor: pointer;

        .name {
          flex: 1;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .desc {
          text-align: left;
          display: flex;
          gap: 6px;

          >div {
            padding-top: 2px;
          }

          &.active>div {
            color: $color_light_green;
          }
        }

        &:hover {
          background: $medium_light_blue;
        }

        &.focused {
          background: $medium_light_blue;
        }

        &.textAlignLeft {
          text-align: left;
        }
      }
    }
  }

  &.largeText {
    .listItem {
      height: 40px !important;
      font-size: 22px !important;
      font-weight: bold;
      text-align: center;
      color: $tee_sheet_blue;
    }
  }

  .status {
    width: calc(100% - 30px);
    position: absolute;
    top: 0;
    left: 10px;
  }

  &.fixTextLayout {
    input {
      margin-top: -6px;
    }

    svg {
      margin-top: 6px;
    }
  }
}

.displayBlock {
  display: block;
}

.displayNone {
  display: none;
}