@use "ui-kit-old" as *;

.infoHover {
  display: inline-block;
  width: 18px;
  height: 18px;
  position: relative;
  right: -10px;
  top: -2px;
  font-size: 16px;
  font-weight: bold;

  &.withTextInputField {
    right: 10px;
  }

  img {
    width: 100%;
    height: auto;
    opacity: 0.5;
  }

  .miniPill {
    position: relative;
    top: 3px;
    width: 28px;
    height: 20px;
    background: $midGray;
    border-radius: 6px;
    color: $white;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
  }

  .infoHoverTooltip {
    position: absolute;
    top: -6px;
    left: 38px;

    & > div {
      width: 275px;
      position: absolute;
      box-shadow: rgba(0, 0, 0, 0.3) 2px 1px 4px -1px;
      border: 1px solid $darkBlue;
      border-radius: 5px;
      padding: 10px;
      margin: 0;
      white-space: pre-line;
      background: $darkBlue;
      z-index: 500;
      color: $white;
    }

    .arrow {
      position: absolute;
      top: 10px;
      right: -18px;
      width: 18px;
      height: 21px;
      background: $darkBlue;
      transform: rotate(45deg);
      border-radius: 0;
      box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
    }

    div {
      line-height: 1.25;
      font-size: 15px;
      font-weight: 500;
    }
  }
}
