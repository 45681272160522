@use "ui-kit" as *;

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  box-shadow: none;
  border: none;
  background-color: transparent;
  margin-bottom: 0 !important;

  padding: 6px 31px;
}
