@use "../abstract/responsive" as *;

.w-auto {
  width: auto;

  &.w-flex {
    flex: 0 0 auto;
  }
}

.w-25p {
  width: 25%;
  &.w-flex {
    flex: 0 0 25%;
  }
}
.w-50p {
  width: 50%;
  &.w-flex {
    flex: 0 0 50%;
  }
}
.w-75p {
  width: 75%;
  &.w-flex {
    flex: 0 0 75%;
  }
}
.w-100p {
  width: 100%;
  &.w-flex {
    flex: 0 0 100%;
  }
}

.w-25p-phone {
  @include phone {
    width: 25%;
    max-width: 25%;
    &.w-flex {
      flex: 0 0 25%;
    } 
  }
}
.w-50p-phone {
  @include phone {
    width: 50%;
    max-width: 50%;
    &.w-flex {
      flex: 0 0 50%;
    } 
  }
}
.w-75p-phone {
  @include phone {
    width: 75%;
    max-width: 75%;
    &.w-flex {
      flex: 0 0 75%;
    }
  }
}
.w-100p-phone {
  @include phone {
    width: 100%;
    max-width: 100%;
    &.w-flex {
      flex: 0 0 100%;
    }
  }
}

.w-flex {
  &.flex-0-0 {
    flex-grow: 0;
    flex-shrink: 0;
  }
  &.flex-1-0 {
    flex-grow: 1;
    flex-shrink: 0;
  }
  &.flex-1-1 {
    flex-grow: 1;
    flex-shrink: 1;
  }
  &.flex-0-1 {
    flex-grow: 0;
    flex-shrink: 1;
  }
}

