@use "colors-legacy" as *;

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;

  .title {
    font-size: 20px;
  }

  .noAccess {
    div {
      font-weight: bold;
      font-size: 22px;
    }
  }

  .bookingModalContainer {
    position: absolute;
    top: 10px;
    left: 10px;
    bottom: 10px;
    right: 10px;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 100px);
  width: 100%;
  margin-top: 100px;
  position: absolute;

  >div {
    text-align: center;

    svg {
      width: 75px;
      height: 75px;

      path {
        fill: $semi_medium_grey;
      }
    }

    &.loading {
      >div {
        margin-bottom: 20px;

        >div>div>div {
          background-color: $text_color_light;
        }
      }
    }
  }

  .emptyState {
    font-size: 28px;
    color: $text_color_light;
    margin-top: 10px;
  }
}