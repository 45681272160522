@use "ui-kit-old" as *;

.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: column nowrap;
  position: relative;
  width: 200px;
}
