@use "ui-kit-old" as *;

.container {
  height: auto;
  max-width: 550px;
  width: 100%;

  display: grid;

  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: 100%;
  grid-template-rows: 52px 1fr 48px;
  grid-template-areas:
    'header'
    'inner'
    'footer';

  opacity: 0;
  transform: scale(0.9);

  transition: opacity 0.2s, transform 0.2s;

  background-color: $white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: $boxShadow;
}

.containerVisible {
  opacity: 1;
  transform: scale(1);
}

.header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $darkBlue;
  padding: 0 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .titleIcon {
    height: auto;
    margin-top: 3px;

    width: 18px;
    margin-right: 13px;

    &.titleIconDefaultColor {
      color: $silverBlue;
      fill: $silverBlue;

      path {
        color: $silverBlue;
        fill: $silverBlue;
      }
    }
  }

  .headerTitle {
    color: $white;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  .closeIcon {
    margin-left: auto;
    color: $white;
    fill: $white;

    width: 14px;
    height: 14px;

    transform: scale(1);

    transition: transform 0.2s;

    path {
      color: $white;
      fill: $white;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }
}

.inner {
  grid-area: inner;
  padding: 32px 22px;

  .innerText {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
  }
}

.footer {
  grid-area: footer;

  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 20px;

  .button {
    margin: 0;
    margin-left: 10px;
  }
}
