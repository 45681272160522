@use "ui-kit-old" as *;

.playerCell {
  white-space: pre-wrap !important;
  &:before {
    width: 100%;
    height: 8px;
    background-color: #fff;
    top: 0px;
    left: 0;
    position: absolute;
  }
  &:after {
    width: 100%;
    height: 8px;
    background-color: #fff;
    bottom: 0px;
    left: 0;
    position: absolute;
  }
}

.caretDown {
  width: 8px;
  height: auto;
  transform: rotate(-90deg);

  transition: transform 0.2s;

  * {
    fill: $darkBlue;
  }

  &.isOpen {
    transform: rotate(0deg);
  }
}

.container {
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 0px;

  &.openContainer {
    background-color: #fff;
  }

  .header {
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .leftSide {
      flex: 1 1 auto;
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:hover {
        cursor: pointer;
      }
    }
    .rightSide {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .caretContainer {
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .caretDown {
        width: 8px;
        height: auto;
        transform: rotate(-90deg);

        transition: transform 0.2s;

        * {
          fill: $darkBlue;
        }

        &.isOpen {
          transform: rotate(0deg);
        }
      }
    }

    .headerTitle {
      @include resetMP;
      font-size: 14px;
      font-weight: 600;
      color: $darkBlue;
    }
  }

  .foldContainer {
    overflow-y: scroll;
    overflow-x: hidden;
    height: auto;
    max-height: 0;
    transform: scaleY(0);
    transform-origin: top;

    transition: transform 0.2s;

    &.isOpen {
      overflow: visible;
      max-height: none;
      transform: scaleY(1);
    }
  }

  .topRow {
    padding-top: 0px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
  }
}
