@use "ui-kit-old" as *;

.container {
  .noDaysSelectedText {
    @include resetMP;
    margin-top: 0;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    color: $midGray;
  }

  .pillsContainer {
    flex: 1 1 100%;
    flex-flow: row wrap;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .miniPill {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: auto;
      margin-right: 8px;
      margin-bottom: 8px;
      justify-content: space-between;
      background: $activeBlue;
      color: $white;
      border-radius: 15px;
      font-size: 14px;
      height: 20px;
      padding: 0 8px;

      &:hover {
        cursor: default;
        background: $activeBlueLight;
      }

      span {
        margin-top: -2px;
        font-weight: 400;
        color: $white;
      }

      .closeButton {
        margin-top: -3px;
        margin-left: 8px;
        font-weight: bold;
        cursor: pointer;

        svg {
          height: 8px;

          path {
            fill: $white;
          }
        }
      }
    }
  }
}

// Has to be outside since it's not actually inside the .container in DOM
.timeDropdownContainer {
  .dropdownTitle {
    @include resetMP;
    font-size: 12px;
    font-weight: 400;
    color: $blueMist;
    margin-bottom: 8px;
  }

  .inputsRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .input {
      margin-bottom: 16px;
      &:first-child {
        margin-right: 4px;
      }
    }
  }

  .buttonsRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;

    .button {
      flex: 1 1 50%;
      width: 50%;
      margin-bottom: 0;
      &:first-child {
        margin-right: 4px;
      }
    }
  }
}
