@use "ui-kit" as *;

.ss-form-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
}

.ss-form-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.ss-input-row {
  height: 32px;
  width: 200px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;

  grid-template-areas: "prefix input suffix";

  &:focus-within {
    .ss-prefix-suffix {
      border-color: $mainBlue;
    }
  }

  &.focused {
    input.ss-input {
      border-color: $mainBlue;
    }

    .ss-prefix-suffix {
      border-color: $mainBlue;
    }
  }

  &.disabled {
    input.ss-input {
      background-color: $backgroundGray;
    }

    .ss-prefix-suffix {
      background-color: $backgroundGray;
    }
  }

  &.error {
    input.ss-input {
      border-color: rgba($orange, 1);
    }

    .ss-prefix-suffix {
      border-color: rgba($orange, 1);
    }
  }
}

.ss-form-group {
  margin-bottom: 1rem;
}

label.ss-label {
  font-size: 12px;
  color: $dark;
  font-family: inherit;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;

  &.bold {
    font-weight: 600;
  }
}

.ss-input {
  grid-area: input;

  box-sizing: border-box;
  min-width: 0;

  display: block;
  width: 100%;
  line-height: 1.5;
  border-width: 1px;
  border-style: solid;
  border-color: $strokeGray;
  border-radius: 4px;
  background-color: $white;
  padding: 0 11px;

  font-family: inherit;
  color: $black;
  font-size: 14px;

  transition: border-color 0.2s, background-color 0.2s;

  &.focused,
  &:focus {
    border-color: $mainBlue;
  }

  &.error {
    border-color: $red;
  }

  &.disabled,
  &:disabled {
    background-color: $backgroundGray;
  }

  &.has-prefix {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.has-suffix {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.prefix-inline {
    border-left: 0;
    padding-left: 0;
  }

  &.suffix-inline {
    border-right: 0;
    padding-right: 0;
  }
}

input.ss-input {
  height: 32px;
}

div.ss-prefix-suffix {
  padding: 0 10px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $white;

  border-width: 1px;
  border-style: solid;
  border-color: $strokeGray;
  border-radius: 4px;

  transition: border-color 0.2s, background-color 0.2s;

  &:hover {
    cursor: pointer;
  }

  &.prefix {
    grid-area: prefix;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.suffix {
    grid-area: suffix;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.box {
    background-color: $iconGray;
  }

  &.inline {
    background-color: $white;
  }

  > p {
    font-size: 14px;
    color: $iconGray;
    font-family: $fontPrimary;
    margin: 0;
    padding: 0;
  }
}

.ss-input-group {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;

  & .ss-input {
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.ss-invalid-feedback {
  color: $red;
  display: block;
  font-size: .875em;
  margin-top: 0.25rem;
  width: 100%;
}

