@use "colors-legacy" as *;

.container {
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  .unitWrapper {
    display: flex;
    flex-direction: row;
    width: 175px;

    div {
      margin-bottom: 0;
    }
  
    .timeUnitSelect{
      margin-left: -2px;
      max-width: 100px;
    }
  }

  .fromTimeWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 250px;
    margin-left: -20px;

    div {
      margin-bottom: 0;
    }

    .secondaryLabel {
      position: absolute;
      bottom: 48px;
      font-size: 13px;
      font-weight: 600;
      color: $text_color_light;
      margin-top: 6px;
    }


    .fromTimeToggle {
      margin-bottom: 0;
      margin-right: -25px;
    }
  }
}

