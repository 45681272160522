@use "ui-kit-old" as *;

.container {
  background-color: $white;
  width: 50%;
  border-radius: 11px;
  min-width: 620px;
  overflow: auto;

  .gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;

    @include upToDesktop {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      width: calc(100% - 32px);
      overflow-y: scroll;
      max-height: 70vh;
      grid-gap: 0px;
      overflow-wrap: break-word;
      padding-left: 12%;
      padding-top: 5px;
    }
  }

  .shadowContainer {
    @include upToDesktop {
      margin-left: -32px;
      margin-right: -32px;
    }
  }
  .withShadow {
    box-shadow: inset 0px 8px 8px -10px $midGray, inset 0px -8px 8px -10px $midGray;
  }

  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: $bold;

    @include upToDesktop {
      margin-bottom: 12px;
    }

    .closeIcon {
      cursor: pointer;
      align-self: flex-start;
      @include upToDesktop {
        display: none;
      }
    }
  }
  .innerContainer {
    padding: 32px;
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 22px 0px 0px 0px;
    @include upToDesktop {
      flex-direction: column;
    }

    @include phone {
      flex-flow: column nowrap;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
    }

    .button {
      padding: 0px 32px;
      border-radius: 11px;
      font-size: 18px;
      justify-content: center;
      height: 48px;
      margin: 0;

      &:not(:last-child) {
        margin-right: 16px;
      }

      @include upToDesktop {
        width: 100% !important;
        margin: 24px 0px;
        margin-right: 0;
        margin-top: 0;
        max-width: 100%;

        &:not(:last-child) {
          margin-bottom: 10px;
          margin-right: 0;
        }
      }
    }
  }
  .closeButton {
    display: none;
    justify-content: center;
    cursor: pointer;
    @include upToDesktop {
      display: flex;
    }
    .closeText {
      margin: 0px;
      @include upToDesktop {
        color: $black;
      }
    }
  }
  @include upToDesktop {
    width: 100%;
    min-width: auto;
  }
}

.animateIn {
  animation-name: animateInKey;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  overflow: hidden;
}

.animateOut {
  @include upToDesktop {
    animation-name: animateOutKey;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    overflow: hidden;
  }
}

@keyframes animateInKey {
  from {
    max-height: 0%;
  }
  to {
    max-height: 100%;
  }
}
@keyframes animateOutKey {
  from {
    max-height: 100%;
  }
  to {
    max-height: 0%;
  }
}
