@use "shared-legacy" as *;

.container {
  width: 100%;
  height: auto;
  margin: 10px 0;
  padding: 0 13px;

  svg {
    color: $text_color_light;
  }
}

.containerLevelTwo {
  padding-right: 0;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 52px;
  background-color: rgba($very_light_green, .5);
  border-radius: 7px;
  padding: 0 20px;

  cursor: pointer;
}
.headerError {
  border: 1px solid $settings_orange;
}
.headerOpen {}

.caretIcon {
  width: 11px;
  height: auto;

  margin: 0;
  padding: 0;
  margin-right: 24px;

  transform: rotate(-90deg);
  transition: transform .2s;
}
.caretIconActive {
  transform: rotate(0deg);
}

.headerTitle {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
  color: $text_color;

  flex: 1 0 50%;
  word-wrap: unset;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.innerContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 0 17px;
  max-height: 0vh;
  opacity: 0;
  overflow: hidden;

  transition: max-height .2s, padding .2s, opacity .1s;
}

.innerContainerLevelOne {}
.innerContainerLevelTwo {
  padding: 0px 0px;
  padding-left: 17px;
}


.innerContainerOpen {
  opacity: 1;
  max-height: unset;
  overflow: visible;
}
.innerContainerOpenLevelOne {
  padding: 20px 17px;
}
.innerContainerOpenLevelTwo {
  padding: 20px 0px;
  padding-left: 17px;
}

.innerContainerNoPadding {
  padding: 0;
}

.deleteContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}