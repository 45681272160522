@use "ui-kit-old" as *;

.container {
  width: 100%;
  height: auto;
  background-color: $white;
  border-radius: 3px;
  border: 1px solid $lightGrayThree;

  margin-bottom: 15px;

  .header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .leftSide {
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .rightSide {
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .removeIconContainer {        
        .removeIcon {
          width: 15px;
          height: 15px;
        }
  
        &:hover {
          cursor: pointer;
          svg path {
            fill: $red;
          }
        }

        &.disabled:hover  {
          cursor: not-allowed;
        }
      }
    }

    .headerTitle {
      @include resetMP;
      font-size: 12px;
      font-weight: 600;
      color: $activeBlue;
    }
  }

  .foldContainer {

    max-height: 0;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: top;

    transition: transform .2s;

    &.isOpen {
      overflow: visible;
      max-height: none;
      transform: scaleY(1);
    }
  }

  .innerContainer {
    padding: 16px 24px;
    padding-top: 0;
    box-sizing: border-box;

    .innerTitle {
      @include resetMP;
      font-size: 12px;
      font-weight: 600;
      color: $activeBlue;
      margin-bottom: 15px;
    }
  }
}