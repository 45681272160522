@use "ui-kit-old" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  .caret {
    margin-right: 3px;
    transform: rotate(-90deg);
    width: 9px;
    height: auto;

    transition: transform .2s;

    &.open {
      transform: rotate(0deg);
    }
  }
}