@use "colors-legacy" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
}
