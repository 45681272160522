@use "../abstract/colors-old" as old;

// Heights
.defaultHeight {
  height: 32px;
  box-sizing: border-box;
}


// Borders
.borderDefault {
  border: 1px solid rgba(old.$silverBlue, .5);
  border-color: rgba(old.$silverBlue, .5);
  transition: border-color .2s;
  &:focus, &:active {
    border-color: rgba(old.$mainBlue, 1);
  }
}
.borderFilled {
  border-color: rgba(old.$silverBlue, 1);
}
.borderError {
  border-color: rgba(old.$orange, 1);
}
.borderSuccess {
  border-color: rgba(old.$green, 1);
}
.borderDisabled {
  border-color: rgba(old.$silverBlue, .5);
    &:focus, &:active {
    border-color: rgba(old.$silverBlue, .5);
  }
}
.borderActive {
  border-color: rgba(old.$mainBlue, 1);
}