@use "colors-legacy" as *;

$height: 40px;

.container {
  width: 300px;
  height: $height;
  border-radius: calc($height / 2);
  background: transparentize($light_grey, .65);
  display: grid;
  grid-template-columns: 40px 1fr;
  cursor: text;

  .iconContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    div {
      text-align: center;
      font-size: 18px;

      path {
        color: $text_color;
      }
    }
  }

  .placeholder {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 1px;

    div {
      font-size: 16px;
      color: transparentize($text_color, .1);
      font-weight: 400;
    }
  }

  .inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 15px;

    input {
      background: transparent;
      border: none;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &.bordered {
    background: none;
    border: 1px solid transparentize($color_black, .81);
    border-radius: 5px;
    height: 30px;

    svg {
      font-size: 16px;

      path {
        fill: transparentize($text_color, .3);
      }
    }
  }
}
