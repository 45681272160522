@use "ui-kit-old" as *;

.golfCartVariantsContainer {
  min-width: 275px;
  min-height: fit-content;
  max-width: 300px;
  max-height: 15em;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000;
  top: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 3px;
  left: 123px;
  background-color: $white;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  overflow-y: auto;

  .innerContainer {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 2em;
    column-gap: 2ch;
    align-items: center;

    &:hover {
      background-color: #c0defb;
    }
  }

  .golfCartNameContainer {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 10px;
  }

  .golfCartPriceContainer {
    padding-right: 10px;
  }

  .disabledColfCart {
    pointer-events: none;
    color: $midGray;
    > span {
      color: $midGray;
    }
    > div {
      color: $midGray;
    }
  }
}
