@use "ui-kit-old" as *;

.container {
  width: 100%;
  height: auto;
  background-color: $white;
  border-radius: 3px;
  border: 1px solid $lightGrayThree;

  margin-bottom: 15px;

  position: relative;

  .pulse {
    position: absolute;
    top: 0;
    left: 0;
  }

  .header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .leftSide {
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rightSide {
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .removeIconContainer {
        transform: scale(1);
        transition: transform 0.2s;

        .removeIcon {
          width: 13px;
          height: 13px;
        }

        svg path {
          transition: fill 0.2s;
        }

        &:hover {
          cursor: pointer;
          transform: scale(1.1);

          svg path {
            fill: $red;
          }
        }

        &.disabled:hover {
          cursor: not-allowed;
        }
      }
    }

    .headerTitle {
      @include resetMP;
      font-size: 12px;
      font-weight: 600;
      color: $activeBlue;
    }

    .headerTitleHover {
      cursor: default;
    }

    .headerHint {
      top: 2px;
    }
  }

  .foldContainer {
    max-height: 0;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: top;

    transition: transform 0.2s;

    &.isOpen {
      overflow: visible;
      max-height: none;
      transform: scaleY(1);
    }
  }

  .innerContainer {
    padding: 16px 24px;
    padding-top: 0;
    box-sizing: border-box;
  }
}