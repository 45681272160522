@use "ui-kit-old" as *;
@use "component-classes/formElements" as *;

.container {
  touch-action: pan-x;
  display: inline-block;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  &.noMargin {
    margin-bottom: 0;
  }
}

.toggleInput {
  cursor: pointer;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.check,
.uncheck {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  opacity: 0;
  transition: opacity 0.25s ease;
}
.check {
  left: 8px;
}
.uncheck {
  opacity: 1;
  right: 10px;
}

.uncheck span,
.check span {
  align-items: center;
  display: flex;
  height: 10px;
  justify-content: center;
  position: relative;
  width: 10px;
}

.labelRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.toggleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.wrapper {
  width: 24px;
  height: 14px;
  padding: 0;
  border-radius: 30px;
  background-color: $gray;
  border: 1px solid $blueMist;
  transition: all 0.2s ease;
  margin-right: 9px;
}

.toggleCircle {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  left: 0;
  width: 14px;
  height: 14px;
  border: 1px solid $blueMist;
  border-radius: 50%;
  background-color: $white;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.checked .check {
  opacity: 1;
}
.checked .uncheck {
  opacity: 0;
}
.checked .toggleCircle {
  left: 11px;
}

.checked {
  .wrapper {
    background-color: $activeBlue;
    border: 1px solid $activeBlue;
  }
  .toggleCircle {
    border: 1px solid $activeBlue;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hint {
  cursor: default;
  .dot {
    margin-right: 2px;
  }
}
