@use "colors-legacy" as *;

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;

  .playerDetailsContainer {
    padding: 15px 20px;
    flex: 1;

    .sectionHeader {
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 12px;
      color: #000000;
    }

    .sectionContainer {
      display: grid;
      grid-template-columns:49% 49%;
      grid-column-gap: 2%;

      .itemTitle {
        height: 70px;
        font-size: 15px;

        div {
          font-weight: 600;
          min-width: 85px;
          color: $text_color_light !important;
          font-size: 14px;
          margin-bottom: 2px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .field {
          &:hover {
            cursor: pointer;
            background: $very_light_grey;
            border-radius: 5px;
            padding-bottom: 2px;
          }
        }

        input {
          height: 26px !important;
        }

        .country {
          > button {
            border-color: $light_grey;
            color: $text_color;
            font-size: 14px;
            margin: 0;
            height: 26px;

            > svg {
              display: none;
            }

            &:hover {
              background: unset;
              border-color: $light_grey;
              color: $text_color;
              font-size: 14px;
            }

            &:active {
              border-color: $medium_blue;
            }
          }

          &.disabled {
            > button {
              background: $color_disabled;
            }
          }
        }

        .btnGroup {
          display: flex;
          justify-content: flex-end;
          margin-top: -15px;

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 20px;
            margin-left: 10px;

            > div {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}



