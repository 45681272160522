@use "ui-kit" as *;

.container {
  width: 100%;
  height: fit-content;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  padding: 0;

  .item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px  31px;
    border-bottom: 1px solid $strokeGray;

    .label {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .voucherName {
        color: $sweetspotBlack;
        font-size: 14px;
      }
    }

    .iconWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 11px;
      .icon {
        display: block;
        width: 20px;
        height: 20px;
      }
    }

    .removeicon {
      cursor: pointer;
    }
  }
}
