@use "colors-legacy" as *;

.clickable {
  cursor: pointer;
  color: $medium_blue;

  &:hover {
    color: darken($medium_blue, 15%);
  }
}
