@use "ui-kit" as *;

.container {
  width: 100%;
  z-index: 99;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;

  max-height: 300px;
  overflow-y: auto;
  border-radius: 4px;
  padding: 0;

  background-color: $white;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);

  transform: scaleY(0);
  transform-origin: top;
  pointer-events: none;

  transition: transform 0.2s;

  &.visible {
    pointer-events: all;
    transform: scaleY(1);
  }

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;

  .row {
    width: 100%;
    padding: 0 16px;
    height: 32px;
    flex: 0 0 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $white;

    transition: background-color 0.2s;

    &:hover {
      background-color: $backgroundGray;
      cursor: pointer;
    }

    .label {
      margin: 0;
      padding: 0;
      font-family: $fontPrimary;
      font-size: 14px;
      color: $black;
    }

    .groupName {
      font-size: 10px;
    }

    .icon {
      font-size: 14px;
      padding-right: 10px;
    }

    &.disabled {
      .label {
        color: $strokeGray;
      }
      &:hover {
        background-color: $white;
        cursor: not-allowed;
      }
    }

    &.notSelectable {
      pointer-events: none;
      &:hover {
        background-color: $white;
        cursor: not-allowed;
      }
    }
  }

  .loaderRow {
    max-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: max-height 0.2s;
    overflow: hidden;

    &:hover {
      background-color: $white;
      cursor: default;
    }
    &.visible {
      max-height: 32px;
    }
  }
}
