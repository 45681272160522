@use "ui-kit-old" as *;

.container {

  .infoText {
    font-size: 12px;
    color: #b2b2b2;
  }

  .addTokenRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .button {
      margin: 0 10px;
    }

    .spinner {
      height: 20px;
      width: 20px;
    }
  }

  .statusRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    width: 365px;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;

    svg {
      margin-right: 10px;
      height: 13px;
      width: 13px;
    }
    
    &.success {
      color: $green;
      background: $veryLightGreen;

      path {
        fill: #59AE8A;
      }
    }
  
    &.error {
      width: 365px;
      padding: 5px 10px;
      border-radius: 4px;
      font-size: 12px;
      color: $red;
      background: rgba(254, 141, 115, 0.2);

      path {
        fill: #FE8D73;
      }
    }
  }
}