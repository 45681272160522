@use "ui-kit-old" as *;

.container {
  height: 100%;
  width: 100%;
  padding: 24px 16px;
  padding-right: 0;
  max-height: 100%;
  overflow: hidden;


  box-sizing: border-box;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;

  &.restrictWidth {
    max-width: 768px;
  }

  .headerRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding-right: 20px;
    margin-bottom: 16px;

    .title {
      @include resetMP;
  
      font-size: 18px;
      font-weight: 600;
      margin-right: 30px;
    }

    .loaderContainerHeader {
      flex: 1 1 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;

      opacity: 0;
  
      transition: opacity .2s;
  
      &.visible {
        opacity: 1;
      }
    }

    .filtersContainer {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 32px;
      background-color: $lightGray;
      border-radius: 3px;
      padding: 0 15px;

      .filterText {
        @include resetMP;
        margin-right: 16px;
        font-size: 14px;
        font-weight: 600;
        color: $darkBlue;
      }

      .checkbox {
        width: auto;
        flex: 0 0 auto;
        margin-bottom: 0;
        
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }

  .loaderContainer {
    flex: 0 0 0px;
    height: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    transition: height .2s;

    &.visible {
      flex: 0 0 40px;
      height: 40px;
    }
  }

  .noExist {
    @include resetMP;

    font-size: 18px;
    font-weight: 600;
    color: $blueMist;
    margin-bottom: 16px;
  }

  .promotionsContainer {
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    padding-right: 20px;

    overflow: auto;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column nowrap;

    .draggableWrapper {
      width: 100%;
      height: auto;
      // max-width: 768px;
      box-sizing: border-box;
      margin-bottom: 8px;
    }
  }
}