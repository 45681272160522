@use "colors-legacy" as *;
@use "sizes-legacy" as *;
@use "ui-kit" as *;

$header_breakpoint: 1400px;
$icon_breakpoint1: 1650px;

.sideMenu {
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 27px;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  max-height: 80vh;

  &.editMode {
    @media screen and (min-width: 1450px) {
      padding-top: 0;
      margin-top: -40px;
    }
  }
}

.bookingModalContainer {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  background-color: $color_white;
}