@use "colors-legacy" as *;
@use "sizes-legacy" as *;

.minContainer {
  float: left;

  .content {
    margin-top: 20px;

    .header {
      opacity: 0;
      font-size: 20px;
      font-weight: $font_weight_semibold;
    }

    .item {
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      min-height: 70px;
      font-size: 14px;
      font-weight: $font_weight_semibold;
      color: transparentize($color-black, .71);
    }
  }
}