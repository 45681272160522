@use "ui-kit-old" as *;

.container {
  padding: 0;
  box-sizing: border-box;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row nowrap;
  align-items: center;
  min-height: 32px;
  * {
    user-select: none;
  }
}
.ccompact {
  flex-direction: column;
  align-items: flex-start;
}

.loaderContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  margin-right: 14px;
  color: $white;
  line-height: 20px;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;

  span {
    font-weight: $normal;
    color: $white;
    margin-right: 3px;
  }

  .priceContainer {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    color: $white;
    line-height: 20px;

    > div {
      width: 100px;
      flex: unset;
      margin: 0 10px;
    }

    svg {
      width: 18px;
      margin-left: 5px;
      cursor: pointer;

      &:first-of-type {
        width: 16px;
      }

      path {
        fill: $white;
      }
    }

    .dynamicPrice {
      position: relative;
      margin: 0;
      width: unset;

      svg {
        margin-top: 3px;
      }

      > span {
        display: block;
        width: 200px;
        position: absolute;
        top: 30px;
        background: $white;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 500;
        padding: 3px 5px;
        color: $darkBlue;
        cursor: pointer;
        box-shadow: $boxShadow;
        z-index: 100;

        svg {
          margin-right: 5px;

          path {
            fill: $darkBlue;
          }
        }
      }
    }
  }

  &.compact {
    min-width: 140px;
  }
}
