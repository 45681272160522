@use "ui-kit-old" as *;

.container {
  width: 100%;
  height: 0px;
  position: relative;
  background-color: $lightGrayThree;
  border-radius: 2px;

  transition: height .2s;

  overflow: hidden;

  .animatedLine {
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, rgba($white, 0) 0%, rgba($white, 1) 50%, rgba($white, 0) 100%);

    animation: line 2s linear infinite;
  }

  &.show {
    height: 4px;
  }
}

@keyframes line {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}