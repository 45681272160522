@use "ui-kit-old" as *;

.container {
  padding: 10px 12px;
  width: 100%;
  box-sizing: border-box;

  border-bottom: 1px solid $lightGraySix;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;


  &:last-child {
    border-bottom: 0;
  }

  .fold {
    overflow: hidden;
    pointer-events: none;
    max-height: 0px;
    padding-top: 0;
    width: 100%;
    padding-left: 12px;

    transition: max-height .2s, padding-top .2s;

    &.open {
      pointer-events: all;
      max-height: 2000px;
      padding-top: 12px;
    }
  }
}