$very_light_green: #f2f2f2;
$light_green: #95b5a7;
$medium_green: #90a199;
$dark_green: #335244;
$light_blue_green: #d7edeb;

$very_light_grey: #f7f7f7;
$light_grey: #e4e4e4;
$semi_medium_grey: #cbcbcb;
$soft_grey: transparentize($light_grey, 0.7);
$medium_grey: #707070;

$very_light_blue: #c5d9e2;
$light_blue: #aecbd7;
$medium_blue: #74b1ee;
$dark_blue: #3f6275;
$deep_dark_grey: #cfddd6;
$dark_grey: #5d5d5d;
$slightly_light_blue: #75b1ee;
$medium_light_blue: #eaf3fd;

$very_light_red: #f6e4dc;
$light_red: #eac2ae;

$light_purple: #e8bcf8;

$medium_orange: #fdd0cd;
$light_orange: #ffb689;

$light_yellow: #ffea9a;

$danger_red: #cb444a;
$button_red: #f69379;

$text_color: #4f5b6d;
$text_color_light: #a6acb5;
$color_disabled: #e9ecef;
$color_green: #37a000;
$color_light_green: #2fc182;
$color_light_black: #0000002e;
$confirm_green: #5fbd86;

$color_black: #000;
$color_white: #fff;
$tee_sheet_green: #a7dec4;
$tee_sheet_dark_blue: #86d1f4;
$tee_sheet_red: #ffa9a3;
$tee_sheet_grey: #f0f0f0;
$tee_sheet_dark_grey: #cbcbcb;
$tee_sheet_light_black: #363636;
$tee_sheet_orange: #fca169;

$filter_light_green: #c3d5cd;
$filter_dark_green: #95b5a7;
$filter_purple: #95b5a7;
$filter_dark_blue: #aed9f7;
$filter_grey: #cbcbcb;
$tee_sheet_blue: #74b1ee;

$price_color_red_dark: #ff5757;
$price_color_red_medium: #ff9090;
$price_color_red_light: #ffc7c7;
$price_color_green_dark: #88dbb8;
$price_color_green_medium: #bdeed9;
$price_color_green_light: #e1f7ee;

$user_card_light_grey: #7e93b4;
$user_card_light_grey_hover: #97acce;

$market_grey: #747474;

$disabled: #9b9b9b;
$dark_brown: #525053;

$dashboard_grey: #d7dbde;

$settings_orange: #ff8d73;

$progress_line_gray: #a6acb5;

$input_field_gray: #d2d5da;

$box_shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
$toast_shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

$darkBlue: #4f5b6d;
$easy_box_shadow: 0px 3px 9px #0000000d;

:export {
  light_red: $light_red;
  light_blue: $light_blue;
  light_purple: $light_purple;
  light_yellow: $light_yellow;
  light_orange: $light_orange;
  light_grey: $light_grey;
  light_blue_green: $light_blue_green;
  tee_sheet_green: $tee_sheet_green;
  tee_sheet_dark_blue: $tee_sheet_dark_blue;
  tee_sheet_tournament: $tee_sheet_orange;
  semi_medium_grey: $semi_medium_grey;
}
