@use "shared-legacy" as *;

.container {
  width: 100%;
  height: 620px;
  flex: 1 0 auto;
  padding: 16px;
  box-sizing: border-box;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  padding-bottom: 0;
}

.tableContainer {
  display: grid;
  grid-template-columns: minmax(15%, auto) minmax(15%, auto) minmax(15%, 1fr) minmax(15%, 1fr) auto;
  grid-auto-rows: 32px;
  column-gap: 10px;
  row-gap: 20px;
  width: 90%;
  align-items: center;

  .gridContent {
    display: contents;

    > div {
      margin-bottom: 0;
    }
  }
}

.headerText {
  font-size: 16px;
  font-weight: 600;
}

.feeButton {
  position: relative;
}

.totalAnnual {
  grid-column-start: 3;
  grid-column-end: 4;
}
.totalMonthly {
  grid-column-start: 4;
  grid-column-end: 5;
}

.totalText {
  font-weight: 600;
}

.trashIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.trashIconDisabled {
  opacity: 0;
  pointer-events: none;
}

.clearIcon {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  &:hover {
    cursor: pointer;
  }
}
.clearIconDisabled {
  opacity: 0;
  pointer-events: none;
}

.dropdownSelectContainer {
  width: 100%;
  margin-right: 0;
  input {
    height: 28px;
  }
  img {
    top: 11px;
  }
}

.dropdown {
  min-width: 200px;
}
