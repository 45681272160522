@use "shared-legacy" as *;

$blue: $medium_blue;
$gray: $progress_line_gray;
$white: $color_white;
$lightGray: $user_card_light_grey;

// Containers
.container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 30px;
  padding-bottom: 22px;
  position: relative;
  user-select: none;
}

.stepContainer {
  flex: 1 1 auto;
  display: flex;
  position: relative;

  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  
  &:last-of-type {
    flex: 0 0 auto;
    justify-content: flex-start;
    align-items: center;
  }
}

.circleAndLabelContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 10;
}

// Line
.stepLine {
  width: 100%;
  height: 2px;
  position: absolute;
  top:  calc(32px / 2) - 1;
  left: calc(32px / 2) - 1;

  background-color: $gray;
  transition: background-color .2s;
}
.stepLineCompleted {
  background-color: $blue;
}
.stepLineLast {
  display: none;
}

// Circles
.stepCircle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  border-width: 2px;
  border-style: solid;
  margin-bottom: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-color: $white;
  background-color: $white;

  transition: border-color .2s, background-color .2s;
}
.stepCircleCompleted {
  border-color: $blue;
  background-color: $blue;
}
.stepCircleCurrent {
  border-color: $lightGray;
  background-color: $lightGray;
}
.stepCircleFuture {
  border-color: $gray;
  background-color: $white;
}
.stepCircleError {
  background-color: $settings_orange;
  border-color: rgba($settings_orange, 1);
}

.pointerOnHover {
  &:hover {
    cursor: pointer;
  }
}

.pointerNotAllowed {
  &:hover {
    cursor: not-allowed;
  }
}

// Numbers
.stepNumber {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  text-align: center;

  color: $gray;
  transition: color .2s;
}
.stepNumberCompleted {
  color: $white;
}
.stepNumberCurrent {
  font-weight: 600;
  color: $white;
}
.stepNumberFuture {
  color: $gray;
}

// Label
.label {
  margin: 0;
  padding: 0;

  font-size: 14px;
  font-weight: 400;
  line-height: 14px;

  color: $gray;
  transition: color .2s;
}
.labelCompleted {
  color: $blue;
}
.labelCurrent {
  color: $lightGray;
}
.labelFuture {
  color: $gray;
}