@use "ui-kit" as *;

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;

  height: 100px;
  overflow: hidden;
  pointer-events: none;
  transform: scaleY(0);
  transform-origin: top;

  background-color: $white;

  transition: transform 0.2s;
  box-sizing: border-box;

  &.visible {
    transform: scaleY(1);
    pointer-events: all;
  }

  .inner {
    padding: 0 30px;
    width: 100%;
    height: 100%;
    background-color: rgba($orange, 0.2);

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .icon {
      margin-right: 12px;
    }

    .text {
      @include resetMarginPadding;
      font-family: $fontPrimary;
      color: $red;
      font-size: 14px;
      font-weight: 500;
    }

    .close {
      margin-left: auto;
      transform: scale(1);

      transition: transform 0.2s;

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }
}
