@use "../abstract/colors-old" as old;

.container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;

  &.noMargin {
    margin-bottom: 0;
  }
}