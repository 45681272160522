@use "shared-legacy" as *;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 75px 200px 0;

  .logo {
    width: 122px;
    height: auto;
    opacity: 0.5;
  }

  .text {
    max-width: 590px;
    margin-bottom: 50px;
    text-align: center;

    div {
      font-weight: $medium;
      color: $text_color_light;
      font-size: 38px;
    }
  }

  .buttonContainer {
    max-width: 300px;
  }
}
