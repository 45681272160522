@use "colors-legacy" as *;

.container {
  width: 100%;
  height: 114px;
  padding: 0 20px 20px;
  box-shadow: 0 3px 10px transparentize($color_black, 0.9);

  h5 {
    min-height: 21px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .headerText > div {
    color: $progress_line_gray;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 12px;
  }

  .actionGroup {
    display: flex;
    justify-content: space-between;

    button {
      margin-top: 0;
    }

    .btnGroup {
      display: flex;
      margin-left: auto;
  
      .cancel {
        > div {
          font-size: 15px;
          font-weight: 500;
          padding-top: 5px;
          margin-right: 20px;    
          color: $settings_orange;
          cursor: pointer;
        }
      }
    }
  }
}