@use "ui-kit-old" as *;

.container {
  width: 100%;
  height: auto;
  background-color: $white;
  border-radius: 3px;
  border: 1px solid $lightGrayThree;
  margin-bottom: 15px;

  .header {
    width: 100%;
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .leftSide {
      padding: 0 24px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .rightSide {
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .removeIconContainer {        
        .removeIcon {
          width: 15px;
          height: 15px;
        }
  
        &:hover {
          cursor: pointer;
          svg path {
            fill: $red;
          }
        }

        &.disabled:hover  {
          cursor: not-allowed;
        }
      }
    }

    .caretContainer {
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .caretDown {
        width: 8px;
        height: auto;
        transform: rotate(-90deg);

        transition: transform .2s;

        * {
          fill: $darkBlue;
        }

        &.isOpen {
          transform: rotate(0deg);
        }
      }
    }

    .headerTitle {
      @include resetMP;
      font-size: 14px;
      font-weight: 600;
      color: $activeBlue;
    }
  }

  .foldContainer {

    max-height: 0;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: top;

    transition: transform .2s;

    &.isOpen {
      overflow: visible;
      max-height: none;
      transform: scaleY(1);
    }
  }

  .nameContainer {
    padding: 10px 24px;
    padding-top: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .textInputField {
      margin-bottom: 16px;
    }
  }

  .innerContainer {
    padding: 0 24px;
    box-sizing: border-box;

    .innerTitle {
      @include resetMP;
      font-size: 12px;
      font-weight: 600;
      color: $activeBlue;
      margin-bottom: 6px;
    }

    .actionSettingsRow {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 10px;

      .textInputField {
        margin-bottom: 0px;
      }
    }

    .rowsContainer {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-flow: column nowrap;
    }

    .addRow {
      margin-top: 16px;
    }
  }
}