@use "ui-kit-old" as *;

.container {
  padding: 10px 12px;
  width: 100%;
  box-sizing: border-box;

  border-bottom: 1px solid $lightGraySix;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: row nowrap;

  @include upToWideDesktop {
    flex-flow: column nowrap;
    justify-content: flex-start;
  }


  &:last-child {
    border-bottom: 0;
  }


  .leftColumn {
    flex: 0 0 48%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column nowrap;

    @include upToWideDesktop {
      width: 100%;
      flex: 0 0 auto;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .rightColumn {
    flex: 0 0 48%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column nowrap;

    @include upToWideDesktop {
      width: 100%;
      flex: 0 0 auto;
    }
  }

  .row {
    height: auto;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    
    margin-bottom: 8px;

    &.firstRow {
      margin-bottom: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.gray {
      p {
        color: $blueMist;
      }
    }

    .innerRow {
      display: flex;
      align-items: center;
      justify-content: center;

      .greenDot {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: $green;
        margin-right: 8px;
      }
    }

    .lastItem {
      text-align: right;
    }
  }
}