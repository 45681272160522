@use "ui-kit-old" as *;

.confirmChangeModal {
  padding: 20px;
  grid-template-rows: unset !important;

  @include upToDesktop {
    padding: 16px;
    border-radius: 8px;

    > div:first-child {
      > div:nth-child(2) {
        padding: 0;
        > div:first-child {
          padding: 0;
          > div {
            display: flex;
            flex-direction: column;
            gap: 16px;
            p {
              margin: 0;
            }
          }
        }
      }
    }
  }

  @include upToTablet {

    > div:first-child {
      > div:nth-child(2) {
        > div:first-child {
          > div {
            > div:last-child {
              gap: 8px;
              flex-direction: column-reverse;
              button {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

  > div:first-child {
    padding: 0 !important;
    background-color: transparent !important;
    color: #000000 !important;
    > div:first-child {
      margin-bottom: 15px;
      font-weight: 600;
    }
    > div:last-child {
      display: none;
    }
    p {
      color: #000000 !important;
    }
    span {
      display: none;
    }
  }

  > div:nth-child(2) {
    padding: 10px 0 0 !important;
    div {
      color: #000000 !important;
    }
  }
}
