@use "ui-kit-old" as *;

.wrapperContainer {
  max-width: 728px;
  max-height: 80%;
  overflow: auto;

  @include upToDesktop {
    max-height: 100vh;
    max-width: initial;
    border-radius: 0;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
  }

  > div > div:nth-child(2) {
    @include upToDesktop {
      padding-top: 28px;
      padding-bottom: 40px;
      margin: 0;
    }
    > div {
      padding-left: 0;
      width: 100%;
      max-width: 100% !important;
    }
  }

  > div > div:nth-child(3) {
    margin: 0;
  }
}
