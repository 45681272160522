@use "ui-kit" as *;

.container {
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $strokeGray;

  .left {
    .inputOptionsSelect {
      background-color: $white;
      min-width: 200px;
    }
  }
}
