@use "colors-legacy" as *;

.container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;


  .content {
    font-size: 22px;
    margin-right: 15px;

    svg {
      width: 32px;
    }

    path {
      color: $tee_sheet_blue;
      cursor: pointer;
    }
    &:hover path {
      color: darken($tee_sheet_blue, 15%);
    }
  }

  .inputContainer {
    position: relative;
    user-select: none;

    input {
      user-select: none;
      cursor: pointer;
      color: transparent;
      text-shadow: 0 0 0 $tee_sheet_blue !important;
    }

    input:hover {
      text-shadow: 0 0 0 darken($tee_sheet_blue, 15%) !important;
    }

    &.fixedWidth {
      width: 234px;
    }

    &.fixedWidthNewDesign {
      width: 180px;
    }

    &.w-full {
      flex: 1;
    }

    &.open {
      input {
        border-bottom-right-radius: 0;
      }
    }

    &.inputForm {
      input {
        font-size: 15px;
        color: $text-color;
        padding: 0.25rem 0.375rem;
        border-radius: 5px;
        text-shadow: none !important;

        &:hover {
          text-shadow: none !important;
        }
      }
    }

  }

  .inputNewDesign {
    display: none;
  }

  .inputIcon {
    color: $tee_sheet_blue;
  }

  .calendarContainer {
    user-select: none;
    position: absolute;
    top: 38px;
    left: 0;
    z-index: 100;

    div {
      border-radius: 8px;
    }
  }
}
