@use "ui-kit-old" as *;

.container {
  width: auto;
  display: grid; 
  grid-template-columns: 1fr 50px;
  grid-template-rows: 1fr;
  grid-auto-rows: 0;
  column-gap: 10px;
  row-gap: 0;
  grid-template-areas: "pills close";
  overflow: hidden;
  margin-top: 13px;
  margin-bottom: 5px;
  margin-right: 30px;

  transition: 0.2s linear;
  
  &.expanded {
    grid-auto-rows: 1fr;
    row-gap: 10px;
  }

  .mainContent {
    display: grid;
    grid-area: pills;
    grid-template-columns: repeat(3, minmax(100px, 33%));
    grid-template-rows: 1fr;
    grid-auto-rows: inherit;
    column-gap: inherit;
    row-gap: inherit;
    overflow: inherit;
  }

  .expandButton {
    display: flex;
    grid-area: close;
    justify-content: center;
    align-items: center;
    background-color: $activeBlue;
    cursor: pointer;
  }
}