@use "shared-legacy" as *;

.modalTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;

  span {
    width: 21vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: white;
    padding-bottom: 3px;
    font-weight: $semi_bold;
  }

  svg {
    height: 30px;
    width: 30px;
    margin-right: 10px;

    path, circle {
      fill: $text_color_light !important;
    }
  }
}

.headerButtons {
  display: flex;
  flex-direction: row;

  button {
    font-size: 14px;
    padding: 0 10px 3px;
  }
}
