@use "ui-kit-old" as *;

.container {
  position: fixed;
  top: 66px;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparentize(black, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 49;

  padding: 10px 0;

  & > * {
    margin: 0 auto;
  }
}

.bottom {
  @include upToDesktop {
    justify-content: flex-end;
    padding: 0px;
  }
}

.fullsize {
  top: 0;
}
