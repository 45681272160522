@use "colors-legacy" as *;
@use "sizes-legacy" as *;

.root {
  width: 100%;
  height: 100%;

  .content {
    position: absolute;
    top: $header_height;
    left: 0;
    bottom: 0;
    width: 100vw;

    z-index: 0;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: row nowrap;

    .page {
      z-index: 0;
      overflow-y: auto;
      display: grid;
      height: 100%;
      width: 90%;
      flex: 1 1 100%;
      background: $very_light_grey;
    }
  }
}