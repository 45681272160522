@use "colors-legacy" as *;

.container {
  width: 60%;
  height: 55%;
  overflow-y: auto;
  background: $very_light_green;
  box-shadow: $box_shadow;
  padding: 20px;
  border-radius: 8px;
  min-width: 1000px;

  .close {
    font-size: 40px;
    text-align: right;
    margin-top: -25px;

    span {
      cursor: pointer;
    }
  }

  .content {
    padding: 0 30px;

    .title {
      margin-bottom: 20px;

      > div {
        color: $tee_sheet_light_black;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .subTitle {
      padding: 10px 0;
    }
  }
}

.legendItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  * {
    font-size: 14px;
    color: $tee_sheet_light_black;
  }

  .rect {
    width: 15px;
    height: 20px;
    margin-right: 10px;
  }

  img {
    margin-right: 10px;
    margin-top: 5px;
    width: 15px;
    height: 15px;
  }
}