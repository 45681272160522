@use "shared-legacy" as *;

.container {
  padding: 20px 0px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
}

.buttonContainer {
  padding: 0px 30px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.validationErrorWrapper {
  width: 100%;
  max-height: 0px;
  opacity: 0;
  overflow: hidden;
  padding: 0px 30px;

  transition: opacity .2s, max-height .2s, padding .2s;
}

.validationErrorWrapperVisible {
  opacity: 1;
  max-height: 150px;
  padding: 10px 30px;
}

.validationErrorContainer {
  width: 100%;
  padding: 10px 20px;
  background-color: $settings_orange;
  border-radius: 5px;
}

.validationErrorText {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $color_white;
  margin: 0;
}