@use "ui-kit-old" as *;

.container {
  padding: 20px 25px 25px 35px;
  min-height: 100%;
  width: 40%;
  min-width: 760px;
  box-sizing: content-box;

  .title {
    font-size: 24px;
    font-weight: 600;
    color: #b2b2b2;
    padding: 0 0 5px 0;
    line-height: 1.5;
    margin-bottom: 30px;
  }

}