@use "ui-kit-old" as *;

.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //padding: 4px 17px 4px 6px;
  padding-bottom: 12px;

  position: relative;

  label {
    color: $blueMist;
  }

  span {
    color: $blueMist;
    font-size: 12px;
  }

  .fromTimeToggle {
    .lightColor {
      margin-bottom: 10px !important;
    }
  }

  .textInputField {
    margin-bottom: 0;

    &.fromTime {
      position: absolute;
      left: 105px;
      top: -5px;
    }

    &.minimum {
      margin-right: 50px;
    }
  }

  .inputField {
    &.fromTime {
      text-align: right;
      padding-right: 0;
    }
    &.minimum {
      text-align: right;
      padding-right: 0;
    }
  }

  .removeIconContainer {
    margin-left: 34px;
    height: 100%;

    position: absolute;
    right: 17px;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    .removeIcon {
      width: 15px;
      height: 15px;
    }
  }

  .rightSide {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.checkerLabel {
  display: block;
  color: $midGray;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: $blueMist;
  margin-bottom: 12px;
}

