@use "ui-kit-old" as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 80px;

  .content {
    font-size: 0;
    padding: 0 20px;

    .loader {
      .dot1 {
        animation: pulseLoaderAnimation 0.75s 0.12s infinite cubic-bezier(0.2,0.68,0.18,1.08);
      }
      .dot2 {
        animation: pulseLoaderAnimation 0.75s 0.24s infinite cubic-bezier(0.2,0.68,0.18,1.08);
      }
      .dot3 {
        animation: pulseLoaderAnimation 0.75s 0.36s infinite cubic-bezier(0.2,0.68,0.18,1.08);
      }
    }
  }
}

.dot {
  background-color: $lightBorder;
  width: 12px;
  height: 12px;
  margin: 2px;
  border-radius: 100%;
  display: inline-block;
  animation-fill-mode: both;
}

@keyframes pulseLoaderAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  45% {
    transform: scale(0.1);
    opacity: .7;
  }
  80% {
    transform: scale(1);
    opacity: 1;
  }
}
