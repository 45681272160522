@use "../abstract/sizes" as *;
@use "../abstract/colors" as *;
@use "../abstract/fontVariables" as *;
@use "../abstract/colors-old" as old;

// Base

div.system-pill {
  height: 2em;
  width: auto;
  border-radius: 1em;
  padding: 0.5em 1em;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  user-select: none;

  font-weight: 400;
  font-size: 0.875rem;
  font-family: inherit;
  outline: none;
  border: none;

  transition: color 0.2s, background-color 0.2s, border-color 0.2s;
}

div.system-pill.primary {
  color: $white;
  background-color: old.$activeBlue;

  &:hover {
    background-color: darken(old.$activeBlue, 5);
  }

  &:active {
    background-color: lighten(old.$activeBlue, 5);
  }

  &.disabled,
  &:disabled {
    background-color: $strokeGray;
    pointer-events: none;

    &:hover {
      cursor: not-allowed;
    }
  }
}

div.system-pill.primary-outline {
  color: $mainBlue;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: rgba($mainBlue, 0.6);

  &:hover {
    color: $blue;
    border-color: rgba($blue, 0.6);
  }

  &:active {
    color: darken($mainBlue, 20);
    border-color: darken($blue, 20);
  }

  &.disabled,
  &:disabled {
    border-color: $strokeGray;
    color: $strokeGray;
    pointer-events: none;
    &:hover {
      cursor: not-allowed;
    }
  }
}
