.numberInput {
  position: relative;

  input {
    position: absolute;
    background-color: transparent;

    &:focus {
      background-color: transparent;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    opacity: 0 !important;

    &:focus {
      opacity: 0 !important;
    }
  }

  .arrows {
    background-color: white;
    position: absolute;
    height: 32px;
    width: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-top: 6px;
    padding-right: 1rem;
    gap: 5px;

    > div {
      cursor: pointer;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;

      &.arrowUp {
        border-bottom: 4px solid #a6acb5;
      }

      &.arrowDown {
        border-top: 4px solid #a6acb5;
      }
    }
  }
}
