@use "ui-kit-old" as *;

.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  width: 100%;
  margin-bottom: 30px;
  flex: 0 0 auto;

  .row {
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 48px;

    &.headerRow {
      min-height: unset;
      align-items: flex-end;
      margin-bottom: 20px;
    }
  }

  .item {
    flex: 0 1 25%;
    margin: 0;
    padding: 0;

    &.days {
      flex: 0 0 30%;
    }
    &.allDay {
      flex: 0 0 25%;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        width: auto;
        flex: 0 0 auto;
      }
    }
    &.from {
      margin-right: 5px;
      > p {
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
    &.to {
      > p {
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
    }

    &.header {
      font-size: 16px;
      font-weight: 600;
    }
  }
}