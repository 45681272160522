@use "colors-legacy" as *;
@use "ui-kit-old" as *;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 250px;
  padding: 0 0 16px;
  overflow: hidden;
  position: relative;

  .topLeftMessage {
    font-family: 'Sofia Pro', sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 1em;
    margin: 0 0 10px 14px;
  }

  .errorTextColor {
    color: $settings_orange !important;
  }

  .infoTextColor {
    color: $text_color_light;
  }

  .linkText {
    cursor: pointer;
    font-style: normal;
    color: $mainBlue;

    &:hover {
      color: $hoverBlue;
    }
  }
}

.dropArea {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 70%);

  /* Border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%2373B0EDFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;

  p {
    font-family: $fontPrimary;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 15px;
    color: #73B0ED;
  }
}

.inactive {
  display: none;
}

.upperArea {
  display: flex;
  flex-direction: row;
  height: 60px;
  padding: 0 16px 5px;
  justify-content: space-between;

  .label {
    color: $text_color_light;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 3px;
  }

  .input {
    margin-bottom: 5px;
    width: 75px;

    input {
      height: 24px;
      font-size: 14px;
    }
  }

  .button {
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
    padding: 3px 9px 3px;

    &.generate {
      width: 150px;
    }
    .loader {
      background-color: $mainBlue;
    }
  }

  .actionsContainer {
    margin: 10px 5px;

    button {
      display: flex;
      justify-content: center;
      font-family: inherit;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      height: 24px;
      padding: 3px 9px 3px;

      svg {
        margin-top: 2px;
      }
    }

    .listItem > div {
      color: $color_black;
    }

    .actionsListClassName {
      width: 210px;
    }
  }

  .generateCodeContainer {
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
    width: auto;

    .inputFieldContainer {
      margin: 0;

      .codesInput {
        display: flex;
        align-items: flex-start;

        input {
          height: 24px;
        }
      }
    }

    button {
      margin: 0 0 0 9px;
    }
  }
}

.codes {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-top: 1px solid $gray_inactive;
  background-color: $gray;

  &.offset {
    padding-bottom: 50px;
  }

  .scrollContainer {
    overflow-y: auto;
    height: calc(100% - 35px);

    .list {
      display: flex;
      flex-flow: column nowrap;
      flex-grow: 1;

      .loader {
        display: flex;
        align-self: center;
        justify-content: center;
        align-items: center;
        height: 35px;

        .dot {
          height: 17px;
          width: 17px;
        }
      }
    }
  }

  .listEmpty {
    width: 100%;
    height: 100%;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 25% 0 35%;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    padding: 0 0 0 16px;
    height: 35px;
    overflow: hidden;

    &.header {
      background-color: $lightGrayThree !important;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        visibility: hidden;
      }
    }

    p {
      color: $grayTextAlt;
      margin: 0;
    }

    .code {
      width: 25%;
    }

    .copyIconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 5%;
      height: 100%;

      &.copyIcon {
        cursor: pointer;

        .icon {
          visibility: hidden;
        }
      }
    }

    &:hover .copyIcon .icon {
      visibility: visible;
    }

    .codeHolder {
      display: flex;
      cursor: pointer;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      line-height: 1;
      width: 45%;
      height: 32px;
      padding-left: 15%;
      padding-right: 15%;

      > div {
        margin: 0;
        > div > input {
          border: 1px solid $tee_sheet_blue;
        }
      }

      &.header {
        cursor: default;
      }

      &.left {
        justify-content: flex-start;
      }

      &.expired {
        visibility: hidden;
      }

      &.assigned {
        cursor: default;
      }

      .assignLoader {
        > div {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
      }

      .playerPopup {
        position: fixed;
        z-index: 100;
        top: 45%;
        right: 40%;

        .selectPlayer {
          transform: translateY(40px);
        }
      }
    }

    .assigneeName {
      display: flex;
      cursor: pointer;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      line-height: 1;
      height: 32px;
      width: 15%;
    }

    .icon {
      width: 13px;
      height: 13px;

      &.removeHeader {
        width: 15%;
      }

      &.remove {
        cursor: pointer;
        width: 15%;
      }
    }

    &:nth-of-type(odd) {
      background: $very_light_grey;
    }

    .removeLoader {
      overflow: hidden;
      width: 15%;
      > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
  }
}
