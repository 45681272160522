@use "colors-legacy" as *;
@use "sizes-legacy" as *;

.container {
  background: white;
  min-height: 122px;
  width: 114px;
  border-radius: 8px;
  margin-right: 2px;
  margin-bottom: 2px;
  border: 2px solid transparent;

  &.disabled {
    opacity: 0.6;

    .content {
      cursor: initial;

      &:hover {
        filter: brightness(100%);
      }
    }
  }

  &:hover {
    position: relative;
    z-index: 1;
  }

  &.editMode {
    border: 3px solid transparent;
  }

  &.selected {
    box-shadow: $box_shadow;
    border: 2px solid $user_card_light_grey;
    border-radius: 11px;

    &.editMode {
      border-width: 3px;
    }
  }

  .content {
    height: 100%;
    border-radius: 8px;
    display: grid;
    cursor: pointer;

    -webkit-transition: filter 0.3s ease-in-out;
    transition: filter 0.3s ease-in-out;

    &:hover {
      filter: brightness(93%);
    }

    &.filteredOut {
      filter: brightness(70%);
    }

    .headerContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4px 8px;
      border-radius: 8px 8px 0 0;

      .time {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 2px;
        min-height: 1rem;
        position: relative;
      }

      .primeTime {
        position: absolute;
        left: 22px;
        top: 2px;

      }

      .category {
        font-size: 12px;
        line-height: 15px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 80px;
        min-height: 15px;
        padding-left: 2px;
      }
    }

    .mainContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      background: $tee_sheet_grey;
      min-height: 4rem;

      .icons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 16px;
        margin: 4px 0;
        max-width: 112px;

        .image {
          width: 16px;
          height: 16px;
          margin-left: 4px;
        }

        .golfCarts {
          display: flex;
          background: $color_white;
          border-radius: 4px;
          align-items: center;
          justify-content: center;
          padding: 2px 6px 3px;
          height: 16px;

          svg {
            width: 11px;
            height: 11px;
            margin-top: 2px;

            path {
              fill: $text_color;
            }
          }

          span {
            font-size: 15px;
            color: $text_color;
            margin: 0 0 0 5px;
            line-height: 100%;
          }
        }
      }
    }

    .footerContainer {
      padding: 2px;
      border-radius: 0 0 8px 8px;
      background: $tee_sheet_grey;
      min-height: 1rem;

      .price {
        color: $text_color;
        text-align: center;
        font-size: 14px;
        line-height: 13px;

        .currency {
          font-size: 10px;
        }
      }
    }
  }
}

.empty {
  .content {
    height: 111px;
    border-right: 2px solid $light_grey;
    border-bottom: 1px solid $light_grey;
    background: darken($very_light_grey, 2);

    &.editMode {
      border-right: 4px solid white;
      border-bottom: 2px solid white;
    }

    &.hide {
      display: none;
    }

    .timeContainer {
      height: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      @media screen and (min-height: 1000px) {
        height: 35px;
      }

      .time {
        position: relative;
        text-align: center;
        font-size: 14px;
        font-weight: $semi_bold;
        color: $text_color;
      }
    }
  }
}