@use "shared-legacy" as *;


.container {
  width: 100%;
  padding: 25px 30px;
  padding-bottom: 5px;
  flex: 1 0 auto;
}

.periodContainer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 20px;

  >p {
    margin: 0;
    display: flex;
    justify-content: center;
    width: 15px;
    padding-bottom: 25px;
  }
}