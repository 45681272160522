@use "ui-kit-old" as *;

.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;

  .noneSelected {
    @include resetMP;
    font-size: 18px;
    font-weight: 600;
    color: $blueMist;
    margin-bottom: 16px;
  }

  .selectedItemsRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row wrap;

    margin-bottom: 8px;

    .pillContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row nowrap;

      margin-right: 8px;
      margin-bottom: 8px;
      padding: 0 8px;
      
      background-color: rgba($activeBlue, 1);
      height: 20px;
      border-radius: 10px;

      box-sizing: border-box;

      &:hover {
        cursor: pointer;
        background-color: rgba($activeBlueLight, 1);
      }

      &.disabled {
        background-color: rgba($activeBlue, .5);

        &:hover {
          cursor: not-allowed;
        }
      }

      .pillText {
        @include resetMP;
        font-size: 14px;
        color: $white;
        margin-right: 8px;
        margin-top: -2px;
        line-height: 17px;
      }

      .pillCross {
        height: 8px;
        width: 8px;
      }
    }
  }

  .dropdownContainerRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    position: relative;

    .dropdown {
      top: 0;
      left: 0;
      bottom: unset;
      transform: none;
    }

    .dropdownInnerContainer {
      width: auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: column nowrap;

      box-sizing: border-box;

      min-width: 200px;

      .itemRow {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-width: 100%;
        width: auto;
        padding: 3px 5px;

        cursor: pointer;

        &.firstRow {
          border-bottom: 1px solid $lightGrayThree;
        }

        .checkboxContainer {
          width: auto;
          flex: 0 0 auto;
          margin-bottom: 0;
        }

        .itemLabel {
          @include resetMP;
          font-size: 14px;
          font-weight: 400;
          color: $darkBlue;
        }
      }
    }
  }
}