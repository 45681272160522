@use "colors-legacy" as *;

.container {
  width: 100%;
  float: left;
  padding: 0 0 10px 0;
  margin-top: 5px;
  z-index: 0;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

  &.last {
    .container {
      .empty {
        border: none;
      }
    }
  }

  .content {
    height: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: visible;
    display: grid;
    grid-template-rows: 18px 1fr;
    grid-gap: 3px;

    .header {
      text-align: center;
      color: $text_color;
      font-size: 18px;
      font-weight: 500;
      line-height: 16px;
      padding-right: 4px;
    }
  }
}
