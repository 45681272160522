@use "colors-legacy" as *;
@use "ui-kit-old" as *;
@use "ui-kit" as newKit;

.container {
  z-index: 1;
  width: 100%;
  padding: 10px 15px;
  box-shadow: 0 3px 10px transparentize($color_black, 0.9);

  h5 {
    color: #8A8A8A;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px
  }

  p {
    color: newKit.$sweetspotBlack;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

.headerTitleContainer {
  display: flex;
  flex-direction: column;

  .headerTitle {
    color: $black;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
  }

  .headerSubTitle {
    color: var(--stone-900);
    font-size: 14px;
    font-weight: 400;
    line-height: 26px
  }
}
