@use "ui-kit" as *;

.selectableTeeTime {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  transition: opacity 0.2s, border-top-color 0.2s, border-bottom-color 0.2s;

  .plusSignContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s;

    &.visible {
      opacity: 1;
    }
  }

  &:hover:not(.disabled) {
    cursor: pointer;
  }

  &.borderTop {
    border-top: 1px solid $strokeGray;
  }

  &.suggested {
    opacity: 1;
    .plusSignContainer {
      opacity: 1;
    }
  }

  &.selected {
    opacity: 1;
  }

  &.topSelected {
    .plusSignContainer {
      opacity: 1;
    }
  }

  &.previousSelected {
    border-top-color: rgba($black, 0) !important;
  }

  &.nextSelected {
    border-bottom-color: rgba($black, 0) !important;
  }

  &.isInBooking {
    pointer-events: none;
  }
}
