@use "shared-legacy" as *;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  user-select: none;

  .logo {
    width: 122px;
    height: auto;
  }

  .text {
    max-width: 590px;
    text-align: center;
    font-size: 34px;
    color: $medium_blue;
    margin: 15px 0 50px 0;
  }
}