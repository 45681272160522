@use "ui-kit-old" as *;

.container {
  overflow: hidden;
  border: 1px solid lighten($silverBlue, 10);
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  border-radius: 8px;

  &.moving {
    &:hover {
      .headerRow {
        background-color: $blueMist;
      }
    }
  }

  &:hover {
    .headerRow {
      background-color: $activeBlue;
      cursor: pointer;
    }
  }

  .headerRow {
    padding: 0 11px;
    width: 100%;
    min-height: 30px;
    background-color: $blueMist;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: background-color 0.2s;

    p {
      margin: 0;
      padding: 0;
    }

    .bookingLetter {
      margin: 0;
      padding: 0;
      color: $white;
      font-size: 27px;
      font-weight: 700;
      margin-right: 20px;
    }

    .bookingPrice {
      font-size: 16px;
      margin-left: 10px;
      font-weight: 300;
    }

    .bookingCost {
      font-size: 16px;
      font-weight: 500;
      color: $white;
      display: flex;
      align-items: baseline;

      span {
        color: $white;
      }

      .dollarSign {
        margin-left: 5px;
        font-size: 17px;
        font-weight: 700;
      }

      .dot {
        height: 13px;
        width: 13px;
        display: inline-block;
        border-radius: 50%;
        border: 2px solid $white;
        margin-left: 10px;
      }

      .awaitingPaymentDot {
        background-color: $yellow;
      }

      .confirmed {
        background-color: $blue;
      }

      .unconfirmed {
        background-color: $blueMist;
      }
    }

    .moveContainer {
      height: 24px;
      margin-left: auto;
      margin-right: 15px;
      padding: 0 10px;
      justify-self: flex-end;
      border-radius: 5px;
      width: auto;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;

      transition: background-color 0.2s;

      &.active {
        background-color: $activeBlue;
      }

      &:hover {
        background-color: $blue;
        cursor: pointer;
      }

      .icon {
        margin-right: 7px;
        align-self: center;
      }

      .text {
        color: $white;
      }
    }

    .arIconWrapper {
      justify-self: flex-end;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0px;

      .arIcon {
        height: 22px;
        width: 22px;
        padding: 3px;
        border-radius: 4px;
        transform: scale(1);
        transition: transform 0.2s;

        path {
          fill: $white;
          font-size: 12px;
        }

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  .slotRow {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid $lightGrayThree;
    background-color: $lightGray;

    .slotInfo {
      padding: 0 14px;
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;

      p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-weight: 500;
      }

      .defaultText {
        font-size: 15px;
        font-weight: 500;
      }

      .name {
        flex: 0 0 32%;
        overflow: hidden;
        margin-right: 10px;
        margin-left: 6px;
      }

      .hcp {
        flex: 0 0 10%;
      }

      .golfId {
        flex: 0 0 25%;
      }

      .typeWrapper {
        flex: 0 0 23%;
        display: flex;
        flex-direction: row;

        .typeIcon {
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }
        }

        .golfCartsTooltip {
          cursor: pointer;
          margin-left: 12px;

          .golfCarts {
            display: flex;
            border-radius: 4px;
            align-items: center;
            justify-content: center;

            height: 20px;
            margin-right: 15px;

            svg {
              width: 14px;
              height: 14px;
              margin-top: 2px;

              path {
                fill: $black;
              }
            }

            span {
              font-size: 14px;
              margin-left: 6px;
              color: $black;
              line-height: 100%;
            }
          }

          .copyTooltip {
            background-color: $hover_black;
            padding: 2px 8px !important;
            z-index: 100;

            >div {
              color: $white;
            }

            &:after {
              left: -5px !important;
              margin-top: -6px;
            }
          }
        }
      }

      .arWrapper {
        margin-left: auto;
        flex: 0 0 auto;

        .inputWrapperClassName {
          margin: 0;

          div {
            margin-right: 0;
          }
        }
      }
    }

    .playerInfoToggle {
      width: 16px;

      &:hover {
        cursor: pointer;
      }

      .icon {
        width: 11px;
        height: auto;
        transform: rotate(-90deg);
        transition: transform 0.2s;

        &.open {
          transform: rotate(-180deg);
        }

        path {
          fill: $darkBlue;
        }
      }
    }

    .playerInfo {
      padding: 0px 15px 15px;
      flex: 1 1 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: #f8f8f8;
      align-content: center;

      .text {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 600;

        &:last-child {
          margin-bottom: 0;
        }

        .value {
          font-weight: 400;
        }
      }
    }
  }
}