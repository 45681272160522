@use "ui-kit-old" as *;

.container {
  position: relative;

  .prepend {
    position: absolute;
    margin-left: 10px;
    svg {
      left: 0;
      top: 10px;
    }
  }

  input {
    width: 100%;
    background: transparent !important;
    padding: 0 35px 2px 10px;
    font-size: 14px;
    overflow: hidden;
    height: 30px;
    text-overflow: ellipsis;
    font-weight: 400;
    line-height: 16px;
    border: 1px solid $silverBlue;
    box-sizing: border-box;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    &:hover {
      cursor: pointer;
    }

    &::placeholder {
      font-weight: 300;
      color: $midGray;
    }

    &.sm {
      height: 25px;
    }

    &.offset {
      margin-left: 20px;
    }
  }

  .bold {
    font-weight: 600;
  }

  &.open {
    input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid $silverBlue;
    }
  }

  &.disabled {
    cursor: not-allowed;
    border: 1px solid rgba($darkBlue, 0.8);
    color: rgba($darkBlue, 0.8);
  }

  &.hideArrow {
    svg {
      display: none;
    }
  }

  &.showBorder {
    border: 1px solid $silverBlue;
    border-radius: 4px;

    &.open {
      border-color: $blue;
    }

    input {
      border: none;
    }
  }

  svg {
    position: absolute;
    top: 12px;
    right: 14px;
    transform: rotate(90deg);
    cursor: pointer;

    &.sm {
      top: 10px;
      right: 12px;
    }
  }

  .listContainer {
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    right: 0;
    max-height: 225px;
    border: 1px solid $lightGrayEight;
    border-top: none;
    background: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: $boxShadow2;

    &.anchorTop {
      top: unset;
      bottom: calc(100% + 1px);
    }

    .list {
      height: 100%;
      overflow: scroll;
      overflow-x: hidden;
      overflow-y: auto;

      .listItem {
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        cursor: pointer;

        .name {
          flex: 1;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .desc {
          text-align: left;
          width: 70px;

          > div {
            font-weight: 600;
            padding-top: 2px;
          }

          &.active > div {
            color: $lightGreen2;
          }
        }

        &:hover {
          background: $lightBlue;
        }

        &.focused {
          background: $lightBlue;
        }
      }
    }
  }
  &.largeText {
    .listItem {
      height: 40px !important;
      font-size: 22px !important;
      font-weight: bold;
      text-align: center;
      color: $blue;
    }
  }

  .status {
    width: calc(100% - 30px);
    position: absolute;
    top: 0;
    left: 10px;
  }
}
