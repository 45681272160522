@use "colors-legacy" as *;

.container {
  font-size: 15px;

  img {
    height: 80px;
    max-width: 200px;
    object-fit: cover;
  }

  .error {
    margin-bottom: 10px;
    color: $danger_red;
  }

  > div {
    word-break: break-all;
  }

  .btnGroup {
    margin-top: 10px;

    button {
      display: inline-block;
      width: unset;
      font-size: 15px;
      margin: 0 10px 0 0;
      padding: 0 15px;
    }
  }
}
