@use "ui-kit" as *;

.container {
  width: 100%;

  display: flex;
  flex-direction: column;

  margin-top: 16px;

  path {
    color: unset;
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin-bottom: 8px;

    .spaceTitle {
      color: $black;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    .removeIconContainer {
      padding: 4px;
      width: 32px;
      height: 32px;
      background-color: #E5E5E5;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.removeDisabled {
        background-color: #F5F5F5;
        cursor: not-allowed;
        svg {
          cursor: not-allowed;
          path {
            fill: #D1D5DB;
          }
        }
      }

      .removeIcon {
        cursor: pointer;
        width: 14px;
        path {
          fill: #171717;
        }
      }
    }
  }
}
