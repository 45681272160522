@use "shared-legacy" as *;

.container {
  width: 100%;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.infoTextContainer {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1 0 50px;
}

.infoText {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}