@use "colors-legacy" as *;

.container {
  display: grid;
  grid-template-columns: 18px auto;
  align-items: center;
  margin-top: 2px;

  .status {
    width: 13px;
    height: 15px;
    border-radius: 2px;
    margin-top: 1px;
  }

  .text {
    color: $text_color;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
