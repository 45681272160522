@use "ui-kit-old" as *;

.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  width: 100%;

  input {
    @include resetMP;
    display: flex;
    min-height: 24px;
    height: 24px;
    font-size: 14px;
    border-radius: 0;
    flex: 1;
    border: 0.5px solid $lightGrayThree;
    margin-bottom: 3px;
  }
}

.header {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 10px;
  cursor: default;
  overflow: hidden;
}

.title {
  @include resetMP;
  align-self: center;
  cursor: default;
}

.label {
  @include resetMP;
  font-size: 14px;
  color: $midGray;
  display: flex;
  cursor: default;

  &.fastCopy {
    margin-left: 5px;
    &:before {
      content: "\00B7";
      margin-right: 5px;
    }

    &:hover {
      color: $hoverBlue;
      cursor: pointer;
    }
  }
}

.updateArea {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .labelArea {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 0.2;
    margin-top: -4px;
    margin-right: 5px;
  }

  .inputArea {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 0.7;
    padding-right: 15px;
  }

  .saveArea {
    display: flex;
    flex: 0.1;
    margin-top: -3px;
    align-items: center;

    button {
      height: 50px !important;
    }
    .saveBtn {
      align-self: center;
      height: 100%;
    }
  }
}
