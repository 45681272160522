.container {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 75px 1fr;
  padding: 20px;
  padding-left: 0;

  .sideText {
    height: 90%;
    padding-top: 100px;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    div {
      transform: rotate(-90deg);
      font-weight: bold;
      font-size: 22px;
    }
  }
  
  .searchInput {
    display: flex;
    margin-bottom: 15px;
  }

  .mainContent {
    grid-column: 2;
    grid-row: 2;
    height: 100%;
    display: grid;
    grid-template-rows: auto 250px 4fr;
    grid-gap: 15px;

    .tables {
      height: 100%;
      display: grid;
      grid-gap: 10px;
    }
    .usersTable {
      padding-right: 10px;
      overflow: auto;
    }
  }

  input {
     max-width: 600px;
  }
}