@use "ui-kit" as *;

.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  padding: 12px 0 !important;
  border-radius: 0;
  height: 100%;

  .header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column nowrap;
    padding: 6px 31px 12px;

    &:not(:last-child) {
      border-bottom: 1px solid $strokeGray;
    }

    .title {
      font-size: 20px;
      color: $sweetspotBlack;
      margin: 12px 0;
      padding: 0;
      font-weight: 600;
    }

    .descriptionRow {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: row wrap;

      .text {
        margin: 0 23px 0 0 ;
        padding: 0;
        font-size: 14px;
        color: $sweetspotBlack;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > span {
          margin-right: 7px;
        }
      }
    }

    .bookingId{
      font-size: 14px;
      font-weight: 400;
      color: $sweetspotBlack;
      span {
        color: $sweetspotBlack;
      }
    }

    .calendarIcon {
      width: 13px;
      height: 13px;
      margin-right: 7px;

      path {
        color: $sweetspotBlack;
      }
    }
  }
}
