@use "colors-legacy" as *;
@use "sizes-legacy" as *;

$width_breakpoint: 1400px;

.container {
  height: 100%;
  position: relative;

  .subContainer {
    display: grid;
  }

  .content {
    & > div:first-of-type {
      padding-right: 20px;
      border-right: 1px solid $light_grey;
      min-width: 380px;
    }

    & > div:last-of-type {
      padding: 0 20px;
    }

    @media screen and (min-width: $width_breakpoint) {
      padding: 10px 0;
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: 1fr;
      height: 100%;
    }
  }
}
