@use "ui-kit-old" as *;

.container {
  width: 100%;

  .noUnitsSelectedText {
    @include resetMP;
    margin-top: 0;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    color: $midGray;
  }

  .unitRow {
    width: 100%;
  }
}