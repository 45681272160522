@use "ui-kit" as *;

.container {
  padding: 17px 24px;
  border: 1px solid $strokeGray;
  background-color: $white;
  border-radius: 0px 0px 4px 4px;

  &.dark {
    background-color: $backgroundGray;
    box-shadow: 0px 2px 7px rgba($dark, .05);
  }
}