@use "ui-kit" as *;

.container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  overflow: auto;
  padding: 12px 31px;
  border-bottom: 1px solid $strokeGray;
  position: relative;

  > div:not(:last-child) {
    margin-bottom: 20px;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    color: $black;
    margin: 0;
    padding: 0;
  }

  hr {
    width: 100%;
    background-color: $strokeGray;
    height: 1px;
    box-shadow: none;
    outline: none;
    border-top: 0;
    position: absolute;
    left: 0;
    right: 0;
  }

  .inner {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column nowrap;
  }

  .spaceRow,
  .discountTotalRow,
  .totalToPayRow {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    p {
      @include resetMP;
    }

    .text {
      font-size: 14px;
      color: $dark;

      &.black {
        color: $black;
      }

      &.size16 {
        font-size: 16px;
      }

      &.strike {
        text-decoration: line-through;
      }

      &.fw600 {
        font-weight: 600;
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      .removeAdjustment {
        margin-right: 8.5px;
        cursor: pointer;

        transform: scale(1);
        transition: transform 0.2s;

        &:hover {
          transform: scale(1.1);
        }
      }

      .editPen {
        margin-right: 8.5px;
        cursor: pointer;
        transform: scale(1);
        transition: transform 0.2s;
        user-select: none;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .dropdown {
      position: absolute;
      top: calc(100% + 12px);
      right: 0;
      transform: scale(0);
      transform-origin: top right;
      opacity: 0;
      transition: transform 0.2s, opacity 0.2s;

      background-color: $white;
      box-shadow: 0px 4px 24px rgba($black, 0.1);
      border-radius: 4px;
      width: 292px;
      height: auto;
      padding: 14px 21px;
      z-index: 9;

      &.open {
        transform: scale(1);
        opacity: 1;
      }

      .buttonsRow {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > button:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
