@use "ui-kit" as *;

.ss-checkbox {
  &.md-16 {
    width: 16px;
    height: 16px;
  }

  border: 1px solid $dark;
  background-color: $white;
  border-radius: 4px;

  transition: background-color 0.2s;

  &:checked {
    background-color: $dark;
  }

  &:hover {
    cursor: pointer;
  }

  &.webapp-style {
    appearance: none;
    -webkit-appearance: none;
    width: 1.5em;
    min-width: 1.5em;
    height: 1.5em;
    min-height: 1.5em;
    border: 1px solid $sweetspotBlack;
    border-radius: 0.25em;
    background-color: transparent;
    margin: 1px;
    cursor: pointer;

    &:checked {
      color: $white;
      background-color: rgba($sweetspotBlack, 90%);
    }

    &:checked:after {
      content: "\2713";
      font-size: 1.125em;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      position: relative;
      transform: translateY(-0.125em);
    }
  }

  & + label {
    font-size: 0.875em;
    display: flex;
    column-gap: 0.5ch;

    > p {
      @include resetMarginPadding;
      a {
        text-decoration: underline;
        color: $sweetspotBlack;
      }
    }
  }
}
