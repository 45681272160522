@use "ui-kit-old" as *;

.container {
  min-height: 40px;
  border-radius: 5px;
  box-shadow: 0px 2px 3px rgba($black, 0.17);
  border: 1px solid $gray;
  border-left: 6px solid $blueMist;
  position: relative;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  box-sizing: border-box;

  transition: border-left-color 0.2s;

  &.compact {
    min-height: 0;
    margin-bottom: 10px;
  }

  &:hover {
    border-left-color: $darkBlue;
  }

  .titleRow {
    width: 100%;
    height: 40px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    padding: 0 18px;
    box-sizing: border-box;

    .toggle {
      width: auto;
      margin-bottom: 0;
      margin-left: 25px;
      margin-top: 1px;
      label {
        color: $midGray;
      }
    }

    &.compact {
      height: 32px;
    }

    .title {
      @include resetMP;
      font-size: 14px;
      font-weight: 600;
      color: $darkBlue;

      &.title:hover {
        cursor: default;
      }
    }

    .removeWrapper {
      margin-left: auto;
      display: flex;
      align-items: center;

      &.compact {
        margin-left: 15px;
        padding-right: 10px;
      }
      .removeIcon {
        width: 10px;
        height: 10px;
        transform: scale(1);
        transition: transform 0.2s;

        * {
          fill: $lightGrayFive;
          transition: fill 0.2s;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.miniWrapper {
    min-height: 32px;
  }

  .content {
    width: 100%;
  }

  .inner {
    width: 100%;
    color: black;
    padding: 12px 18px;
    padding-top: 0;
  }

  .disabled {
    position: absolute;
    top: 0;
    width: calc(100% - 30px);
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.containerReadOnly {
  @extend .container;
  &:hover {
    border-left-color: $blueMist;
  }
}
