@use "ui-kit-old" as *;

.container {
  width: auto;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  padding: 0;
  box-sizing: border-box;

  .itemWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    user-select: none;

    .icon {
      margin-right: 7px;
    }
    .addIcon {
      width: 13px;
      height: 13px;
    }
    .copyIcon {
      width: 16px;
      height: 16px;
    }
  }

  &.light {
    .label {
      color: $white;
      transition: color .2s;
    }

    .addIcon {
      g, line {
        stroke: $white;
        transition: stroke .2s;
      }
    }
  }
}