@use "colors-legacy" as *;

.container {
  width: 100%;
  padding: 20px;

  .field {
    margin-bottom: 15px;
    
    > div {
      color: $text_color_light;
      font-size: 14px;
    }

    span {
      color: $text_color;
      font-size: 15px;
    }
  }
}