.dueIconContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dueDateContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    padding-left: 5px;
    align-items: center;
    font-size: 15px;
}

.invalidPayoutState {
    cursor: pointer;
    stroke: #FE9178;
    stroke-width: 15px;
}

.tooltipTextContainer {
    display: flex;
    line-height: normal;
}