.container {
  width: 15px;
  overflow: auto;

  .overflowContent {
    height: 500%;
    width: inherit;
  }

  &.horizontal {
    width: 100%;
    height: 15px;

    .overflowContent {
      height: 1px;
      width: 500%;
    }
  }
}