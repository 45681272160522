@use "ui-kit-old" as *;

.spinner {
  opacity: 0;
  stroke: $white;
  transition: opacity .2s, stroke .2s;
}

.visible {
  opacity: 1;
}

.white {
  stroke: $white;
}

.blue {
  stroke: $blue;
}

.dark {
  stroke: rgba($darkBlue, .5);
}

.light {
  stroke: rgba($darkBlue, .3);
}