@use "ui-kit-old" as *;

.container {
  width: 100%;

  .allSlotsText {
    @include resetMP;
    font-size: 14px;
    font-weight: 600;
    color: $blueMist;
    margin-bottom: 12px;
  }

  .newDropdownContainer {
    position: relative;

    .newDropdown {
      min-width: 200px;
    }
  }

  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .input {
      width: 75px;
      margin-right: 10px;
      flex: 0 0 75px;
    }

    .checkbox {
      margin: 0;
    }

    .emptyQueryText {
      @include resetMP;
      font-size: 14px;
      font-weight: 600;
      color: $blueMist;
    }
  }

  .cooperationsList {
    flex-direction: column;
  }
  .isLoading {
    padding: 0;
  }
}
