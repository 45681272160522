@use "ui-kit-old" as *;

.container {}

.hoverBox {
  pointer-events: none;
  opacity: 0;

  transition: opacity .2s;

  border-radius: 5px;
  background-color: $darkBlue;
  padding: 12px;

  box-shadow: 0px 1px 6px rgba($black, .16);

  min-height: 40px;

  max-width: 250px;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .triangleRight {
    position: absolute;
    right: -14px;
    top: 50%;
    transform: translateY(-50%);
    width: 21px;
    height: 18px;

    &.pointLeft {
      right: unset;
      left: -14px;
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .hoverText {
    @include resetMP;
    color: $white;
    font-weight: 400;
    font-size: 15px;
  }
}