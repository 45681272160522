@use "ui-kit" as *;

.container {
  height: calc(100% - 60px);
  margin: 20px -20px 0px -20px;
  font-size: 15px;
  padding: 0px 20px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;

  .searchContainer {
    display: flex;
  }

  .gridContainer {
    margin-top: 12px;
    display: grid;
    align-content: start;
    overflow-y: auto;
    margin: 12px -20px 0px -20px;
    padding-bottom: 20px;
    grid-template-columns:
      minmax(60px, auto) minmax(60px, auto) minmax(60px, auto) minmax(60px, auto) minmax(60px, auto) minmax(60px, 40%) minmax(60px, auto);

    [role="columnheader"] {
      font-weight: 600;
      position: sticky;
      top: 0;
      display: flex;
      align-items: center;
      background-color: $backgroundGrayTwo;
      padding-left: 20px;
      width: 100%;
      height: 50px;

      &.sortable {
        cursor: pointer;
        user-select: none;

        svg {
          padding-left: 1ch;
        }
      }
    }
  }

  .noActivity {
    display: flex;
    align-self: center;
    justify-self: center;
    place-items: center;
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 7;
    padding-top: 50px;

    div {
      font-size: 30px;
      font-weight: 500;
      color: $iconGray;
    }
  }
}