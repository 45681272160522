@use "ui-kit-old" as *;

.container {
  width: 100%;
  height: auto;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "inner";

  grid-column-gap: 0px;
  grid-row-gap: 0px;


  // border-top-left-radius: 7px;
  // border-top-right-radius: 7px;
  overflow: hidden;


  .header {
    grid-area: header;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    background-color: $gray;

    border: 1px solid $lightBorder;

    .title {
      margin: 0;
      padding: 0;
      color: $darkBlue;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .inner {
    grid-area: inner;
    width: 100%;
    height: 100%;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: lighten($silverBlue, 10);
    overflow-y: auto;
    border-top: 0;
  }
}
