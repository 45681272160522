@use "colors-legacy" as *;

.container {
  border: 1px solid $light_grey;
  border-radius: 4px;
  padding: 0;

  .title {
    font-size: 20px;

    div {
      font-weight: 600;
    }
  }
}