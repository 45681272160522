@use "colors-legacy" as *;

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;

  .playerDataContainer {
    padding: 15px 20px;
    flex: 0.55;

    .sectionContainer {
      display: grid;
      grid-template-columns: 49% 49%;
      grid-column-gap: 2%;
    }

    .sectionHeader {
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 12px;
      color: #000000;
    }

    .infoItem {
      height: 70px;
      font-size: 15px;

      label > div {
        font-weight: 600;
        min-width: 85px;
        color: $text_color_light;
        font-size: 14px;
        margin-bottom: 2px;
      }

      .field {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          cursor: pointer;
          background: $very_light_grey;
          border-radius: 5px;
          padding-bottom: 2px;
        }
      }

      .disabledField {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      input {
        height: 26px !important;
      }

      .country {
        > button {
          border-color: $light_grey;
          color: $text_color;
          font-size: 14px;
          margin: 0;
          height: 26px;

          > svg {
            display: none;
          }

          &:hover {
            background: unset;
            border-color: $light_grey;
            color: $text_color;
            font-size: 14px;
          }

          &:active {
            border-color: $medium_blue;
          }
        }

        &.disabled {
          > button {
            background: $color_disabled;
          }
        }
      }

      .btnGroup {
        display: flex;
        justify-content: flex-end;
        margin-top: -15px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 20px;
          margin-left: 10px;

          > div {
            font-size: 12px;
          }
        }
      }
    }

    .bDay {
      > div > div > div:last-of-type {
        top: -270px;
      }
    }
  }

  .notesContainer {
    background-color: #FAFAFA;
    flex: 0.45;
    padding: 30px 22px 30px 30px;

    textarea {
      border-radius: 8px;
    }

    .infoText {
      font-weight: 400;
      color: $text_color_light;
      font-size: 12px;
    }

    .noteText {
      font-size: 14px;
      font-weight: 400;
      color: #4F5B6D;
    }

    .notesListContainer {
      overflow-y: auto;

      .noteTitleContainer {
        display: flex;
        justify-content: space-between;

        .noteActionsContainer {
          display: flex;
          justify-content: flex-end;
          gap: 10px;

          button {
            color: $medium_blue !important;
            border: none;
            cursor: pointer;
            font-size: 12px;
            font-weight: 400;
            padding: 0;
            margin: 0;
            width: 55px;
            max-width: fit-content;
            &:hover {
             opacity: .7;
            }
          }
        }
      }
    }
  }
}
