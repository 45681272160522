@use "colors-legacy" as *;

.container {
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &.open {
    border: 1px solid $light_grey;
    background: lighten($light_grey, 20);
  }

  &:hover {
    .listContainer {
      .option {
        color: $text_color;

        div {
          color: $text_color;
        }
      }
    }
  }

  .textContainer {
    height: 100%;
  }

  .listContainer {
    position: absolute;
    left: -1px;
    right: -1px;
    top: 100%;
    height: calc(10 * 26px + 2px);
    background: white;
    border: 1px solid $light_grey;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    z-index: 100;

    div {
      color: $text_color !important;
    }

    .listContent {
      height: 100%;
      overflow: auto;

      .option {
        padding: 3px 6px;
        font-size: 14px;
        height: 26px;

        &:hover {
          background: transparentize($light_grey, .6);
        }
      }
    }
  }
}