@use "colors-legacy" as *;

.content {
  height: 100%;
  padding: 10px;
  position: relative;

  > div {
    width: 100%;
  }

  .noVoucher {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 150px;
    bottom: 150px;
    width: 30%;
    margin-left: 35%;

    svg {
      padding: 8px;
    }

    > div {
      font-size: 28px;
      color: $text_color_light;
      font-weight: 500;
      margin-top: 20px;
    }
  }
}
