@use "ui-kit-old" as *;

.container {
  z-index: 9999;
  position: fixed;

  background-color: $white;

  opacity: 0;
  padding: 0;
  min-width: 131px;

  transition: opacity .2s;

  box-shadow: 0px 3px 6px rgba($black, .16);
  border-radius: 3px;
  border: 1px solid $lightGrayThree;
  overflow: hidden;

  pointer-events: none;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  &.invert {
    
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
  }


  .option {
    width: 100%;
    padding: 0px 8px;
    background-color: transparent;
    transition: background-color .2s;
    height: 25px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:not(:last-child) {
      border-bottom: 1px solid $lightGrayThree;
    }

    &:hover {
      background-color: $lightBlue;
      cursor: pointer;
    }

    .noChildrenWrapper {
      user-select: none;
      width: 100%;
      height: auto;
      min-height: 14px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .iconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        height: 12px;

        .icon {
          width: auto;
          height: 100%;
        }
      }

      .label {
        margin: 0;
        padding: 0;

        font-size: 12px;
        font-weight: 400;
        color: $darkBlue;

        transition: font-weight .2s;
      }
    }

    &.disabled {
      &:hover {
        cursor: not-allowed;
        background-color: transparent;

        .noChildrenWrapper {
          .label {
          }
        }
      }

      .label {
        color: $silverBlue;
      }
    }
  }
}