@use "ui-kit" as *;
@use "ui-kit-old" as old;

.centeredContainer {
  width: 100%;
  min-height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  background-color: $white;
  width: initial;
  height: 100%;
  display: grid;

  grid-template-columns: 0px 2fr 0px 1fr;
  grid-template-rows: 60px minmax(70px, auto) 22px 1fr 0px;
  grid-template-areas:
    "header header header right"
    ". left1 . right"
    ". left1 . right"
    ". left1 . right";


  .header {
    grid-area: header;
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-bottom: 1px solid $strokeGray;
    box-sizing: border-box;
    position: relative;

    .arrowBack {
      margin-right: 20px;
    }

    .loadingLine {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
    }
  }
  .extraPadding {
    padding: 0px 30px;
  }
  .left1 {
    grid-area: left1;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 12px;
    height: auto;
    overflow: auto;

    > div {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .left {
    grid-area: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column nowrap;

    > div {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .right {
    grid-area: right;
    z-index: 9;
  }
}

.confirmPaymentOrRefundModal {
  padding: 20px;
  grid-template-rows: unset !important;

  > div:first-child {
    padding: 0 !important;
    background-color: transparent !important;
    color: $black !important;
    p {
      color: $black !important;
    }
    span {
     display: none;
    }
  }

  > div:nth-child(2) {
    padding: 10px 0 0 !important;
    div {
      color: $black !important;
    }
  }
}

.confirmCancelModal {
  max-width: 520px!important;
  .modalText {
    @include resetMarginPadding;
    font-size: 14px;
    margin-bottom: 25px;
  }

  hr {
    height: 1px;
    border: none;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: $strokeGray;
  }

  .table {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column nowrap;
    margin-bottom: 24px;
    .row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        @include resetMarginPadding;
        font-size: 14px;
        color: $dark;
        margin: 8px 0px;

        &.black {
          color: $black;
        }

        &.size16 {
          font-size: 16px;
        }
      }
    }
  }

  .buttonsRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.confirmOverrideContainer {
  display: flex;
  flex-direction: column;
  margin: -20px 0 0 -5px;

  .membershipName {
    color: old.$midGray;
    font-weight: old.$semiBold;
    margin-bottom: 5px;
  }


  .policyContainer {
    .policyName {
      font-weight: old.$semiBold;
    }

    ul {
      margin-left: 20px;
    }

    .violation {
      span {
        position: relative;
        left: -5px;
      }
    }
  }

  .confirmOverride {
    align-self: flex-end;
    justify-self: flex-end;
    margin-bottom: -20px;
    font-weight: old.$semiBold;
  }
}

.bookingStepsContainer {
  display: flex;
  justify-content: space-between;
  width: 40%;
}
