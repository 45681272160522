@use "colors-legacy" as *;

.container {
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
  }

  .text {
    font-size: 13px;
  }

  .timePicker {
    input {
      width: 60px;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .divider {
    align-self: flex-end;
    margin-bottom: 5px;
  }
}