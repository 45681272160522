.wrapper {
  border-radius: 4px;
  padding: 10px 25px 3px 25px;

  .label {
    margin-top: 10px;
    div {
      font-weight: 600;
      font-size: 18px;
    }
  }
}