/******************/
/* UI Kit V1 Club */
/*      Light     */
/******************/

$white: #ffffff;
$black: #000000;

$lightBlue: #eaf3fd;
$midBlue: #b9d8f6;
$blue: #74b1ee;
$silverBlue: #d2d5da;
$blueMist: #a6acb5;
$darkBlue: #4f5b6d;

$activeBlue: #7E93B4;
$activeBlueLight: #97ACCE;
$hoverBlue: #489EF4;
$skyBlue: #75B1EE;
$mainBlue: #73B0ED;

$green: #2ec182;
$easyGreen: #93cfb5;
$lightGreen: #96e0c0;
$veryLightGreen: #cff4e4;
$lightBlueGreen: #d7edeb;
$lightGreen2: #2fc182;

$gray: #f2f2f2;
$gray_inactive: #e0e0e0;
$grayTextAlt: #4f5b6c;
$lightGray: #f8f8f8;
$lightGrayTwo: #f7f7f7;
$lightGrayThree: #e5e5e5;
$lightGrayFour: #ededed;
$lightGrayFive: #d2d5da;
$lightGraySix: #d6d6d6;
$lightGraySeven: #f0f0f0;
$lightGrayEight: #e4e4e4;
$lightGrayNine: #D3D3D3;
$lightGrayTen: #DCDCDC;

$midGray: #A6ACB5;
$midGrayTwo: #7E7E7E;
$midGrayThree: #7C7C7C;
$midGrayFour: #8D8D8D;
$darkGray: #CCCCCC;
$darkerGray: #A5A5A5;
$yellow: #FFCE00;

$lightRed: #ffc6b8;
$red: #ff8d73;
$hoverRed: #ff7e60;
$orange: $red;
$redError: #ED6A65;

$boxShadow: 0 1px 6px rgba($black, 0.16);
$boxShadow2: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
$easyBoxShadow: 0px 3px 9px #0000000d;
$hardBoxShadow: 0px 3px 6px rgba($black, 0.16);
$toastShadow: 0 3px 6px rgba(0, 0, 0, 0.16);

$mainTextColor: $darkBlue;

$lightBorder: lighten($silverBlue, 10);

/*********************/
/* UI Kit V1 Web App */
/*       Light       */
/*********************/
$blueWebApp: #0a85ff;
$hoverBlueWebApp: #1267bc;
$activeBlueWebApp: #a2d1ff;

$grayWebApp: #cccccc;
$grayDarkWebApp: #3a3a3a;
$lightGrayWebApp: #b7b7b7;
$lightGrayPartner: #e4e4e4;
$medium_green: #90a199;
$dark_blue: #3f6275;
$medium_grey: #707070;
$medium_dark_grey: #525252;
$blue_400: #4DA0F1;
$gray_300: #9B9B9B;
$dark_fill: #65748A;
$pink_light: #f9d3d2;
$brown_dark: #525053;
$hover_black: #171717;



/*************/
/* UI Kit V1 */
/*    Dark   */
/*************/

// TBD

/*************/
/* UI Kit V2 */
/*    Light  */
/*************/

// TBD