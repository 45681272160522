@use "colors-legacy" as *;

.container {
  display: grid;
  grid-template-rows: auto;
  padding: 20px 25px 25px 35px;

  .title > div {
    display: inline-block;
    padding: 0 0 5px 0;
    font-size: 24px;
    font-weight: 600;
    margin-right: 15px;
    color: darken($semi_medium_grey, 10%);
  }

  form {
    margin-top: 20px;
    max-width: 620px;

    input {
      height: 32px;
    }

    .inputOptionsSelect {
      div > div:first-of-type {
        height: 32px;
      }
    }

    > div label,
    > div label > div {
      font-size: 15px;
      font-weight: 600;
    }

    .formButtons {
      display: flex;
      justify-content: space-between;
      margin: 30px 0;
    }

    .customPayOnSite {
      width: 75%;
    }
  }
}