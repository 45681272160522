@use "colors-legacy" as *;
@use "ui-kit-old" as *;

.container {
  width: 100%;
  height: 350px;
  overflow: hidden;
  border: 1px solid $gray_inactive;
  border-radius: 0px 3px 3px 3px;
  background-color: $gray;
  padding: 15px;
  margin-bottom: 10px;
}

.content {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid $gray_inactive;
  background-color: white;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 0.425;
  &.middle {
    flex: 0.15;
    padding-top: 70px;
    align-items: center;
    border-left: 1px solid $gray_inactive;
    border-right: 1px solid $gray_inactive;
  }
  .list {
    overflow-y: auto;
    padding-top: 3px;
  }
}

.row {
  width: 100%;
  min-height: 24px;
  height: 24px;
  font-size: 14px;
  border-bottom: 1px solid $lightGrayThree;
  padding-left: 8px;
  .label {
    font-size: 14px;
    color: $midGray;
  }
  &.noPadding {
    padding: 0;
  }
  &.item {
    border: none;
  }
  .searchContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0;
    height: 24px;
    border: 0;
    div {
      font-size: 14px !important;
      color: $midGray;
    }
  }
  .inputContainer {
    margin-right: 0;
    width: 100%;
    justify-content: flex-end;
    input {
      font-size: 14px;
    }
  }
  .iconContainer {
    height: 24px;
    margin-left: 3px;
    margin-right: 6px;
  }
  .searchIcon {
    width: 24px;
  }
}

.button {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  height: 20px !important;
  width: 85% !important;
  margin-bottom: 10px !important;
  border-radius: 5px !important;
  padding-right: 7px !important;
  padding-left: 7px !important;
  .text {
    padding: 5px 0px 7px;
  }
  .arrow {
    height: 10px;
    width: 10px;
    transform: scaleX(1.2) scaleY(0.9);
    path, circle {
      fill: white;
    }
    &.left {
      transform: scaleX(-1.2) scaleY(0.9);
    }
  }
}
