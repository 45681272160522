$gap: 32px !default;
$phone: 480px !default;
$tablet: 769px !default;
$desktop: 960px + 2 * $gap !default;
$widescreen: 1152px + 2 * $gap !default;
$fullhd: 1344px + 2 * $gap !default;

@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}
// Duplicate on above
@mixin phone {
  @media screen and (max-width: $phone - 1px) {
    @content;
  }
}

@mixin upToTablet {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet), print {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin upToDesktop {
  @media ( max-width: #{$desktop - 1px} ) {
    @content;
  }
}

@mixin hideUpToDesktop {
  visibility: visible;
  display: inherit;

  @include upToDesktop {
    visibility: hidden;
    display: none;
  }
}

@mixin fromDesktop {
  @media ( min-width: #{$desktop} ) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
    @content;
  }
}
@mixin smallDesktop {
  @media ( max-width: #{widescreen - 1px} ) and (min-width: #{$desktop - 1px}  ) {
    @content;
  }
}

@mixin upToWideDesktop {
  @media ( max-width: #{$widescreen  - 1px} ) {
    @content;
  }
}

@mixin until-widescreen {
  @media screen and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: $widescreen) {
    @content;
  }
}

@mixin widescreen-only {
  @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
    @content;
  }
}

@mixin until-fullhd {
  @media screen and (max-width: $fullhd - 1px) {
    @content;
  }
}

@mixin fullhd {
  @media screen and (min-width: $fullhd) {
    @content;
  }
}