@use "colors-legacy" as *;
@use "sizes-legacy" as *;


.wrapper {
  display: grid;
  grid-template-rows: auto;
  padding: 20px 25px 25px 35px;

  div {
    margin-top: 0;
  }
  form {
    margin-top: 30px;
    max-width: 600px;
  }
}

.formButtons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.label {
  display: inline;
  div {
    display: inline-block;
    font-size: 24px;
    font-weight: $semi_bold;
    color: darken($semi_medium_grey, 10%);
  }
}

.golfClubForm {
  label {
    font-size: 16px !important;
  }
}

.section {
  margin-bottom: 20px;

  div {
    color: $text_color;
  }

  label {
    font-weight: $semi_bold !important;
    font-size: 16px;
    margin-bottom: 7px;
  }

  .revenueSettings label {
    font-size: 12px !important;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-items: center;
    margin-bottom: 20px;

    .title {
      div {
        font-size: 18px;
        font-weight: $semi_bold;
      }
    }
  }

  .columnContainer {
    display: flex;
    flex-direction: row;
    height: 50px;
    margin-left: 10px;

    > div {
      margin-top: 1px;
      font-size: 16px;
      font-weight: $semi_bold;
    }

    .text {
      margin-right: 10px;
      padding-left: 12px;
    }

    .timePicker {
      width: 80px;
      margin-bottom: 20px;

      input {
        margin-left: 30px;
        height: 30px;
        font-size: 16px;
        color: $text_color;
      }
    }
  }
}

.noAccess {
  div {
    font-weight: bold;
    font-size: 22px;
  }
}
