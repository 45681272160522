@use "colors-legacy" as *;
@use "sizes-legacy" as *;

$header_breakpoint: 1400px;
$icon_breakpoint1: 1650px;

.header {
  padding-left: 15px;

  .headerTitle {
    display: flex;
    justify-content: space-between;

    > div {
      font-size: 18px;
      font-weight: bold;
      color: transparentize($color_black, .6);
    }
  }

  .occupancy {
    display: flex;
    flex-direction: row;
    font-size: $default_font_size;
    color: transparentize($color_black, .55);
    margin-top: 10px;

    .title {
      font-weight: bold;
      color: transparentize($color_black, .44);
      margin-right: 5px;
      margin-top: -3px;
    }
  }

  .viewMethod {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .text {
      font-size: $default_font_size;
      font-weight: bold;
      color: transparentize($color_black, .44);
      margin-bottom: 3px;
    }

    .days {
      display: flex;
      flex-direction: row;
      border-radius: 5px;

      &>div:first-of-type {
        border-radius: 5px 0 0 5px;
      }

      &>div:last-of-type {
        border-radius: 0 5px 5px 0;
      }

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $default_font_size;
        background: $very_light_grey;
        min-width: 80px;
        text-align: center;
        padding: 7px 15px;
        cursor: pointer;

        svg {
          font-size: 18px;
        }
      }

      div.active {
        background: transparentize($very_light_blue, .5);

        * {
          font-weight: bold;
        }

        svg path {
          color: white;
        }
      }
    }
  }
}

.timeSelect {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 12px;

  .calendarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    
    .container {
      height: 100%;
    }
    input {
      border: none;
      text-align: center;
      font-size: 18px;
      color: transparent;
      text-shadow: 0 0 0 $tee_sheet_blue;
      margin: 0;
    }
  }
}