@use "colors-legacy" as *;

.container {
  background: white;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: transparentize($color_black, .48);

  p {
    margin-bottom: 0;
    font-weight: bold;
    color: transparentize($color_black, .55);
  }

  span {
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    font-size: 10px;
    text-align: center;
    color: transparentize($color_black, .48);
  }
}

.empty {
  padding: 3px;
  min-height: 70px;
}

.bg0 {
  background: $price_color_green_dark;
}

.bg1 {
  background: $price_color_green_medium;
}

.bg2 {
  background: $price_color_green_light;
}

.bg3 {
  background: $color_white;
}

.bg4 {
  background: $price_color_red_light;
}

.bg5 {
  background: $price_color_red_medium;
}

.bg6 {
  background: $price_color_red_dark;
}