@use "ui-kit-old" as *;

.container {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: stretch;
  position: relative;
  padding: 5px 0;
  grid-gap: 5px;
  background: $white;
  user-select: none;

  .tab {
    //flex: 1 1 100%;
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      .label {
        color: $silverBlue;
      }
    }

    &.active {
      .label {
        font-weight: $semiBold;

        &:after {
          transform: scaleX(1);
        }
      }
    }

    &.disabled {
      .label {
        color: $silverBlue;
      }
      cursor: not-allowed;
    }

    .label {
      margin: 0;
      padding: 0;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: $mainTextColor;
      transition: color 0.2s;
      display: inline-block;

      &:after {
        display: block;
        content: '';
        margin-top: 3px;
        border-bottom: 3px solid $darkBlue;
        transform: scaleX(0);
        transition: transform 0.2s ease-in-out;
      }
    }

    .errorDot {
      display: inline-block;
      height: 6px;
      width: 6px;
      margin: -3px 0 0 4px;
      border-radius: 50%;

      &.visible {
        background: $red;
      }
    }
  }

  .indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30%;
    height: 3px;
    border-radius: 8px;
    background-color: $darkBlue;
    // transform: translateX(0%);

    transition: transform 0.2s;
  }
}
