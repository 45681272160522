@use "colors-legacy" as *;

.container {
  width: 100%;
  height: 100%;
  padding: 20px;

  > div {
    label {
      margin-bottom: 5px;
  
      > div {
        font-size: 15px;
        font-weight: 500;
      }
    }

    > div > div {
      background: transparent;
    }
  }

  .field {
    margin-bottom: 15px;
    
    label {
      margin-bottom: 0;

      div {
        color: $text_color_light;
        font-weight: 500;
      }
    }

    span {
      font-size: 15px;
      color: $text_color;
    }
  }
}