@use "ui-kit-old" as *;

.miniPill {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  margin-right: 8px;
  margin-bottom: 8px;
  justify-content: space-between;
  background: $activeBlue;
  color: $white;
  border-radius: 15px;
  font-size: 14px;
  height: auto;
  min-height: 20px;
  padding: 0px 10px;

  &:hover {
    cursor:  default;
    background: $activeBlueLight;
  }

  .dot {
    margin-left: -5px;
    margin-right: 5px;
  }

  span {
    margin-top: -2px;
    font-weight: 400;
    color: $white;
  }

  .closeButton {
    margin-top: -3px;
    margin-left: 8px;
    font-weight: bold;
    cursor: pointer;

    svg {
      height: 8px;

      path {
        fill: $white;
      }
    }
  }
}

.miniPillReadOnly {
  @extend .miniPill;
  &:hover {
    background: $activeBlue;
  }
}
