@use "colors-legacy" as *;
@use "ui-kit-old" as *;

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  padding: 12px 15px;
  position: relative;
  background: $text_color;
  border-radius: 10px 10px 0 0;

  > div {
    font-weight: 500;
    color: $color_white;
  }

  .closeIcon {
    position: absolute;
    right: 15px;
    top: 17px;
    cursor: pointer;

    path {
      fill: $color_white;
    }
  }
}

.loading {
  height: 538px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    > div > div > div {
      background-color: $text_color_light;
    }
  }
}

.body {
  padding: 20px 15px 0;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
}

.labelRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
}

.column {
  flex-direction: column;
  align-items: flex-start;
}

.hintAll {
  margin-top: -11px;
}

.hintMemberships {
  margin-top: -11px;
}

.shared {
  width: 100%;
  height: 350px;
  overflow-y: auto;
  border: 1px solid $gray_inactive;
  border-radius: 0px 3px 3px 3px;
  background-color: $gray;
  padding: 15px;
  margin-bottom: 10px;
  .sharedRow {
    width: 100%;
    display: flex;
    height: 28px;
    padding-left: 10px;
    align-items: center;
    background-color: $lightGray;
    cursor: default;
    &.even {
      background-color: white;
    }
  }
  .empty {
    font-size: 15px;
    color: #4F5B6D;
  }
}

.footer {
  display: flex;
  height: 50px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-right: 16px;
  justify-content: flex-end;
  justify-self: flex-end;
  box-shadow: 0px 0px 6px #00000029;
  margin-top: auto;
  .button {
    border-radius: 5px;
    padding: 0px 15px 0px 15px;
    height: 20px;
    margin-left: 15px;
    > div {
      font-size: 12px;
    }
    &.cancel {
      cursor: pointer;
      background-color: $settings_orange;
      border-color: $settings_orange;
      &:hover {
        background-color: orangered;
      }
    }
  }
}
