@use "ui-kit-old" as *;

$defaultBaseColor: #eee;
$defaultHighlightColor: #f5f5f5;

.defaultStyle {
  background-color: $defaultBaseColor;
  background-image: linear-gradient(
    90deg,
    $defaultBaseColor,
    $defaultHighlightColor,
    $defaultBaseColor
  );

  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;

  animation-name: pulse;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.darkTheme {
  background-color: $midBlue;
  background-image: linear-gradient(
    90deg,
    $midBlue,
    $activeBlue,
    $midBlue
  );
}

.lightTheme {
  background-color: $white;
  background-image: linear-gradient(
    90deg,
    $white,
    $defaultBaseColor,
    $white
  );
}
