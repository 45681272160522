@use "ui-kit" as *;

.container {
  height: calc(100% - 60px);
  margin: 20px -20px;
  padding: 0 29px;
  padding-top: 15px;
  overflow-y: auto;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;

  .title {
    @include resetMarginPadding;
    font-size: 18px;
    color: $dark;
    font-weight: 600;
    margin-bottom: 12px;
  }

  .form {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row nowrap;

    .availableContainer {
      width: 16%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: column nowrap;

      .availableText {
        @include resetMarginPadding;
        padding-top: 6px;
        font-size: 15px;
        color: $dark;
        width: 100%;
        text-align: center;
      }

      .loader {
        padding-top: 10px;
      }
    }
  }

  .note {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row nowrap;
  }

  .actions {
    width: 100%;
    margin-bottom: 41px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row nowrap;

    button {
      flex: 0 0 auto;
    }

    .errorWrapper {
      flex: 1 1 100%;
      background-color: $orange;
      padding: 12px 15px;
      border-radius: 3px;

      p {
        @include resetMarginPadding();
        font-size: 12px;
        font-weight: 600;
        color: $white;
      }
    }
  }

  .reservedContainer {
    margin-top: 15px;
    width: 100%;
  }
}
