@use "ui-kit-old" as *;

.dotContainer {
  height: 15px;
  width: 15px;
  flex: 0 0 15px;
  position: relative;
  overflow: hidden;

  margin-right: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  .dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;

    &.red {
      background-color: $red;
    }
    &.green {
      background-color: $green;
    }
  }
}
