@use "ui-kit" as *;
@use "colors-legacy" as *;

.container {
  overflow: hidden;
  background-color: $white;

  .course {
    height: 50px;
    padding: 15px 0 18px 16px;
    font-family: $fontRoboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: $mainBlue;
  }

  .content {
    display: grid;
    grid-template-columns: 210px auto;
    height: calc(100% - 35px);
    grid-gap: 6px;

    .left {
      display: grid;
      grid-template-rows: 75px auto;
      grid-gap: 6px;
      overflow: hidden;

      .header {
        background: $text_color;
        padding: 12px 10px;
        box-shadow: 3px 3px 6px rgba($color_black, 0.16);
        flex-direction: column;
        display: flex;
        justify-content: center;

        .title {
          font-size: 14px;
          font-weight: 500;
          color: $color_white;
        }

        >button {
          margin-top: 6px;
          padding: 0 8px 1px;
          width: 86px;

          >div {
            font-size: 14px;
          }
        }

        .createNewText {
          font-family: $fontRoboto;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          padding: 2px;
          display: flex;
          align-items: center;
          text-align: center;
          color: $dark;
        }
      }

      .periodList {
        padding-right: 0px !important;
      }
    }

    .right {
      overflow: hidden;
      display: flex;
      flex-direction: column;

      >.container:last-child {
        display: flex;
        flex-direction: column;
        flex: 1;

        form {
          display: flex;
          flex-direction: column;
          flex: 1;

          .priceTableContainer {
            width: 970px;
            display: flex;
            flex-direction: column;
            justify-content: stretch;
          }
        }
      }
    }
  }
}

.initialPriceLists {
  display: flex;
  flex-direction: column;
}

.priceListOverhead {
  display: flex;
  flex-direction: row;
  width: 226px;
  height: 30px;
}

.priceListFields {
  display: flex;
  flex-direction: row;
}

.priceListTable {
  display: flex;
  flex-direction: row;
}

.mainRectangle {
  box-sizing: border-box;
  width: 200px;
  height: 68px;
  background: $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}

.createNewButton {
  margin-bottom: 10px;
  margin-left: 10px;
  height: 30px;
}

.rectangle {
  box-sizing: border-box;
  width: 200px;
  height: 64px;
  background: $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}

.labelHeader {
  font-family: $fontRoboto;
  font-style: normal;
  font-size: 14px;
  height: 16px;
  display: flex;
  align-items: center;
  line-height: 16px;
  width: 75px;
  font-weight: 500;
  color: $iconGray;
  padding-top: 10px;
  padding-left: 10px;
}

.labelHeaderRectangle {
  font-family: $fontRoboto;
  font-style: normal;
  font-size: 14px;
  height: 16px;
  display: flex;
  align-items: center;
  line-height: 16px;
  width: 75px;
  font-weight: 500;
  color: $iconGray;
  padding-top: 10px;
  padding-left: 10px;
}

.labelBottom {
  font-family: $fontRoboto;
  font-style: normal;
  font-size: 14px;
  height: 16px;
  display: flex;
  align-items: center;
  line-height: 16px;
  margin-top: 25px;
  width: 140px;
  font-weight: 400;
  color: $dark;
  padding-bottom: 10px;
  padding-left: 10px;
}

.fiMoreHorizontal {
  margin-top: 4px;
  margin-right: 8.5px;
}

.heading {
  display: flex;
  justify-content: space-between;
}

.menuOptions {
  display: flex;
  flex-direction: row;
  margin-top: 7px;
}

.applyButton {
  border: 1px solid waDisabledGray;
  border-radius: 4px;
}

.tableheader {
  background: $backgroundGray;
  width: 120px;
  height: 40px;
}

.tableSelector {
  background: $backgroundGray;
  width: 80px;
  height: 40px;
}

.toggleMenu {
  margin-top: 20px;
  display: flex;
  gap: 20px;

  li {
    list-style-type: none;
    text-align: center;
    height: 2rem;

    &.active {
      border-bottom: 3px solid $dark;

      span {
        font-weight: 600;
      }
    }

    span {
      left: 10px;
      font-family: $fontRoboto;
      font-style: normal;
      color: $dark;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      text-decoration: none;
    }
  }
}

.timePeriodsFormContainer {
  min-height: 130px;

  .teeTimePeriod {
    border-radius: 7px 0 0;
    padding: 12px 10px;
    box-shadow: 3px 3px 6px rgba($color_black, 0.16);

    font-family: $fontRoboto;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-left: 21px;
    height: 75px;

    >.buttons {
      display: flex;
      gap: 15px;
      margin-right: 20px;

      .createBtnDisabled {
        background-color: $waDisabledGray !important;
        border: 1px solid $waDisabledGray;
      }

      .createBtnEnabled {
        background-color: $mainBlue !important;
        border: 1px solid $mainBlue;
      }
    }
  }

  .submitButton {
    button {
      margin-top: 18px;
      margin-right: 5px;
    }
  }

  .addOverride {
    padding-top: 15px !important;

    @media screen and (max-width: 1325px) {
      padding-top: 25px !important;
    }
  }

  .formCheckboxes {
    display: flex;
    align-items: center;
    gap: 25px;
    padding-right: 10px;
    margin-left: 12.5px;
    padding-top: 25px;

    >div {
      margin-bottom: 0;
    }
  }

  .teeTimePeriodOptions {
    display: flex;
    flex-wrap: wrap;
    margin-left: 7px;
    margin-top: 15px;
    gap: 10px;
    max-width: 1100px;

    input {
      height: 38px;
    }
  }

  .saveChanges {
    flex-grow: 1;
    padding-right: 25px;
    justify-content: flex-end;
    display: flex;
    align-items: flex-end;

    >div {
      display: flex;
      align-items: center;
    }
  }

  .teeTimePeriodContent {
    margin-left: 25px;
    margin-top: 15px;
  }

  .mainContainer {
    width: 250px;
  }

  .optionsText {
    font-family: $fontRoboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: $iconGray;
  }

  .numberInput {
    position: relative;
    height: 36.5px;
    width: 70px;

    &.slots {
      width: 57px;
    }

    :global input {
      font-family: $fontRoboto;
    }
  }

  .inputOptions {
    margin-top: 4px;
    margin-bottom: 0px;

    &:disabled {
      background-color: $backgroundGray;
      color: $iconGray;
    }
  }

  .priceInput {
    width: 90px;
  }

  .priceContainer {
    display: flex;
  }

  .scheduleTo {
    position: relative;
  }

  .sekBtn {
    width: 40px;
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    font-family: $fontRoboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    align-items: center;

    color: $dark;

    &:disabled {
      padding: 6px 8px 7px 9px;
      opacity: 1;
      color: $dark;
      font-weight: 500;
      height: auto;
      background-color: $backgroundGrayTwo;
    }
  }

  .applyBtn {
    margin-top: 14px;
    margin-bottom: 5px;
    width: 90px;
    justify-content: center;
    font-family: $fontRoboto;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    background-color: $white !important;
    color: $black !important;

    &:disabled {
      border-color: waDisabledGray;
      background-color: $backgroundGray !important;
      color: $iconGray !important;
    }
  }

  .saveChangesBtn {
    margin-top: 19px;
    width: 135px;
    justify-content: center;
    font-family: $fontRoboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: $white !important;
    background-color: $mainBlue !important;

    &:disabled {
      color: $white !important;
      background-color: $waDisabledGray !important;
    }
  }

  .select {
    margin-top: 4px;
    padding-top: 8px;
    margin-bottom: -5px;
    height: 38px;
    border: 1px solid $strokeGrayTwo;
    border-radius: 4px;

    >div {
      width: 100%;
    }

    span {
      height: 18px;
    }

    input {
      border: none !important;
    }

    svg {
      top: 6px;
    }
  }

  .categorySelect {
    max-width: 196px;
  }

  .intervalInput {
    width: 70px;
  }

  .datePicker {
    width: 146px;
  }

  .errored {
    position: relative;
    margin-bottom: 2rem;

    .error {
      margin-top: 3px;
      color: $red;
      width: 100%;
      word-wrap: break-word;
      position: absolute;
      font-size: small;
    }
  }

  .priceTableContainer {
    margin-top: 15px;
    position: relative;
    width: 100%;

    .priceTableBodyContainer {
      //height: 400px;
      overflow-y: auto;

      table {
        margin-top: -4px;

        thead tr th {
          height: 0 !important;
          overflow: hidden;
          width: 122px;
        }
      }
    }

    .priceTable {
      border-collapse: separate;
      border-spacing: 2px;

      thead th div span {
        display: block;

        &:not(:first-child) {
          font-size: 10px;
          color: $iconGray;
        }
      }

      th {
        cursor: pointer;
        background: $backgroundGray;
        width: 122px !important;
        align-items: center;
        text-align: center;

        &.complete {
          background-color: #bfdbfe;
        }

        &:first-child {
          width: 81px !important;
        }

        span,
        label {
          font-family: $fontRoboto;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: $black;
        }
      }

      td {
        div {
          cursor: pointer;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 0 25px;
          background: $backgroundGray;
          border: none;
          font-family: $fontRoboto;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: $black;

          span {
            font-family: $fontRoboto;
            color: $black;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;

            &.extra {
              font-size: 12px;
              color: $iconGray;
            }
          }
        }

        &.selected {
          div {
            background-color: $lightBlue;

            span:not(.extra) {
              background-color: $lightBlue;
              color: $blue;
            }
          }
        }
      }
    }
  }

  .tablePulseLoader {
    margin-top: 150px;
    display: flex;
    width: 970px;
    justify-content: center;

    .pulseLoader {
      width: 200px;
      height: 200px;
    }
  }
}

.input-with-arrows {
  position: relative;
  display: inline-block;
}

.arrow-up,
.arrow-down {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 20px;
}

.arrow-up {
  bottom: 50%;
}

.arrow-down {
  top: 50%;
}

.periodSelect,
.spaceSelect {
  padding: 0;
  position: relative;
  width: 250px;

  input {
    margin-top: -8px;
    font-family: $fontRoboto;
    font-style: normal;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: $dark;
    position: absolute;
    height: 36px;
    width: 100%;
    font-size: 16px;
  }

  [class*="listContainer"] {
    top: calc(100% + 25px);
  }
}

.toggleMenu {
  margin-top: 20px;
  display: flex;
  gap: 20px;

  li {
    list-style-type: none;
    text-align: center;
    height: 2rem;

    &.active {
      border-bottom: 3px solid $dark;

      a {
        font-weight: 600;
      }
    }

    a {
      left: 10px;
      font-family: $fontRoboto;
      font-style: normal;
      color: $dark;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      text-decoration: none;
    }
  }
}

.timePeriodDetailsContainer {
  display: flex;
  justify-content: space-between;
  max-width: 950px;
  align-items: center;
}

.letterStyle {
  display: flex;
  align-items: center;
  font-family: $fontRoboto;
  color: $dark;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  height: 18px;
  line-height: 18px;
}

.infoHover {
  margin-left: 7px;
  padding-right: 20px;
}

.tabsContainer {
  display: flex;
}

.teeTimePeriodsMainContainer {
  display: flex;
  flex-direction: row;
}

.saveChangesConfirmModal {
  >div>div>div:first-child {
    height: 40px;
    display: flex;
    align-items: center;
    font-family: $fontRoboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    gap: 30px;

    h3 {
      font-family: $fontRoboto;
      font-size: 15px;
      font-weight: 400;
      line-height: 23px;
    }

    label {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}
