@use "ui-kit-old" as *;

.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 12px;
  position: relative;

  span {
    font-size: 12px;
  }

  .fromTimeToggle {
    .lightColor {
      margin-bottom: 10px !important;
    }
  }

  .textInputField {
    margin-bottom: 0;

    &.minimum {
      margin-right: 50px;
    }
  }

  .inputField {
    &.minimum {
      text-align: right;
      padding-right: 0;
    }
  }

}
