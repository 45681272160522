@use "ui-kit-old" as *;

.container {
  padding: 20px 25px 25px 35px;
  min-height: 100%;

  .title {
    font-size: 24px;
    font-weight: 600;
    color: #b2b2b2;
    padding: 0 0 5px 0;
    line-height: 1.5;
  }

  .form {
    width: 50%;
    min-height: 100%;
  }

  .buttonsRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .button {
      margin-right: 10px;
    }

    .spinner {
      height: 20px;
      width: 20px;
    }
  }

  .generalError {
    color: $red;
  }
}