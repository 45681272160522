@use "ui-kit-old" as *;

.container {
  width: 100%;
  height: auto;

  border-radius: 3px;
  background-color: $lightGraySeven;
  padding: 12px;

  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
}