@use "ui-kit-old" as *;

.container {
  width: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: column wrap;
  margin-right: 20px;
  margin-top: 5px;

  .title {
    color: $white;
    font-size: 14px;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;

    .button {
      margin: 0 3px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
