@use "ui-kit" as *;

.footer {
  padding: 12px 31px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column nowrap;

  svg {
    color: unset;
    > path {
      color: unset;
      fill: currentColor;
    }
  }

  .paidOnSiteBox {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $strokeGray;
    width: 100%;
    margin-bottom: 20px;
    height: 36px;
    border-radius: 4px;
    box-shadow: 0px 2px 7px rgba($black, 0.05);
    padding: 0 17px;

    p {
      @include resetMarginPadding;
      font-size: 14px;
      font-family: $fontPrimary;
      color: $black;
      font-weight: 500;
    }
  }


  .backToSpacesBtn {
    color: #60A5FA !important;
  }

}
