@use "ui-kit-old" as *;

.container {
  z-index: 9999;
  position: fixed;

  background-color: $white;

  opacity: 0;
  padding: 0;
  min-width: 280px;

  transition: opacity .2s;

  box-shadow: $hardBoxShadow;
  border-radius: 5px;
  overflow: hidden;

  pointer-events: none;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }


  .option {
    width: 100%;
    padding: 10px 14px;

    background-color: transparent;

    transition: background-color .2s;



    &:hover {
      cursor: pointer;
      background-color: $lightBlue;

      .noChildrenWrapper {
        .label {
          font-weight: 600;
        }
      }
    }

    .noChildrenWrapper {
      user-select: none;
      width: 100%;
      height: auto;
      min-height: 16px;

      display: flex;
      align-items: center;
      justify-content: flex-start;
      .iconWrapper {
        flex: 1 1 auto;
        .icon {}
      }
      .label {
        flex: 1 1 auto;
        margin: 0;
        padding: 0;

        font-size: 15px;
        font-weight: 400;

        transition: font-weight .2s;
      }
    }

    &.disabled {
      &:hover {
        cursor: not-allowed;
        background-color: transparent;

        .noChildrenWrapper {
          .label {
            font-weight: 400;
          }
        }
      }

      .label {
        color: $silverBlue;
      }
    }
  }
}