@use "ui-kit" as *;

$startColor: #{var(--startColor)};
$endColor: #{var(--endColor)};

@include pulseBgAnim($startColor, $endColor, 0.1, 0.25, 1.01, 0.99);

.container {
  flex: 0 0 145px;
  width: 145px;
  border-right: 1px solid $strokeGray;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column nowrap;

  position: relative;

  .headerFill {
    height: 70px;
    width: 100%;
  }

  .header {
    width: 145px;
    height: 70px;
    border-bottom: 1px solid $strokeGray;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    background-color: $white;
    padding: 0 12px;

    z-index: 15;
    top: 0;
    position: sticky;

    .spaceNameContainer {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .spaceName {
      overflow: hidden;

      text-align: center;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;

      margin: 0;
      font-weight: 700;
      font-size: 14px;
      font-family: $fontSofia;
      color: $sweetspotBlack;
    }
    .spaceFloor {
      margin: 0;
      font-weight: 400;
      font-size: 12px;
      font-family: $fontSofia;
      color: $sweetspotBlack;
      text-align: center;
    }
  }

  .teeTime {
    &:not(:last-child) {
      box-shadow: inset 0px -1px 0px rgba($strokeGray, 0.3);
    }

    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px;
    box-sizing: border-box;
    width: 100%;

    user-select: none;

    &.categoryAnim {
      transition-delay: 400ms;
      animation-name: blinkBg;
      animation-duration: 3s;
      animation-timing-function: linear;
      animation-direction: normal;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
    }

    &.borderTop {
      border-top: 1px solid $strokeGray;
    }

    .priceContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
    }

    .price {
      margin: 0;
      padding-top: 5px;
      z-index: 10;
    }

    .innerHeaderContainer {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .rowContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .rowEndPosition {
      justify-content: flex-end;
    }

    .categoryName {
      margin: 0;
      font-family: $fontSofia;
      font-size: 12px;
      font-weight: 400;
      color: $sweetspotBlack;
    }
  }

  .teeTimesContainer,
  .bookingsContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column nowrap;
    z-index: 2;

    .headerFiller {
      width: 100%;
      flex: 0 0 70px;
    }

    .teeTimeFiller {
      width: 100%;
      pointer-events: none;
    }
  }

  .loaderContainer {
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1 1 100%;
  }
}
