@use "colors-legacy" as *;
@use "sizes-legacy" as *;

.container {
  display: inline-block;
  background: $very_light_grey;
  text-align: center;
  color: transparentize($color_black, .41);
  padding: 7px 25px;
  margin: 5px 5px 0 0;
  border-radius: 5px;

  .percent * {
    font-size: $default_font_size;
  }

  .days {
    display: flex;
    flex-direction: row;
    margin-top: -4px;

    span {
      margin-right: 3px;
    }
    
    * {
      font-size: 11px;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.container.active {
  background: transparentize($very_light_blue, .5);

  * {
    font-weight: bold;
    color: transparentize($color_black, .48);
  }
}