@use "ui-kit" as *;

.container {
  height: auto;
  width: 288px;
  background-color: $white;
  border-radius: 4px;

  box-shadow: 0px 4px 24px rgba($black, .1);

  transform-origin: top left;
  transform: scale(0);
  opacity: 0;

  transition: transform .2s, opacity .2s;
  pointer-events: none;

  &.visible {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
  }

  .loading {
    padding: 17px 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    .dot {
      width: 10px;
      height: 10px;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 16px;
    height: 27px;
    border-bottom: 1px solid $strokeGray;

    p {
      @include resetMarginPadding;
      color: $iconGray;
      font-size: 12px;

      &:first-child {
        margin-right: auto;
      }
    }

    span {
      margin-left: 10px;
      transform: scale(1);
      transition: transform .2s;

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }

  .inner {
    padding: 17px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column nowrap;

    hr {
      margin: 5px 0;
      border: 0;
      height: 1px;
      width: 100%;
      background-color: rgba($strokeGray, .6);
    }

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .memberBox {
        height: 18px;
        border: 1px solid $accentGreen;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        border-radius: 9px;
        p {
          @include resetMarginPadding;
          font-size: 10px;
          color: $accentGreen;
        }
      }

      .text {
        @include resetMarginPadding;
        font-size: 14px;
        color: $dark;

        &.black {
          color: $black;
          font-weight: 500;
        }
      }
    }
  }
}
