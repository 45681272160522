@use "ui-kit-old" as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  z-index: 100;
  top: 80px;
  right: 15px;
  background-color: white;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: auto;

  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;

    path {
      fill: $darkBlue;
      color: $darkBlue;
    }
  }

  .grid {
    min-width: 900px;
    width: auto;
    flex: 1 1 auto;

    .selected {
      flex: 0 0 20px;
    }
    .name {
      flex: 0 0 170px;
    }
    .golfId {
      flex: 0 0 115px;
    }
    .hcp {
      flex: 0 0 75px;
    }
    .gender {
      flex: 0 0 90px;
    }
    .booking {
      flex: 0 0 90px;
      justify-content: center;
    }
    .tee {
      flex: 0 0 200px;
    }
    .slope {
      flex: 0 0 100px;
    }
    .print {
      flex: 0 0 100px;
      margin-left: auto;
    }

    .gridButton {
      margin: 0;
      width: 100%;
    }
    .selectCheckbox {
      margin: 0;
    }
    .input {
      margin: 0;
    }
  }

  .settingsRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 15px;

    .scoreCardType {
      margin: 0;
      flex: 0 0 25%;
      margin-right: 15px;
    }
    .selectPrinter {
      margin: 0;
      flex: 0 0 25%;
    }
  }
}
