@use "colors-legacy" as *;

.container {
  background: $color_white;
  width: 550px;
  border-radius: 6px;
  box-shadow: 0px 0px 10px -5px #000;

  .header {
    display: flex;
    align-items: center;
    background-color: $text_color;
    padding: 8px 15px;
    border-radius: 4px 6px 0 0;
    position: relative;

    * {
      display: inline-flex;
      align-items: center;
    }

    > div {
      color: $color_white;
    }

    svg {
      margin-right: 10px;
      
      path {
        fill: $input_field_gray;
      }
    }

    .closeBtn {
      position: absolute;
      right: 5px;
      top: 15px;
      cursor: pointer;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 15px;
    min-height: 125px;

    .displayInline {
      display: flex;

      * {
        font-size: 15px;
      }
  
      b {
        font-weight: 500;
      }
  
      &.inline {
        display: inline-block;
        text-align: center;
  
        * {
          display: inline;
        }
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-top: auto;
  
      * {
        cursor: pointer;
        font-size: 15px;
        font-weight: 500;
      }

      button {
        margin-left: 15px;
      }
    }
  }
}