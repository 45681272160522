@use "colors-legacy" as *;
@use "abstract/colors-old" as s;
@use "abstract/mixins" as *;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 30px;

  .hidden {
    display: none;
  }

  .headers {
    flex: 0 0 100%;
    box-sizing: border-box;
    display: flex;
    max-height: 29px;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    align-items: flex-end;
    justify-content: flex-start;
    z-index: 2;

    .tab {
      height: 29px;
      padding: 0 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid s.$gray_inactive;
      border-bottom: 0;
      border-radius: 3px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: s.$gray_inactive;
      transition: height .2s, background-color .2s;
      margin-bottom: -2px;

      &:hover {
        cursor: pointer;
      }

      .label {
        @include resetMP;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0;
        color: s.$darkBlue;
        margin-bottom: 3px;
      }

      &.current {
        background-color: $very_light_green;
        height: 30px;
        .label {
          font-weight: bold;
        }
      }
    }
  }

}
