@use "ui-kit-old" as *;

.pill {
  width: auto;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;

  background-color: $lightGray;
  border-radius: 10px;

  margin-right: 8px;

  &:last-child{
    margin-right: 0;
  }

  &:only-child {
    margin-right: 0;
  }

  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 4px;

    &.green {
      background-color: $green;
    }
    &.red {
      background-color: $red;
    }
    &.deepRed {
      background-color: #EF4444;
    }
    &.gray {
      background-color: $blueMist;
    }
    &.yellow {
      background-color: $yellow;
    }
  }

  .text {
    @include resetMP;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    font-weight: 500;
    color: $darkBlue;
    vertical-align: middle;
  }
}
