@use "colors-legacy" as *;

.container {
  display: inline-block;
  margin-top: 20px;

  .noPolicy {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: $text_color_light;
    margin-top: 8px;
  }

  .policyWrapper {
    width: 100%;
    background-color: $very_light_green;
    border: 1px solid $light_grey;
    border-radius: 3px;
    padding: 12px 16px;
    position: relative;
    margin-bottom: 10px;

    .closeBtn {
      width: 15px;
      height: 15px;
      position: absolute;
      right: 14px;
      top: 12px;
      cursor: pointer;
    }

    .mainLabel {
      display: block;
      font-size: 13px;
      font-weight: 600;
      color: $user_card_light_grey;
    }

    .uType {
      position: relative;
      background: $color_white;
      box-shadow: $box_shadow;
      border: 1px solid $very_light_green;
      border-radius: 5px;
      border-left: 6px solid $text_color_light;
      padding: 10px 15px;
      margin-top: 10px;

      .title {
        font-size: 13px;
        font-weight: 600;
      }
    }

    .secondaryLabel {
      display: block;
      font-size: 13px;
      font-weight: 600;
      color: $text_color_light;
      margin-top: 6px;
    }

    .checkboxList {
      margin-top: 8px;

      > div {
        margin-bottom: 8px;

        label {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }

    .hcpInput {
      max-width: 100px;
      margin-top: 10px;

      > div {
        margin-bottom: 0;
      }

      > div > div > div p {
        font-size: 14px;
      }
    }
  }

  .newContainer {
    position: relative;
    margin-top: 10px;

    .dropdown {
      top: 20px;
      left: 0;
      bottom: unset;
      transform: none;
      min-width: 80px;
    }
  }
}

.membershipContainer {
  margin-top: 5px;

  > span {
    font-size: 13px;
    font-weight: 500;
    color: $text_color_light;
  }
}

.clipLoader {
  width: 100%;
  height: 30px;
  margin-top: 10px;
  padding: 0;
}
