@use "colors-legacy" as *;

.container {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 5px;
  padding-right: 9px;

  .arrowContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 14px;
    cursor: pointer;

    path {
      color: $text_color;
    }

    &:hover {
      path {
        color: $medium_blue;
      }
    }
  }
}