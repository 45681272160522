@use "colors-legacy" as *;
@use "ui-kit" as *;

.container {
  background: $color_white;
  padding: 10px;
  max-height: 50%;
  margin-top: 10px;
  overflow-x: auto;
  margin-bottom: 6px;
  margin-right: 10px;

  .header {
    display: flex;
    margin-bottom: 2px;
    margin-top: 12px;

    .title {
      font-family: $fontRoboto;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: $dark;
      margin-bottom: 15px;
    }
  }

  .tableContainer {
    max-height: 45vh;
    .header {
      display: grid;
      grid-template-columns: 140px 90px 90px 160px 140px 100px 100px 85px 67px 95px 150px 80px 85px 45px;
      grid-gap: 6px;
      margin-bottom: 5px;

      &.course {
        grid-template-columns: 140px 90px 90px 160px 140px 100px 85px 67px 95px 150px 80px 85px 45px;
      }

      @include until-fullhd {
        grid-template-columns: 140px 90px 90px 160px 140px 100px 85px 67px 95px 150px 60px 100px 85px 45px;
      }

      span {
        font-size: 12px;
        color: $text_color_light;
        font-family: $fontRoboto;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
      }
      .removeIcon svg {
        float: right;
        width: 15px;

        path {
          fill: $text_color_light;
        }
      }
    }

    .row {
      height: 25px;
      margin-top: 4px;

      p {
        font-family: $fontRoboto;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: $dark;
      }

      display: grid;
      grid-template-columns: 140px 90px 90px 160px 140px 100px 100px 85px 67px 95px 150px 80px 85px 45px;
      grid-gap: 6px;
      align-items: center;
      padding: 1px 0 2px;

      &.course {
        grid-template-columns: 140px 90px 90px 160px 140px 100px 85px 67px 95px 150px 80px 85px 45px;
      }

      @include until-fullhd {
        grid-template-columns: 140px 90px 120px 160px 140px 135px 125px 90px 95px 150px 60px 100px 85px 45px;
      }

      &:nth-child(odd) {
        background: $very_light_grey;
      }

      * {
        font-size: 14px;
        font-weight: 500;
        color: $text_color;
      }

      svg {
        width: 15px;
        height: 15px;

        path {
          fill: $text_color_light;
        }
      }

      .scheduleIcon {
        display: flex;
        align-items: center;
      }

      .removeIcon svg {
        float: right;
        cursor: pointer;
      }

      .disabledIcon svg {
        cursor: not-allowed;
        path {
          fill: $color_disabled;
        }
      }

    }
  }

  .noScheduled {
    font-size: 15px;
    font-weight: 500;
    color: $text_color;
    font-style: italic;
  }
}
