@use "colors-legacy" as *;

.container {
  width: 100%;
  height: 100%;
  background: $text_color;
  display: flex;
  flex-direction: column;

  .avatar {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 5px;
  }

  .menuItem {
    > div {
      color: $color_white;
      padding: 3px 20px 4px;
      font-size: 15px;
      cursor: pointer;
      font-weight: 500;
    }
      
    &.active > div {
      background: $user_card_light_grey;
    }

    &.disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }

  .bottom {
    margin-top: auto;
    margin-bottom: 20px;
    padding: 0 20px;
    font-size: 13px;

    > div {
      margin-bottom: 15px;

      * {
        color: $text_color_light;
      }
  
      span {
        color: $color_white;
      }
    }
  }
}