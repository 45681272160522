@use "ui-kit-old" as *;

.container {
  width: 100%;
  background-color: $gray;
  border: 1px solid $lightGrayThree;
  border-radius: 3px;
  margin-bottom: 0px;

  &.openContainer {
    background-color: #fff;
  }

  .header {
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .leftSide {
      flex: 1 1 auto;
      padding: 10px 24px;
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:hover {
        cursor: pointer;
      }
    }
    .rightSide {
      padding: 10px 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .caretContainer {
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .caretDown {
        width: 8px;
        height: auto;
        transform: rotate(-90deg);

        transition: transform 0.2s;

        * {
          fill: $darkBlue;
        }

        &.isOpen {
          transform: rotate(0deg);
        }
      }
    }

    .headerTitle {
      @include resetMP;
      font-size: 14px;
      font-weight: 600;
      color: $darkBlue;
    }

    .statusContainer {
      width: auto;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;

      background-color: $lightGray;
      border-radius: 10px;


      &:last-child{
        margin-right: 0;
      }

      &:only-child {
        margin-right: 0;
      }

      .dot {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 4px;

        &.green {
          background-color: $green;
        }
        &.red {
          background-color: $red;
        }
      }

      .text {
        @include resetMP;
        font-size: 12px;
        line-height: 12px;
        display: inline-block;
        font-weight: 400;
        color: $darkBlue;
        vertical-align: middle;
      }
    }

  }

  .foldContainer {
    overflow: hidden;
    max-height: 0;
    transform: scaleY(0);
    transform-origin: top;

    transition: transform 0.2s;

    .codeText {
      @include resetMP;
      font-size: 14px;
    }

    .copyIconContainer {
      width: 12px;
    }

    &.isOpen {
      overflow: visible;
      max-height: none;
      transform: scaleY(1);
    }
  }

  .topRow {
    padding: 14px 24px;
    padding-top: 0px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

    &.extraMargin {
      margin-bottom: 20px;
    }
  }
}
