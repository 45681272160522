@use "colors-legacy" as *;

.container {
  width: 100%;
  height: 100%;
  background: $text_color;

  .avatar {
    height: 150px;
    text-align: center;
    padding-top: 35px;
  }

  .menuItem {
    > div {
      color: $color_white;
      padding: 5px 20px;
      font-size: 15px;
      cursor: pointer;
      font-weight: 500;
    }
      
    &.active > div {
      background: $user_card_light_grey;
    }

    &.disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }
}