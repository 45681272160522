$header_height: 76px;
$default_font_size: 14px;
$font_weight_semibold: 600;
$font_weight_default: 400;

$normal: 400;
$medium: 500;
$semi_bold: 600;
$bold: 700;

:export {
  header_height: $header_height
}