@use "colors-legacy" as *;

.container {
  margin-top: -10px;
  margin-left: -12px;
  > div {
    background: none !important;
    margin-top: 5px;
    svg {
      margin-top: 18px;
      width: 16px;
      height: 16px;

      path {
        fill: $tee_sheet_blue;
      }
    }
  }
}
