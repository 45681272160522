@use "ui-kit-old" as *;

.container {
  display: flex;
  position: relative;

  .tooltip {
    display: flex;
    align-items: center;
    height: 100px;
    position: absolute;
    z-index: 500;
    opacity: 1;
    pointer-events: none;

    &.right {
      right: -12px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &.left {
      left: -262px;
      top: 0;
      bottom: 0;
      margin: auto;

      .arrow {
        left: 232px;
      }
    }

    & > div {
      width: 250px;
      position: fixed;
      border: 1px solid $darkBlue;
      border-radius: 5px;
      padding: 10px;
      margin: 0;
      white-space: pre-line;
      background: $darkBlue;
      color: $white;
      z-index: 500;
    }

    .arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: -18px;
      width: 18px;
      height: 21px;
      background: $darkBlue;
      transform: rotate(45deg);
      border-radius: 0;
      box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
    }

    div {
      line-height: 1.25;
      font-size: 15px;
      font-weight: 500;
    }
  }
}
