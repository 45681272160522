@use "colors-legacy" as *;

.container {
  height: auto;
  min-height: 100%;
  border-radius: 8px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 100%;
  grid-template-areas: 
    "header"
    "content"
  ;
  align-content: stretch;
  position: relative;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 5px;
    overflow: visible;
    grid-area: header;
    z-index: 99;

    .select {
      width: 210px;
      margin-left: 20px;
    
      > div {
        border: 1px solid $dashboard_grey;
        border-radius: 5px;
    
        * {
          border-color: $dashboard_grey;
          font-size: 14px !important;
          font-weight: 500;
        }
      }
    }

    .selectContainer {
      display: flex;
    }

    .playerTypeSelect {
      input {
        background: $color_white !important;
      }

      margin: 0 45px 0 5px;
    }

    .dropDownLabel {
      display: block;
      margin-bottom: 7px;
      font-size: 13px;
      font-weight: 600;
    }
  }

  .content {
    background: white;
    grid-area: content;
  }

  .contentWithHelperText {
    grid-area: content;
    background: white;
    .helperText {
      div {
        padding: 5px 0 5px 15px;
        font-weight: 600;
        font-size: 18px;
        color: lighten($medium_grey, 25%);
      }
    }
  }
}