@use "ui-kit" as *;

.container {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 31px;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid $strokeGray;
  }
}
