@use "colors-legacy" as *;
@use "ui-kit" as *;

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $text_color;
  border-radius: 0 7px 0 0;
  box-shadow: 2px 2px 6px rgba($color_black, 0.16);
  height: 75px;
}

.container {
  background: $text_color;
  padding: 16px 10px;
  border-radius: 0 7px 0 0;
  box-shadow: 2px 2px 6px rgba($color_black, 0.16);

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-template-columns: 200px 90px 90px 90px 120px 90px 70px;

    > div {
      &.name {
        width: 230px;
      }

      &.time {
        width: 130px;
      }

      &.interval {
        width: 90px;
      }

      &.status {
        width: 165px;
      }

      &.golfIdOption {
        width: 90px;
      }

      &.slots {
        width: 115px;
      }

      &.spaces {
        width: 85px;
      }

      h5 {
        color: $white;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 15px;
        font-style: normal;
        font-family: $fontRoboto;
        display: flex;
        align-items: center;
      }

      .label {
        font-family: $fontRoboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        color: $iconGray;
      }

      span {
        color: $white;
        font-family: $fontRoboto;
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        margin-top: 4px;
        align-items: center;
      }

      > div > div {
        color: $color_white;
      }
    }
  }
}
