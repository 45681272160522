@use "ui-kit-old" as *;
@use "ui-kit-old" as *;

.componentContainer {
  flex: 0 0 auto;
  width: auto;

  padding: 0 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  padding-top: 2px;
}

.prefix {
  border-right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.suffix {
  border-left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.text {
  font-weight: 500;
  font-size: 14px;
  color: $blueMist;
  margin: 0;
  padding: 0;
}
.noBorder {
  border: 0;
}
