@use "ui-kit-old" as *;

.text {
  @include resetMP;
  font-size: 14px;
  font-weight: 400;
  color: $black;

  display: block;
  vertical-align: middle;

  // Font weight
  &.regular {
    font-weight: 400!important;
  }
  &.medium {
    font-weight: 500!important;
  }

  // Font size
  &.font12 {
    font-size: 12px!important;
  }
  &.font13 {
    font-size: 13px!important;
  }
  &.font14 {
    font-size: 14px!important;
  }

  .suffixText {
    color: $black;
    font-size: 12px;
    font-weight: 400;
  }
}