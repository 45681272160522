@use "colors-legacy" as *;

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  * {
    font-weight: 500;
  }
  
  .image {
    margin-right: 7px;
    height: 20px;
    width: 20px;
  }

  &.hide {
    div {
      visibility: hidden;
    }
  }

  .inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    .image {
      margin-left: 5px;
    }
  }

  .name {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .partnershipRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 25px;
  }

  .infoHover {
    color: white;
    font-weight: bold;
  }

  .partnershipList {
    * {
      color: white;
    }
    .club {
      div {
        font-weight: 400;
        margin-top: -4px;
      }
    }
  }
}

