@use "colors-legacy" as *;

.container {
  position: relative;
  border-radius: 4px;

  .textContainer {
    height: 100%;
    width: 100%;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  &.open {
    border: 1px solid $light_grey;
    background: lighten($light_grey, 20);

    .textContainer {
      cursor: auto;
    }
  }
}