@use "colors-legacy" as *;
@use "ui-kit" as *;

.container {
  width: 100%;
  height: 300px;
  padding: 15px 16px 16px;
  overflow: visible;
}

.inactive {
  display: none;
}


.input {
  margin-bottom: 5px;
  width: 55%;

  input {
    height: 24px;
    font-size: 14px;
  }

  &.value {
    input {
      width: 80px;
    }
  }
}

.label {
  color: $text_color_light;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
}

.actionsArea {
  margin-bottom: 20px;

  .row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    > div {
      margin-right: 10px;
    }

    &.target {
      margin-bottom: 10px;
      justify-content: flex-start;
    }
  }


  .option {
    flex: 0.10;
    label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .checkbox {
    width: auto;
  }
  .checkboxLabel {
    margin: 3px 25px 3px 5px;
    width: auto;
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    font-size: 14px;
  }
}

.inputArea {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;

  > div {
    &.input  {
      width: 120px;
      margin-right: 20px;
    }

    > div {
      display: inline-block;
      margin-bottom: 0;
    }
  }
}

.error {
  float: unset;
  cursor: default;
  margin: 10px 0;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 24px;
  color: $red;
  font-weight: 500;
  font-size: 14px;
  //box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  transition: max-height 0.2s, opacity 0.2s, padding 0.2s;
  border-radius: 5px;
  //border: 1px solid $red;
}

.saveArea {
  float: right;
  display: flex;
  flex-direction: row;
  font-size: 14px;

  > button {
    width: 105px;
    margin-right: 15px;
  }
}
