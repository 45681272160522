@use "shared-legacy" as *;

.container {
  width: 100%;
  height: 100%;
}

.emptyContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 15%;

  .logo {
    width: 80px;
    height: auto;
    opacity: 0.5;
  }

  .text {
    max-width: 60%;
    text-align: center;

    div {
      font-weight: 500;
      color: $text_color_light;
      font-size: 22px;
    }
  }
}
