@use "ui-kit" as *;

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: $fontPrimary;
  margin-bottom: 20px;
  font-size: 1rem;
  gap: 0 20px;

  > div {
    display: grid;
    font-family: inherit;
    gap: 0 10px;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      "header header"
      "select select"
      "footer footer";

    .header {
      grid-area: header;
      grid-column-end: span 2;
      font-family: inherit;
      font-size: inherit;

      > label {
        font-family: inherit;
        color: inherit;
        font-weight: 500;
        color: $black;
        font-size: 0.875em;
      }
    }

    .selection {
      display: contents;
      grid-area: select;

      .input {
        width: 100%;
        min-width: 180px;
      }
    }

    .footer {
      grid-area: footer;
      grid-column-end: span 2;
      font-size: 0.75em;
      font-family: inherit;
      margin-top: 1em;
      max-height: 1.5em;
      margin-bottom: 10px;
    }
  }
}
