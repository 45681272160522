.container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: 10px;
  color: inherit;

  .content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;
  }

  .closeButton {
    font-size: 1em;
    cursor: pointer;
    color: inherit;
    justify-content: flex-end;
  }
}