@use "ui-kit-old" as *;

.container {
  width: 100%;
  height: auto;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  flex-flow: column nowrap;

  padding-bottom: 20px;

  &.loading {
    align-items: center;
    height: 100%;
  }

  .cardHeader {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-bottom: 24px;
    padding-left: 12px;

    .headerTitle {
      @include resetMP;
      font-size: 14px;
      font-weight: 600;
      color: $midGrayTwo;
      margin-bottom: 8px;
    }

    .leftColumn {
      flex: 0 0 75%;

      .autoRenewalContainer {
        line-height: 0px;
        > div {
          margin-bottom: 1px;
        }
        > label {
          margin-left: 33px;
          font-weight: 400;
        }
      }

      .statusContainer {
        width: 100%;
        height: auto;

        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: row wrap;
        margin-bottom: 4px;
        margin-top: 20px;
      }

      .dateWrapper,
      .paymentType {
        flex: 0 0 auto;
        margin-right: 24px;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-flow: column nowrap;

        .dateText,
        .paymentText {
          @include resetMP;
          display: inline-block;
          height: 14px;
          line-height: 14px;
          vertical-align: middle;
          font-size: 14px;
          font-weight: 400;
          color: $black;
        }
      }
    }
    .rightColumn {
      flex: 0 0 49%;
    }
  }

  .tabsContainer {
    padding-left: 12px;
  }
}
