@use "ui-kit-old" as *;

.toggleActiveSwitch {
  width: 140px;
  position: relative;
}

.container {
  width: 100%;
  background-color: $gray;
  border: 1px solid $lightGrayThree;
  border-radius: 3px;
  margin-bottom: 0px;

  //max-width: 768px;

  .header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    user-select: none;

    .leftSide {
      padding: 0 24px;
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:hover {
        cursor: pointer;
      }
    }
    .rightSide {
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .optionsIcon {
        margin-left: 16px;
        margin-right: 16px;

        &:hover {
          cursor: pointer;
          circle {
            fill: $blue;
          }
        }
      }

      .archivedLabel {
        color: $blueMist;
        font-weight: 600;
      }

      .toggleWrapper {
        width: 140px;
      }
    }

    .caretContainer {
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .caretDown {
        width: 8px;
        height: auto;
        transform: rotate(-90deg);

        transition: transform .2s;

        * {
          fill: $darkBlue;
        }

        &.isOpen {
          transform: rotate(0deg);
        }
      }
    }

    .headerTitle {
      max-width: 23vw;
      min-width: 250px;
      text-overflow: ellipsis;
      overflow: hidden;

      @include resetMP;
      font-size: 14px;
      font-weight: 600;
      color: $darkBlue;
    }

    &.newHeader {
      padding: 10px 24px;

      .headerTitle {
        font-weight: 400;
        color: $blueMist;
      }
      .deleteButton {
        margin: 0!important;
      }
    }
  }

  .foldContainer {
    overflow: hidden;
    max-height: 0;
    transform: scaleY(0);
    transform-origin: top;

    transition: transform .2s;

    &.isOpen {
      overflow: visible;
      max-height: none;
      transform: scaleY(1);
    }
  }

  .topRow {
    padding: 10px 24px;
    padding-top: 0px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;

    &.extraMargin {
      margin-bottom: 20px;
    }
  }

  .tabsContainer {
    width: 100%;
    height: auto;
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    position: relative;
  
    min-height: 30px;

    .tabsHeaders {
      flex: 0 0 100%;
      padding: 0 24px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      z-index: 2;
      user-select: none;
      
      .singleTab {
        height: 27px;
        flex: 0 0 auto;

        padding: 0 18px;

        display: flex;
        align-items: center;
        justify-content: center;
        
        border: 1px solid $lightGrayThree;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom: 0;

        background-color: $lightGrayThree;

        transition: height .2s, background-color .2s;

        &:hover {
          cursor: pointer;
        }

        .tabLabel {
          @include resetMP;
          font-size: 14px;
          font-weight: 400;
          color: $darkBlue;
        }

        &.currentTab {
          background-color: $gray;
          height: 29px;

          .tabLabel {
            font-weight: 600;
          }
        }
      }
    }

    .tabsHeaderLine {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      height: 1px;
      background-color: $lightGrayThree;
    }
  }

  .rulesCheckersWrapper {
    display: none;

    &.active {
      display: block;
    }
  }
}