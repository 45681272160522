@use "ui-kit" as *;
@use "ui-kit-old" as *;

.customPlayerSearch {
  display: flex;
  flex-direction: column;
  > :nth-child(1) {
    display: flex;
    width: 100%;
    gap: 16px;
  }
}

.confirmOverrideContainer {
  display: flex;
  flex-direction: column;
  margin: -20px 0 0 -5px;

  .membershipName {
    color: $midGray;
    font-weight: $semiBold;
    margin-bottom: 5px;
  }


  .policyContainer {
    .policyName {
      font-weight: $semiBold;
    }

    ul {
      margin-left: 20px;
    }

    .violation {
      span {
        position: relative;
        left: -5px;
      }
    }
  }

  .confirmOverride {
    align-self: flex-end;
    justify-self: flex-end;
    margin-bottom: -20px;
    font-weight: $semiBold;
  }
}

.tempContainer {
    width: auto;
}

.editSpaceButton {
  svg {
    color: unset;
    > path {
      color: unset;
      fill: currentColor;
    }
  }
}

.divider {
  margin: 0 -30px 24px;
  width: calc(100% + 60px),
}

.spacesContainer {
  > h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #000;
    margin-bottom: 16px;
  }
}
