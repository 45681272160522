@use "colors-legacy" as *;

.content {
  height: 100%;
  display: grid;
  position: relative;

  .title {
    font-size: 20px;
  }

  .noAccess {
    div {
      font-weight: bold;
      font-size: 22px;
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 100px);
  width: 100%;
  margin-top: 100px;
  position: absolute;

  > div {
    text-align: center;

    svg {
      width: 100px;
      height: 100px;
    }

    &.loading {
      > div {
        margin-bottom: 20px;

        > div > div > div {
          background-color: $text_color_light;
        }
      }
    }
  }

  .noFleetText {
    font-size: 28px;
    color: $text_color_light;
    margin-top: 10px;
  }
}
