@use "ui-kit-old" as *;

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
}

.widthAuto {
  width: auto;
  flex: 0 0 auto;
}
.widthFull {
  width: 100%;
  flex: 0 0 100%;
}
.widthHalf {
  width: 50%;
  flex: 0 0 50%;
}
.widthQuarter {
  width: 25%;
  flex: 0 0 25%;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  position: relative;

  height: 22px;

  box-sizing: border-box;

}

.innerContainer {
  flex: 0 0 100%;
  height: 100%;
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  border-width: 1px;
  border-style: solid;
  border-color: $blueMist;
  border-radius: 0;

  background-color: $gray;

  z-index: 1;

  &:hover {
    cursor: pointer;
  }

}

.containerDisabled {
  border-color: lighten($blueMist, 20);
  &:hover {
    cursor: not-allowed;
  }
}

.medium {
  height: 26px
}
.large {
  height: 30px;
}

.containerRound {
  border-radius: 5px;
}


.checkboxInput {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.slider {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 50%;
  z-index: 2;
  transform: translateX(50%);

  will-change: auto;

  background-color: $gray;

  transition: transform .2s ease-in-out, background-color .2s ease-in-out;
}
.sliderRound {
  border-radius: 5px;
}
.sliderUnchecked {
  transform: translateX(0);
  background-color: $orange;
}
.sliderChecked {
  transform: translateX(100%);
  background-color: $green;
}
.sliderDisabledUnchecked {
  background-color: lighten($orange, 10);
}
.sliderDisabledChecked {
  background-color: lighten($green, 10);
}

.text, .text div {
  flex: 1 0 50%;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  z-index: 3;

  color: $blueMist;
  transition: color .2s ease-in-out;

  pointer-events: none;
}
.textActive, .textActive div {
  color: $white;
}