@use "colors-legacy" as *;

.container {
  width: 1100px;
  max-width: 90%;
  height: 400px;
  background: $color_white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px -4px $color_black;
  position: relative;

  .closeBtn {
    position: absolute;
    right: 15px;
    top: -10px;
    font-size: 40px;
    cursor: pointer;
  }

  .header {
    display: flex;
    flex-direction: row;

    .title {
      div {
        font-size: 18px;
        font-weight: 600;
        color: $text_color_light;
      }
    }
  }

  .searchInput {
    margin: 15px 0 15px;
    display: flex;
    flex-direction: row;
  }

  .searchResulTable {
    width: 100%;
    height: 75%;
    overflow: auto;
  }
}