@use "ui-kit-old" as *;

.textInput {
  flex: 1 1 100%;
  width: 100%;
  box-shadow: none;
  border-radius: 3px;
  padding: 5px 10px;
  background-color: inherit;

  font-size: 15px;
  line-height: 15px;
  font-weight: 400;
  color: $darkBlue;

  &::placeholder {
    color: $midGray;
  }

  &:focus,
  &:active {
    border-color: $mainBlue;
  }

  &.defaultColor {
    &::placeholder {
      color: $midGray;
      border-color: $midGray;
    }
  }

  &.whiteOutlineTheme {
    background-color: transparent;
    color: $white;
    border-color: $silverBlue;
  }

  &.noBorder {
    border: none;
  }

  &.readOnly {
    user-select: none;
  }

  &.reducePaddingRight {
    padding-right: 0;
  }

  &.isFocused {
    border-color: $mainBlue;
  }
}

.textInputDisabled {
  color: rgba($darkBlue, 0.8);
  cursor: not-allowed;

  &::placeholder {
    font-weight: 500;
    color: rgba($darkBlue, 0.5);
  }

  &:focus,
  &:active {
    border-color: rgba($silverBlue, 0.5);
  }
}

.hasPrefix {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.hasSuffix {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.loginTheme {
  border-radius: 0;
  border: none;
}

.webAppTheme,
.lessDarker {
  padding-left: 0;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid black;
  font-size: 19px;
  color: black;
}

.webAppTheme:focus,
.webAppTheme:active,
.lessDarker:focus,
.lessDarker:active {
  border-color: rgba(0, 0, 0, 1);
}

.webAppTheme::placeholder {
  font-weight: 400;
  color: black;
  font-size: 14px;
}

.lessDarker::placeholder {
  font-weight: 400;
  color: #8a8a8a;
  font-size: 14px;
}

.ppTheme {
  padding-left: 0;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid $black;
  font-size: 1rem;

  color: $black;

  &::placeholder {
    font-weight: 400;
    color: $midGray;
    font-size: 1rem;
    border-color: rgba($midGray, 1);
  }

  &:focus,
  &:active {
    border-color: rgba($black, 1);
  }
}

.withCalendarPrefix {
  height: 38px;
}
