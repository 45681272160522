@use "ui-kit-old" as *;

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.container {
  display: contents;

  > div {
    transform: inherit;
    transform-origin: top;
    transition: transform 0.15s linear;
  }

  .input {
    margin-bottom: 0;
  }

  .status {
    opacity: 0;
    font-size: 1rem;

    &.success {
      opacity: 1;
      animation: fadeOut 2s ease-out 4s 1 normal forwards;
      svg,
      path {
        color: $green;
      }
    }
    &.error {
      opacity: 1;
      > div > svg > path {
        color: $red;
      }
    }
  }

  .clickable {
    cursor: pointer;
    user-select: none;
    font-size: 1.125rem;

    .toggle {
      margin-bottom: 0;
    }

    svg,
    path {
      color: $midGray;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
}
