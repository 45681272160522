@use "ui-kit-old" as *;

.container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  max-height: 260px;
  border-radius: 8px;
}

.notesContainer {
  width: 100%;
  overflow-y: auto;
  margin-bottom: 3px;

  .scrollElement {
    flex: 0 0 0;
    height: 0;
    width: 100%;
  }

  .note {
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    word-break: break-word;

    .headerArea {
      width: 100%;
      display: flex;
      flex-direction: row;
    }

    .buttonArea {
      display: flex;
      flex-direction: row;
      margin-left: auto;

      p:first-child {
        margin-right: 15px;
      }
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: $gray_300;

      &.text {
        font-size: 14px;
        line-height: 18px;
        color: black;
        margin-top: 5px;
      }

      &.author {
        font-weight: 500;
        margin-right: 5px;
      }

      &.time {
        font-weight: 300;
      }

      &.button {
        color: $blue_400;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}

.addNoteContainer {
  z-index: 99;
  width: 100%;
  transition: transform 0.2s;
  background-color: $white;
  height: auto;

  .textContainer {
    margin: 0;
  }

  .inputContainer {
    textarea {
      padding-left: 20px !important;
      padding-right: 20px !important;
      border-radius: 8px;
    }
  }

  .infoText {
    margin: 0;
    padding: 0;
    font-size: 10px;
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: $darkBlue;
    cursor: default;
    user-select: none;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }
}
