@use "colors-legacy" as *;

.label {
  display: inline-block;
  background: $text_color_light;
  border-radius: 5px;
  padding: 2px 5px;
  cursor: pointer;

  div {
    font-style: italic;
    color: $color_white;
  }
}
