@use "ui-kit-old" as old;
@use "ui-kit" as *;

.container {
  height: 100%;
  width: 100%;

  background-color: $white;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;

  position: relative;
  overflow: hidden;

  .headersContainer {
    width: 100%;
    flex: 1;
    position: relative;
  }

  .centeredContainer {
    border-top: 1px solid $strokeGray;

    flex: 1 1 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .header {
    flex: 0 0 60px;
  }

  .categoriesRow {
    flex: 0 0 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 30px;
    padding-bottom: 16px;
    height: 36px;

    .category {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 25px;

      .categoryDot {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        margin-right: 7px;
      }

      .categoryName {
        margin: 0;
        color: $dark;
        font-family: $fontSofia;
        font-size: 12px;
      }
    }
  }

  .spacesContainer {
    flex: 1 1 100%;
    width: 100%;

    border-top: 1px solid $strokeGray;
  }
}