@use "colors-legacy" as *;
@use "ui-kit-old" as *;

.container {
  width: 100%;
  height: auto;
  padding: 27px 16px 16px;
  overflow: visible;
}

.inactive {
  display: none;
}

.hidden {
  visibility: hidden;
}

.input {
  margin-bottom: 5px;
  width: 55%;

  input {
    height: 24px;
    font-size: 14px;
  }

  &.noCursor {
    input {
      cursor: default;
    }
  }
}

.label {
  color: $text_color_light;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 3px;
}

.error {
  z-index: 1;
  &.name {
    margin-bottom: 1px;
  }
  &.date {
    margin-bottom: 22px;
  }
  &.code {
    margin-bottom: -23px;
  }
}

.typeArea {
  margin-bottom: 25px;
}

.optionsRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .option {
    flex: 0.25;
    label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: black;
    }
  }
}

.codeArea {
  display: flex;
  width: 100%;
  flex-direction: row;
  max-height: 25px;
  margin-bottom: 40px;

  .codeBtn {
    display: flex;
    margin-left: 10px;
    margin-top: 20px;
    font-size: 14px;
    padding: 3px 9px 3px;
    border-color: $mainBlue;

    &:hover {
      background-color: $mainBlue;
      color: white;
    }
    &:active {
      background-color: $blueWebApp;
      color: white;
    }
  }

  .or {
    font-size: 14px;
    margin-top: 21px;
    margin-left: 10px;
  }
}

.createBtn {
  float: right;
  font-size: 14px;
  padding: 0 15px 3px;
  border-color: $mainBlue;;
}

.choosePeriodType {
  margin-top: 30px;
  margin-bottom: 8px;
}

.periodContainer{
  display: none;
  grid-template-columns: repeat(5, minmax(80px, auto)) 1fr;
  grid-template-rows: auto 1fr;
  grid-auto-flow: column;
  column-gap: 10px;
  width: auto;

  &.active {
    display: grid;
  }

  &.dynamicPeriod {
    grid-template-columns: minmax(80px, auto) minmax(80px, auto) max-content minmax(80px, auto) 1fr;
  }

  .toggleEndDate {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    width: 100%;
    label {
      margin-bottom: 2px;
    }
  }

  .dateInput {
    min-width: 130px;
  }

  .time {
    width: 62px;
    .timeInput {
      input {
        text-align: center;
        padding: 0;
      }
    }
  }
}

.confirmHeader {
  height: 5px;
}

.periodConfirm {
  display: flex;
  flex-direction: row;
  margin-left: -180px;
  margin-top: 40px;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
  .action {
    margin: 0 5px 0 0;
    width: 82px;
  }

  .loader {
    padding-top: 5px;
  }
}