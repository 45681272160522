@use "ui-kit" as *;

.container {
  background-color: $white;
  width: 350px;
  height: auto;
  padding: 13px 17px;

  box-shadow: 0px 4px 24px rgba($black, .1);
  border-radius: 6px;

  position: fixed;


  .group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: row nowrap;
    margin-bottom: 10px;
    
    .checkbox {
      flex: 0 0 18px;
      margin-top: 4px;
      margin-bottom: 0;
    }

    .right {
      flex: 1 1 auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-flow: column nowrap;

      .label {
        @include resetMarginPadding;
        font-family: $fontRoboto;
        font-size: 14px;
        line-height: 24px;
        color: $darkTwo;
      }

      .description {
        @include resetMarginPadding;
        font-family: $fontRoboto;
        color: $dark;
        font-size: 12px;
      }
    }

    &.footerGroup {
      align-items: center;
      margin-bottom: 0;
      .checkbox {
        margin-top: -1px;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-top: 24px;
    width: 100%;

    p, button {
      font-family: $fontRoboto;
    }

    .destructive {
      @include resetMarginPadding;
      color: $red;
      font-size: 14px;
      font-weight: 400;

      transition: color .2s;

      &:hover {
        cursor: pointer;
        color: rgba($red, .8);
      }
    }

    .right {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-flow: row nowrap;

      button {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}