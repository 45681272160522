@use "ui-kit" as *;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 99;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba($black, .5);

  opacity: 0;
  pointer-events: none;

  transition: opacity .2s;


  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .container {
    max-width: 100%;
    min-width: 520px;
    height: auto;
    background-color: $white;
    border-radius: 10px;
    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr;
    grid-template-areas: 
      "header"
      "body"
    ;

    .header {
      grid-area: header;
      background-color: $dark;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 21px;

      .left {
        padding-right: 21px;

        p {
          @include resetMarginPadding;
          color: $white;
          font-size: 20px;
        }
      }

      .closeIcon {
        transform: scale(1);
        transition: transform .2s;

        &:hover {
          cursor: pointer;
          transform: scale(1.1);
        }
      }
    }

    .inner {
      grid-area: body;
      padding: 23px 21px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-flow: column nowrap;
    }
  }
}