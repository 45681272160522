@use "colors-legacy" as *;

.container {
  button {
    height: 32px;
    position: relative;

    .buttonText {
      display: flex;
      align-self: center;

      align-items: center;
      justify-content: center;

      color: inherit;

      * {
        font-weight: 500;
      }

      >svg {
        transform: rotate(90deg);
        margin-left: 5px;

        path {
          fill: $medium_blue;
        }
      }
    }

    .list {
      width: 200px;
      position: absolute;
      top: 32px;
      left: 0;
      background: $color_white;
      padding: 5px 0;
      border-radius: 5px;
      box-shadow: 0px 3px 6px transparentize($color_black, 0.71);
      text-align: left;
      z-index: 100;

      &.fromRight {
        left: unset;
        right: 0;
      }

      &.noItemWrap {
        width: auto;
      }

      &.noItemWrap .listItem {
        white-space: nowrap;
      }

      .listItem {
        position: relative;
        padding: 5px 12px;

        * {
          display: block;
          color: $text_color;
          font-size: 15px;
          font-weight: 500;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &:hover {
          background: $medium_light_blue;
        }

        >img {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }

        .subItemsList {
          position: absolute;
          left: 199px;
          top: -5px;
          min-width: 200px;
          max-width: 500px;
          max-height: 500px;
          overflow-y: auto;
          background: $color_white;
          padding: 5px 0;
          border-radius: 5px;
          box-shadow: 0px 3px 6px transparentize($color_black, 0.71);

          &.fromRight {
            left: unset;
            right: 199px;
          }

          &.noItemWrap {
            width: auto;
          }

          &.noItemWrap .subItem {
            white-space: nowrap;
          }

          .subItem {
            padding: 5px 12px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
              background: $medium_blue;
              color: $color_white;
            }
          }
        }
      }

      .disabled {
        opacity: 0.3;
      }
    }
  }
}