@use "colors-legacy" as *;

.container {
  background: $color_white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 500px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px -4px $color_black;
  padding: 50px 25px;

  h5 {
    font-size: 20px;
  }

  .displayInline {
    display: flex;

    &.inline {
      display: inline-block;
      text-align: center;

      b {
        font-weight: 600;
      }

      * {
        display: inline;
      }
    }
  }
}
