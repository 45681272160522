@use "colors-legacy" as *;

.container {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 85%;
  align-items: center;
  justify-content: center;

  h2 div {
    font-size: 50px;
    font-weight: 600;
    color: #A6ACB5;
    margin-bottom: 20px;
  }

  h4 div {
    font-size: 21px;
    font-weight: 500;
    color: #A6ACB5;
    margin-bottom: 35px;
  }

  h5 div {
    font-weight: 500;
    color: $text_color_light;
  }
}