@use "ui-kit-old" as *;

.feedbackContainer {
  position: absolute;
  left: 0;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  padding: 0px 0px;

  max-height: 0px;
  opacity: 0;
  height: auto;

  overflow: hidden;
  z-index: 299;
  background-color: rgba($white, 0);

  border-radius: 3px;

  box-shadow: 0px 3px 6px rgba($black, 0.16);

  transition: max-height 0.2s, opacity 0.2s, padding 0.2s;
}
.feedbackContainerVisible {
  padding: 6px 5px;
  opacity: 1;
  max-height: 100px;

  &.error {
    background-color: rgba($red, 1);
  }
  &.success {
    background-color: rgba($green, 1);
  }
}

.positionBottom {
  bottom: -2px;
  transform: translateY(100%);
}
.positionTop {
  top: -2px;
  transform: translateY(-100%);
}
.anchorRight {
  left: unset;
  right: 0;
}

.feedbackText {
  line-height: 17px;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  margin: 0;
  padding: 0;
}
.error {
  color: $white;
}
.success {
  color: $white;
}
