/**
 * DO NOT ADD OR CHANGE THESE
 * THESE SHOULD NOW BE MANAGED FROM TAILWIND CONFIG
*/

/** Colors from UI kit */
$blue: #489ef4 !default;
$mainBlue: #74b1ee;
$lightBlue: #eaf3fd;

$backgroundGray: #f7f7f7;
$backgroundGrayTwo: #f2f2f2;
$strokeGray: #e5e5e5;
$strokeGrayTwo: #e0e0e0;
$iconGray: #9b9b9b;

$accentGreen: #59ae8a;

$white: #ffffff !default;
$black: #000000 !default;
$sweetspotBlack: #171717;
$sweetspotYellow: #fff853;
$dark: #4f5b6c;
$darkTwo: #1d2d48;

$orange: #fe8d73;

$red: #e53737;

/** Web app colors **/
$waEnabledBlue: #0a85ff;
$waHoverBlue: #1267bc;
$waActiveBlue: #a2d1ff;
$waDisabledGray: #cccccc;
$waDefaultHighlightColor: #f5f5f5;
$waPink: #fdeee9;
$waSoftGreen: #d5eae4;
$waRealBackgroundGray: #f2f0e7;
