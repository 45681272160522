@use "ui-kit-old" as *;

.box {
  width: 100%;
  height: 100%;
}

.container {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .paymentsHeader {
    padding-left: 10px;
  }

  .gridWrapper {
    flex: 0 1 auto;
    overflow-y: auto;

    &.loading {
      flex: 0 0 100%;
    }

    .method {
      flex: 0 0 150px;
    }
    .date {
      flex: 0 0 100px;
    }
    .psp {
      flex: 0 0 150px;
    }
    .type {
      flex: 0 0 50px;
    }
    .amount {
      flex: 0 0 auto;
      margin-left: auto;
      text-align: right;
    }

    .row {
      padding-left: 10px;
      .method {
        font-size: 14px;
        font-weight: 700;
      }
      .date {
        font-size: 14px;
        font-weight: 400;
      }
      .type {
        font-size: 14px;
        font-weight: 700;
        .typeText {
          color: $black;
        }
      }
      .receipt {
        margin-left: auto;

        button {
          color: $darkBlue;
          font-size: 13px;
          padding-top: 2px;
          background-color: $gray;
          width: 80px;

          > div {
            min-width: unset;
          }

          svg {
            margin-right: 5px;
          }
        }
      }
      .amount {
        font-size: 14px;
        width: 100px;
        justify-content: flex-end;
        span {
          text-align: right;
          margin-right: 0;
          padding-right: 0;
        }
        .positive {
          font-weight: 700;
        }
        .negative {
          font-weight: 400;
        }
      }
    }
  }

  .totalPaidRow, .leftToPayRow {
    width: 100%;
    flex: 0 0 34px;
    border-top: 1px solid $lightBorder;
    border-bottom: 1px solid $lightBorder;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 11px;

    .totalPaid, .leftToPay {
      font-size: 14px;
      font-weight: 700;
      margin: 0;
      padding: 0;
      .amount {
        font-size: 14px;
        font-weight: 700;
        &.positive {
          color: $green;
        }
        &.neutral {
          color: $darkBlue;
        }
        &.negative {
          color: $orange;
        }
      }
    }
  }

  .bottomRow {
    width: 100%;
    flex: 0 0 auto;
    padding: 14px 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .addPaymentButton {
      margin: 0;
      flex: 0 0 auto;
      width: auto;
      min-width: 200px;
      justify-content: space-between;
      margin-right: 10px;
    }

    .markAsPaidButton {
      margin: 0;
      flex: 0 0 auto;
      width: auto;
      min-width: 200px;
      margin-right: 10px;
    }

    .leftToPay {
      margin: 0;
      padding: 0;
      text-align: right;
      font-size: 14px;
      font-weight: 700;
      margin-left: auto;
    }
  }
}
