@use "colors-legacy" as *;

.content {
  width: 100%;
  height: 100%;
  padding: 25px 20px;
  padding-left: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column nowrap;
  box-sizing: border-box;

  .header {
    width: 100%;
    flex: 0 0 auto;
    height: auto;

    display: grid;
    grid-template-columns: 1fr 110px;
    grid-gap: 15px;
    margin-bottom: 10px;

    padding-left: 12px;
  }

  .body {
    flex: 1 1 10%;
    height: 10%;
    width: 100%;

    .underConstructionContainer {
      width: 100%;
      height: 100%;
      padding: 50px 0;
    }



    .row {
      display: grid;
      grid-template-columns: 52% 44%;
      grid-gap: 4%;

      .title {
        font-weight: 600;
        margin-top: 20px;

        > div {
          font-weight: 600;
        }
      }

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        font-size: 14px;

        label {
          max-width: 80%;

          > div {
            font-weight: 600;
            color: $text_color_light;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          > div {
            display: inline !important;
          }
        }
      }

      &:nth-child(2n) {
        margin: 0 -20px;

        > div {
          background: $very_light_grey;

          &:first-of-type {
            padding-left: 20px;
          }

          &:last-of-type {
            padding-right: 20px;
          }
        }
      }
    }

    .daysIncluded {
      display: grid;
      grid-template-columns: 52% 44%;
      grid-gap: 4%;

      > div:first-of-type {
        background: $very_light_grey;
      }
    }
  }
}

.noMembership {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  button {
    width: 200px;

  }

  .noMembershipIcon {
    margin-top: 50px;
    > i {
      font-size: 45px;
    }
  }

  .dropdownContainer {
    margin-top: 30px;
  }


  > h1 {
    font-size: 18px;
    > div {
      font-weight: 600;
      color: $color_black;
    }
  }

  > h6 {
    font-size: 14px;
    > div {
      color: $color_black;
    }
  }
}
