@use "colors-legacy" as *;

.input-group {
  margin: 0;
  flex-wrap: nowrap;

  .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.ss-input-group {
  margin: 0;
  flex-wrap: nowrap;
}

.input-group-text {
  border: 1px solid $light_grey;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.form-control {
  font-size: 16px;
  border: 1px solid $light_grey;
  border-radius: 4px;

  &:focus {
    outline: none;
    border: 1px solid $light_grey;
  }
}

.img-fluid {
  width: 100%;
}

.input-range__track, .input-range__track--active {
  background: $tee_sheet_blue !important;
}

.input-range__slider {
  background:  $tee_sheet_blue !important;
  border-color: $tee_sheet_blue !important;
}

.multi-select {
  &>div {
    border-color: $light_grey !important;
    box-shadow: $box_shadow;

    &.multi-select__control {
      box-shadow: none !important;
    }

    .multi-select__value-container {
      max-height: 64px;
      overflow-y: auto;
    }
  }

  &:active {
    &>div {
      box-shadow: none !important;
      border-color: $light_grey !important;
    }
  }
}
