@use "ui-kit-old" as *;

.container {
  min-width: 165px;
  width: auto;

  &.maxWidth {
    width: 100%;
    max-width: 100%;
  }

  &.w-1\/2 {
    max-width: 50%;
  }

  &.w-1\/4 {
    max-width: 25%;
  }

  .title {
    display: block;
    font-size: 13px;
    font-weight: 600;
  }

  .selectedOptions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 10px;

    .miniPill {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: auto;
      margin-right: 8px;
      margin-bottom: 4px;
      justify-content: space-between;
      background: $activeBlue;
      color: $white;
      border-radius: 15px;
      font-size: 14px;
      height: 20px;
      padding: 0 8px;

      &:hover {
        cursor:  default;
        background: $activeBlueLight;
      }

      span {
        margin-top: -2px;
        font-weight: 400;
        color: $white;
      }

      .closeButton {
        margin-top: -3px;
        margin-left: 8px;
        font-weight: bold;
        cursor: pointer;

        svg {
          height: 8px;

          path {
            fill: $white;
          }
        }
      }
    }
  }

  .dropdown {
    display: block;
    margin-top: 8px;
  }

  .noSelectedValues {
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    color: $midGray;
  }

  .addButton {
    margin-top: 7px !important;
  }

  .buttonPlaceholder {
    height: 36px;
  }
}
