@use "colors-legacy" as *;
@use "ui-kit" as *;

.content {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 4px;
  overflow: hidden;
  padding-bottom: 5px;

  >div {
    background: $color_white;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);

    &:first-of-type {
      border-radius: 10px 0 0 10px;
    }

    &:last-of-type {
      border-radius: 0 10px 10px 0;
    }
  }

  .sideMenu {
    height: 100%;
    // border-right: 3px solid $very_light_grey;
    padding: 10px 0 10px;
    position: relative;
    display: table;
    user-select: none;

    .label {
      margin: 10px 0 20px 40px;

      div {
        font-size: 20px;
        font-weight: 600;
        color: $text_color;
      }
    }

    .sideMenuTitle {
      position: absolute;
      height: 40px;
      font-size: 18px;
      padding: 10px;
    }

    .sideMenuContent {
      overflow-y: auto;
      height: calc(100% - 190px);

      &::-webkit-scrollbar-track {
        background: red !important;
      }

      a {
        text-decoration: none;
      }
    }


    .languageSelect {
      width: 100%;
      position: absolute;
      bottom: 0px;
      padding: 10px 25px;

      div {
        font-weight: 500;
      }

      .versionNo {
        margin-top: 10px;
        text-align: center;

        p {
          font-weight: 500;
          font-size: 18px;
          color: $medium_grey;
        }
      }
    }

    .menuItem {
      height: 40px;
      padding: 0 30px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: $medium_light_blue;
      }

      &.active {
        background: $medium_light_blue;

        * {
          font-weight: 500;
        }
      }

      * {
        font-size: 16px;
      }

      &.disabled {
        color: $iconGray;

        >div {
          color: $iconGray;
        }

        cursor: not-allowed !important;

        &:hover {
          background: $white;
        }
      }
    }
  }

  .settingComponentContainer {
    height: 100%;
    overflow: auto;
  }

  .label {
    margin-top: 10px;

    div {
      font-weight: 600;
      font-size: 18px;
    }
  }

  label {
    margin-top: 5px;

    div {
      font-weight: 500;
    }
  }
}