@use "shared-legacy" as *;

.row {
  width: 100%;
  display: grid;
  grid-template-columns: 30% 20% auto 25px;
  align-items: center;
  margin: 4px 0px;

  p {
    font-size: 14px !important;
    margin-bottom: 0;

    &:first-of-type {
      font-weight: bold;
    }
  }

  .timePicker {
    > span > a {
      margin-top: -2px;
      margin-right: -3px;
    }
    input {
      width: 60px;
    }
  }

  .minutes {
    display: flex;

    > div {
      width: 30%;
      margin: 0;

      input {
        height: 28px;
        width: 90px;
        padding: 2px 10px;
      }

      > div > div {
        height: 28px;
      }
    }

    span {
      margin-top: 2px;
      margin-left: 8px;
      font-size: 15px;
      color: $text_color_light;
    }
  }

  .clearIcon {
    svg {
      width: 25px;
    }

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
}
