@use "colors-legacy" as *;

.content {
  height: 100%;
  display: grid;
  position: relative;

  .overlaySelect {
    width: 555px;
    min-height: 194px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px -4px $color_black;
    overflow: visible;
    margin: 10px auto !important;
  }

  .overlayManage {
    width: 800px;
    min-height: 617px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px -4px $color_black;
    overflow: visible;
    margin: 10px auto !important;
  }

  .title {
    font-size: 20px;
  }
}

.container {
  display: flex;
  width: 100%;
  margin-top: 115px;
  position: absolute;

  > div {
    text-align: center;

    svg {
      width: 100px;
      height: 100px;
    }

    &.loading {
      > div {
        margin-top: 20px;
        margin-left: 10px;

        > div > div > div {
          background-color: $text_color_light;
        }
      }
    }
  }

  .tableEmpty {
    font-size: 15px;
    font-weight: 500;
    color: #4F5B6D;
    margin-top: 10px;
    margin-left: 25px;
  }
}
