@use "colors-legacy" as *;

.container {
  position: relative;
  width: 500px;
  height: 250px;
  padding: 15px;
  background: white;
  border-radius: 8px;
  box-shadow: $box_shadow;

  div {
    color: $text_color;
  }

  .content {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 3fr 2fr;

    .textContainer {
      grid-row: 2;
      padding: 20px;
      border-radius: 8px;

      * {
        margin-left: 10px;
        font-weight: 500;
        font-size: 18px;
      }
    }

    .closeAlert {
      grid-row: 3;
      display: flex;
      flex-direction: row;
      justify-content: center;
      div {
        font-size: 28px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}