@import '../abstract/colors';
@import '../abstract/sizes';

/* Rules for sizing the icon. */
.material-icons, .material-icons-outlined, .material-icons-round {
  &.md-12 { font-size: #{$md12}px; }
  &.md-14 { font-size: #{$md14}px; }
  &.md-16 { font-size: #{$md16}px; }
  &.md-18 { font-size: #{$md18}px; }
  &.md-24 { font-size: #{$md24}px; }
  &.md-36 { font-size: #{$md36}px; }
  &.md-48 { font-size: #{$md48}px; }
}

.material-icons, .material-icons-outlined, .material-icons-round {
  transition: color .2s; 

  &.md-white {
    color: rgba($white, 1);

    &.md-inactive { 
      color: rgba($white, 0.5); 
    } 
  }

  &.md-dark { 
    color: rgba($dark, 1);
    &.md-inactive { 
      color: rgba($dark, 0.5); 
    } 
  }

  &.md-light { 
    color: $iconGray;
    &.md-inactive {
      color:rgba($iconGray, .3);
    }
  }

  &.md-blue {
    color: $mainBlue;
    &.md-inactive {
      color: rgba($mainBlue, .5);
    }
  }

  &.md-green {
    color: $accentGreen;

    &.md-inactive {
      color: rgba($accentGreen, .5);
    }
  }

  &.md-orange {
    color: $orange;

    &.md-inactive {
      color: rgba($orange, .5);
    }
  }

  &.md-red {
    color: $red;

    &.md-inactive {
      color: rgba($red, .5);
    }
  }
}
