@use "ui-kit-old" as *;

.buttonBasic {
  box-sizing: border-box;

  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: $lightGrayThree;

  background-color: $gray;

  box-shadow: 0px 1px 2px rgba($black, 0);

  color: $darkBlue;
  font-size: 12px;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: color .2s, background-color .2s, box-shadow .2s, border-color .2s;

  // Sizes
  &.sizeSmall {
    padding: 0px 8px;
    margin: 0;
    height: 20px;

    .icon {
      height: 8px;
      width: auto;
    }

    .loaderDotClass {
      height: 7px;
      width: 7px;
    }
  }
  &.sizeMedium {}
  &.sizeLarge {}


  // Colors
  &.colorDefault {
    background-color: $gray;
    box-shadow: 0px 1px 2px rgba($black, 0);
    border-color: $lightGrayThree;
    color: $darkBlue;
    cursor: pointer;

    .icon {
      * {
        fill: $hoverBlue;
      }
    }

    &.disabled {
      color: $blueMist;
      pointer-events: none;
      cursor: not-allowed;

      .icon {
        * {
          fill: $blueMist;
        }
      }
    }

    &:hover {
      background-color: $white;
      box-shadow: 0px 1px 2px rgba($black, .16);
    }

    &:active {
      color: $white;
      background-color: $hoverBlue;
      box-shadow: 0px 1px 2px rgba($black, 0);

      .icon {
        * {
          fill: $white;
        }
      }
    }
  }

  &.colorRed {
    background-color: $red;
    box-shadow: 0px 1px 2px rgba($black, 0);
    border-color: $red;
    color: $white;
    cursor: pointer;

    .icon {
      * {
        fill: $white;
      }
    }

    &.disabled {
      color: $blueMist;
      pointer-events: none;
      cursor: not-allowed;

      .icon {
        * {
          fill: $blueMist;
        }
      }
    }

    &:hover {
      border-color: darken($red, 15);
      background-color: darken($red, 15);
      box-shadow: 0px 1px 2px rgba($black, .16);
    }

    &:active {
      color: $white;
      border-color: lighten($red, 15);
      background-color: lighten($red, 15);
      box-shadow: 0px 1px 2px rgba($black, 0);

      .icon {
        * {
          fill: $white;
        }
      }
    }
  }

  // Icons
  .icon {
    width: auto;
    margin-right: 4px;
    &.plus {}
  }
}