.timePicker {
  display: inline-block;
  position: relative;
  width: 120px;
  overflow: hidden;

  &.time input {
    padding: 0 30px 0 0;
    display: block;
  }

  &.disabled {
    input {
      background-color: #f9fafb;
      color: #a6acb5;
    }
    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  input {
    font-family: "Roboto";
    font-style: normal;
    width: 100%;
    padding: 4px 21px 4px 10px;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    background-image: url("./time-icon.svg");
  }

  &.left {
    input::-webkit-calendar-picker-indicator {
      color: rgba(0, 0, 0, 0);
      opacity: 1;
      display: block;
      background-repeat: no-repeat;
      border-width: thin;
    }
    input::-webkit-calendar-picker-indicator {
      position: absolute;
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
      left: 0%;
    }

    input::-webkit-datetime-edit-fields-wrapper {
      position: relative;
      left: 10%;
    }

    input[type="time"]::-webkit-datetime-edit {
      position: relative;
      right: -30%;
    }
  }
}
