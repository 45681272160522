@use "./colors-old" as old;

@mixin resetMarginPadding {
  margin: 0;
  padding: 0;
}

@mixin resetMP {
  margin: 0;
  padding: 0;
}

@mixin pulseBgAnim($startColor, $endColor, $lightAlpha, $darkAlpha, $bigScale, $smallScale) {
  @keyframes blinkBg {
    0% {
      background-color: rgba($startColor, $lightAlpha);
      transform: scale($smallScale);
    }
    15% {
      background-color: rgba($endColor, $darkAlpha);
      transform: scale($bigScale);
    }

    30% {
      background-color: rgba($endColor, $lightAlpha);
      transform: scale($smallScale);
    }

    45% {
      background-color: rgba($endColor, $darkAlpha);
      transform: scale($bigScale);
    }

    100% {
      background-color: rgba($endColor, $lightAlpha);
      transform: scale(1);
    }
  }
}

@mixin getColors($platform: club) {
  @if $platform == club {
    $lightBlue: old.$lightBlue;
    $midBlue: old.$midBlue;
    $blue: old.$blue;
    $silverBlue: old.$silverBlue;
    $blueMist: old.$blueMist;
    $darkBlue: old.$darkBlue;

    $activeBlue: old.$activeBlue;
    $hoverBlue: old.$hoverBlue;

    $green: old.$green;
    $easyGreen: old.$easyGreen;
    $lightGreen: old.$lightGreen;

    $gray: old.$gray;
    $lightGray: old.$lightGray;
    $lightGrayTwo: old.$lightGrayTwo;
    $lightGrayThree: old.$lightGrayThree;
    $midGray: old.$midGray;
    $yellow: old.$yellow;

    $lightRed: old.$lightRed;
    $red: old.$red;
    $hoverRed: old.$hoverRed;
    $orange: old.$red;
  } @else if $platform == webApp {
    $lightBlue: old.$lightBlue;
    $midBlue: old.$midBlue;
    $blue: old.$blueWebApp;
    $silverBlue: old.$silverBlue;
    $blueMist: old.$blueMist;
    $darkBlue: old.$darkBlue;

    $activeBlue: old.$activeBlueWebApp;
    $hoverBlue: old.$hoverBlueWebApp;

    $green: old.$green;
    $easyGreen: old.$easyGreen;
    $lightGreen: old.$lightGreen;

    $gray: old.$grayWebApp;
    $lightGray: old.$lightGray;
    $lightGrayTwo: old.$lightGrayTwo;
    $lightGrayThree: old.$lightGrayThree;
    $midGray: old.$midGray;
    $yellow: old.$yellow;

    $lightRed: old.$lightRed;
    $red: old.$red;
    $hoverRed: old.$hoverRed;
    $orange: old.$red;
  }
}
