@use "../abstract/colors" as *;

.ss-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1ch;

  label {
    cursor: inherit;
  }

  .radioStyle {
    appearance: none;
    -webkit-appearance: none;
    width: 1.125em;
    height: 1.125em;
    border: 3px solid $white;
    border-radius: 50%;
    box-shadow: 0 0 0 1px currentColor;
    background-color: transparent;
    margin: 1px;
    cursor: pointer;
    flex-shrink: 0;
    flex-grow: 0;

    & + label {
      cursor: pointer;
    }

    &:checked {
      background-color: currentColor;
    }

    &:checked + label {
      font-weight: 400;
    }

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      opacity: 0.4;

      &:hover {
        cursor: initial;
      }
    }

    &:disabled + label {
      opacity: 0.4;
    }
  }

  &.highlight-selected {
    > input[type="radio"]:checked + label {
      font-weight: 500;
    }
  }

  input[type="radio"] {
    @extend .radioStyle;
  }
}
