@use "colors-legacy" as *;

.container {
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &.open {
    border: 1px solid $light_grey;
    background: lighten($light_grey, 20);
  }

  &:hover {
    .selectContainer {
      .timeContent {
        div {
          color: $text_color;
        }
      }
    }
  }

  .textContainer {
    height: 100%;
  }

  .selectContainer {
    position: absolute;
    left: -1px;
    right: -1px;
    top: 100%;
    height: calc(4 * 26px + 2px);
    background: white;
    border: 1px solid $light_grey;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    cursor: default;
    z-index: 100;

    .selectContent {
      height: 100%;

      .timeContent {
        width: 50%;
        height: 100%;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center;
        padding: 10px 30px 10px 15px;
        font-size: 20px;

        &.left {
          padding: 10px 15px 10px 30px;
        }

        .chevron {
          padding: 5px;
          cursor: pointer;

          &:hover {
            path {
              color: $medium_blue;
            }
          }
        }

        input {
          text-align: center;
          border: none;
          color: $text_color !important;
        }
      }
    }
  }
}