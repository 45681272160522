@use "ui-kit-old" as *;

.container {
  position: absolute;
  top: -5px;
  left: 0;
  transform: translateY(100%);
  max-height: 0;

  opacity: 0;

  pointer-events: none;

  transition: opacity .2s;
  background-color: $white;

  border-radius: 3px;
  box-shadow: $hardBoxShadow;

  min-width: 470px;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "header"
    "tabs"
    "results";
    grid-column-gap: 0px;
    grid-row-gap: 0px;

  max-height: 400px;

  overflow: hidden;

  &.growsUps {
    grid-template-areas:
    "results"
    "tabs"
    "header";
  }

  &.isFixed {
    position: fixed;
    transform: translateY(0);
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
  }


  &.active {
    max-height: none;
    pointer-events: all;
    opacity: 1;

    z-index: 199;
  }

  &.parentWidth {
    right: 0;
    min-width: none;
  }

  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .header {
    grid-area: header;
    width: 100%;

    background-color: $darkBlue;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row wrap;

    padding: 12px 10px;
    box-sizing: border-box;

    .iconTitleContainer {
      flex: 0 0 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: row nowrap;
      margin-bottom: 10px;

      .headerIcon {
        flex: 0 0 auto;
        margin-right: 8px;
      }
      .headerTitle {
        flex: 0 0 auto;
        color: $white;
        font-size: 13px;
        margin: 0;
        padding: 0;
        padding-right: 20px;
        display: inline-block;
      }
    }
    .inputAndButtonWrapper {
      flex: 0 0 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .input {
        flex: 0 0 100%;
        margin: 0;
      }

      &.manual {
        .input {
          flex: 1 1 100%;
          margin: 5px;
        }
        .button {
          flex: 0 0 auto;
          height: 28px;
          margin: 0;
          margin-left: 10px;
        }
      }
    }
  }

  .tabsContainer {
    grid-area: tabs;

    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .tab {
      flex: 1 0 33%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;

      user-select: none;

      .tabTitle {
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-weight: 400;
        color: $blueMist;

        transition: color .2s, font-weight .2s;
      }

      .activeIndicator {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        background-color: rgba($blue, 0);
        transition: background-color .2s;
      }

      &:hover {
        cursor: pointer;

        .activeIndicator {
          background-color: rgba($blue, .5);
        }
      }

      &.active {
        .tabTitle {
          font-weight: 600;
          color: $mainTextColor;
        }
        .activeIndicator {
          background-color: rgba($blue, 1);
        }
      }
    }
  }


  .results {
    width: 100%;
    grid-area: results;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    overflow: hidden;

    max-height: 300px;

    position: relative;

    .noResultsContainer {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin: 0;
        padding: 0;
      }
    }

    .searchingContainer {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .resultsWrapper {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .playerRow {
        flex: 0 0 70px;
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: rgba($white, 1);
        padding: 0 10px;

        transition: background-color .2s;
        user-select: none;

        .infoWrapper {
          flex: 1 1 auto;
          p {
            padding: 0;
            margin: 0;
            font-size: 15px;
          }
        }

        .playerAddButton {
          flex: 0 0 auto;
          margin-left: auto;
        }

        &:hover, &.selectedElement {
          background-color: rgba($lightBlue, 1);
          p {
            font-weight: 700;
          }
        }

      }
    }
  }
}
