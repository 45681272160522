@use "ui-kit-old" as *;

.cross {
  width: 14px;
  height: 14px;
  overflow: visible;

  transform: scale(1);
  transition: transform .2s;

  path {
    fill: $white;
    color: $white;
  }

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}

.crossFont {
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center; 
  overflow: visible;
  box-sizing: border-box;
  font-size: 1em;
  height: 1em;
  width: 1em;
  
  transform: scale(1);
  transform-origin: center;  
  transition: transform .1s;

  -webkit-user-select: none;
  user-select: none;

  &:hover {
    transform: scale(1.1);
  }
  &:active {
    background-color: currentColor;
    background-color: rgba($gray, 0.2);
    transform: scale(1);
  }
}