@use "colors-legacy" as *;

.container {
  display: flex;
  flex-direction: column;

  &.selected {
    .content {
      &:last-of-type {
        padding-left: 4px;
      }
    }
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 20px;

    &:last-of-type {
      justify-content: flex-start;
      padding-left: 7px;
    }

    .slot {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: white;
      margin: 0 2px;

      &.openSlot {
        box-shadow: 0px 0px 3px #0000006b;
      }

      &.playerType {
        display: block;
        background: transparent;

        > img {
          margin-bottom: 13px;
        }
      }
    }
  }
}
