@use "colors-legacy" as *;

$border_color: $very_light_green;
$header_size: 55px;

.container {
  position: relative;
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  grid-template-rows: 1fr auto;

  &.nonEditable {
    .body {
      pointer-events: none;
    }
  }

  &.fullHeight {
    height: 100%;
    overflow: hidden;
  }

  .checkboxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;

    .checkboxHeader {
      background: $very_light_grey;
      height: 55px;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .checkboxBody {
      overflow: hidden;

      // &.fullHeight {
      //   padding-bottom: 100px;
      // }

      .checkboxRow {
        height: 45px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0 10px;
        min-height: 45px;

        &:nth-child(even) {
          background: $very_light_grey;
        }

        &.hover,
        &:hover {
          background: $medium_light_blue;
        }
      }
    }
  }

  .table {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;

    .headerContent {
      color: $medium_grey;
      background: $very_light_grey;
      height: 55px;
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: grid;
      padding: 0 20px;

      .headerElement {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 5px;
        font-size: 15px;
        color: transparentize($color-black, 0.71);
        white-space: initial;
        line-height: 17px;

        div {
          color: $text_color;
          font-weight: 600;
        }

        path {
          color: $text_color;
        }
      }

      &.hideHeader {
        .headerElement {
          display: none;
        }
      }

      &.hideArrows {
        .arrowContainer {
          display: none;
        }
      }

      &.boldHeaders {
        .headerElement {
          div {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 5px;
          }
        }
      }

      .arrowContainer {
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-left: 5px;

        span {
          margin-bottom: 4px;
        }
      }

      .arrowContainer {
        margin-top: 5px;
        margin-left: 5px;
      }
    }

    .body {
      position: relative;
      background: white;
      height: 100%;

      &.fullHeight {
        overflow-y: hidden;
        // padding-bottom: 100px;
      }

      .row {
        &:nth-child(odd) {
          &.selectedRow {
            &>div {
              background: $medium_light_blue;
            }
          }

          &:hover {
            &>div {
              background: $medium_light_blue;
            }
          }
        }

        &:nth-child(even) {
          &.selectedRow {
            &>div {
              background: $medium_light_blue;
            }
          }

          &>div {
            background: $very_light_grey;
          }

          &:hover {
            &>div {
              background: $medium_light_blue;
            }
          }
        }

        &.hover {
          &>div {
            background: $medium_light_blue;
          }
        }

        .rowContent {
          height: 45px;
          display: grid;
          padding: 6px 20px;
          min-height: 45px;
        }
      }

      &.hideOnHoverEffect {
        pointer-events: none;
      }

      .cell {
        cursor: pointer;
        padding: 0;
        align-self: center;

        &>div {
          padding: 5px;
        }
      }

      &.noRowClick {
        .cell {
          cursor: auto;
        }
      }

      .doubleCell {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        padding: 2px 5px;

        >div {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          padding: 0 6px;
        }
      }
    }
  }

  .footer {
    grid-column-start: 1;
    grid-column-end: 3;
    height: 30px;
    background: $very_light_grey;
    margin-top: 10px;
    padding: 0 15px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;

    .footerElement {
      position: relative;
      margin-right: 30px;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      div {
        color: $medium_grey;
      }

      &:not(:last-of-type) {
        &:after {
          content: '';
          position: absolute;
          top: 6px;
          bottom: 6px;
          right: -15px;
          border-right: 1px solid white;
        }
      }
    }
  }

  .verticalScrollbar {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 3;
  }

  .horizontalScrollbar {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

//Firefox off-sets rows
/*
@-moz-document url-prefix() {
  .checkboxRow {
      height: 45.5px !important;
  }
  .rowContent {
    height: 45px;
  }
}
*/