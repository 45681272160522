@use "ui-kit-old" as *;

.content {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  text-align: center;
  margin: 10px 10px 20px 10px;
  user-select: none;

  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 350px;
    padding: 2px;
    border-radius: 5px;

    span {
      font-size: 17px;
      font-weight: 500;
      color: $midGray;
      cursor: pointer;
      margin: 0 2px;
      padding: 0 2px;

      path {      
        color: $blue;
      }
  
      &.disabled {
        cursor: default;
        path {      
          color: transparentize($midGray, .25);
        }
      }
  
      &.active {
        color: white;
        background: $activeBlue;
        border-radius: 4px;
        padding: 0 7px;
      }
    }
  
    svg {
      margin: 0 7px;
    }
  

  }

  .viewRange {
    margin-top: 5px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-content: center;

    div {
      font-weight: 500;
      color: $midGray;
    }

    .number {
      font-weight: 500;
    }
  }
}