@use "colors-legacy" as *;

.settings {
  border-right: 2px solid $light_grey;
  font-size: 15px;

  .title {
    padding: 20px 15px 10px;

    div {
      font-size: 20px;
      font-weight: 600;
      color: $text_color;
    }
  }

  .form {
    .formField {
      border-top: 2px solid $light_grey;
      padding: 10px 15px;
      position: relative;

      > div:last-of-type {
        margin-bottom: 0;
      }

      .header {
        label {
          > div {
            top: 3px;

            > div {
              top: -12px;
            }
          }
        }
      }

      label {
        margin-top: 0;
        font-size: 15px;
      }

      .headerColor {
        width: 50%;
        display: flex;
        height: 28px;
        border: 1px solid $light_grey;
        border-radius: 4px;
        cursor: pointer;

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          height: 100%;
          font-size: 15px;

          &:last-of-type {
            border-radius: 0 4px 4px 0;
          }
        }
      }

      .colorPicker {
        position: absolute;
        z-index: 100;
      }

      .colorPad {
        position: absolute;
        left: 95px;
        top: 40px;

        > div {
          width: 80px;
          height: 20px;
          border: 1px solid $input_field_gray;
          margin-bottom: 8px;
          border-radius: 4px;
        }
      }

      .urlField {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        > div {
          margin-bottom: 0;

          > div:last-of-type {
            box-shadow: none;
            left: -5px;
            bottom: 0;
            padding: 0 5px;
          }

          input {
            padding: 0 5px;
          }
        }
      }
    }
  }
}
