@use "ui-kit-old" as *;
@use "ui-kit" as *;

.spacesContainer {
  flex: 1 1 100%;
  width: 100%;
  border-top: 1px solid $strokeGray;
}
.centeredContainer {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}