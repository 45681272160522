@use "ui-kit-old" as *;
@use "component-classes/formElements" as *;


.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .dropdownIcon {
    width: 9px;
    height: auto;
    * {
      fill: $blueMist;
    }
  }
}