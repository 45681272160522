@use "ui-kit-old" as *;

.label {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;

  margin: 0;
  padding: 0;
  flex: 0 0 auto;

  margin-top: 0!important;

  height: auto;
  color: $darkBlue;
  cursor: inherit;

  &.lightColor {
    color: $midGray;
  }

  span {
    font-weight: 400;
  }
}
.labelPointerHover {
  &:hover {
    cursor: pointer;
  }
}

.labelMargin {
  margin-bottom: 4px;
}

.labelSecondary {
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: $blueMist;
  margin-bottom: 4px;
}

.labelSecondaryDark {
  font-size: 14px;
  line-height: 15px;
  font-weight: 400;
  color: $darkBlue;
}

.labelNormal {
  font-weight: 400;
}
.labelDisabled {
  color: $blueMist;
  &:hover {
    cursor: not-allowed;
  }
}

