@use "colors-legacy" as *;

.wrapper {
  border-radius: 4px;
  padding: 25px;
  padding-top: 10px;

  div {
    margin-top: 0;
  }
  form {
    margin-top: 30px;
  }
}

.formControl {
  max-width: 70%;
}

.formButtons {
  width: 20% !important;
  margin: 40px 20px 0 0;
}

.label {
  display: inline;
  div {
    padding: 5px 0 5px 0;
    font-size: 18px;
    font-weight: 600;
  }
}

.formLabel {

}
