@use "ui-kit-old" as *;

#ellipsed-tooltip {
  .tooltip-inner {
    background: $darkBlue !important;
  }

  .arrow::before {
    border-bottom-color: $darkBlue !important;
    border-top-color: $darkBlue !important;
  }
}

#booking-modal-ellipsed-text {
  top: 50px !important;

  .tooltip-inner {
    max-width: unset;
    background: $darkBlue !important;
  }

  .arrow::before {
    border-bottom-color: $darkBlue !important;
    border-top-color: $darkBlue !important;
  }
}

#ellipsed-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 500;
}
