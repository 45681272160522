@use "ui-kit-old" as *;

.componentContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  min-height: 18px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.radioContainer {
  position: relative;
  margin-right: 9px;
  overflow: hidden;
  width: 15px;
  height: 15px;
  flex: 0 0 15px;

  border: 1px solid $blue;
  border-color: rgba($blue, 1);
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: border-color 0.2s;

  cursor: pointer;

  &:hover {
    border-color: rgba($blue, 0.8);
  }
}
.radioContainerChecked {
}
.radioContainerDisabled {
  border-color: rgba($blueMist, 1);
  cursor: not-allowed;

  &:hover {
    border-color: rgba($blueMist, 1);
  }
}

.innerCircle {
  width: 7px;
  height: 7px;
  background-color: $blue;
  opacity: 0;
  border-radius: 50%;

  transition: opacity 0.2s;
}
.innerCircleVisible {
  opacity: 1;
}
.innerCircleDisabled {
  background-color: $blueMist;
}

.radioInput {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 0px;
  height: 0px;
}

.textInputWrapper {
  flex: 0 0 25%;
  opacity: 0;
  margin-left: auto;
  position: relative;
  height: 18px;
  width: 50%;
  transition: opacity 0.2s;

  input {
    z-index: 10;
  }
}
.textInputWrapperVisible {
  opacity: 1;
}

div.inputFieldClassName {
  margin-bottom: 0;
  position: absolute;
  right: 0;
  bottom: -5px;
  width: 100%;
}
