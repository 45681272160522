@use "colors-legacy" as *;

.toggleSwitch {
  position: relative;
  display: block;
  width: 52px;
  height: 22px;
  color: white;
  font-size: 14px;
  font-weight: 200;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $medium_green;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    border-radius: 6px;
  }

  .slider:before {
    position: absolute;
    cursor: pointer;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 4px;
  }

  input:checked + .slider {
    background-color: $slightly_light_blue;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $slightly_light_blue;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  img {
    width: 15px;
    height: auto;
  }

  .textChecked,
  .textUnchecked {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    transition: opacity 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 11px;

    div,
    path {
      text-align: center;
      color: white;
    }
  }

  .textChecked {
    left: 5px;
    right: 26px;
  }

  .textUnchecked {
    left: 26px;
    right: 53px;
  }

  input:checked ~ .textUnchecked {
    opacity: 0;
  }

  input:not(:checked) ~ .textChecked {
    opacity: 0;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.toggleSwitch.medium {
  width: 65px;

  input:checked + .slider:before {
    -webkit-transform: translateX(41px);
    -ms-transform: translateX(41px);
    transform: translateX(41px);
  }

  .slider {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .slider:before {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}

.toggleSwitch.small {
  width: 36px;
  height: 18px;

  input:checked + .slider:before {
    -webkit-transform: translateX(px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  span::before {
    width: 14px;
    height: 14px;
  }

  .slider {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .slider:before {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}

.toggleSwitch.large {
  width: 90px;

  input:checked + .slider:before {
    -webkit-transform: translateX(66px);
    -ms-transform: translateX(66px);
    transform: translateX(66px);
  }

  .slider {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .slider:before {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}
