@use "ui-kit-old" as *;

.wrapper {
  width: 100%;
  flex: 0 0 100%;
  
  height: auto;
  scrollbar-width: none;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;

  &::-webkit-scrollbar { width: 0 !important; height: 0 !important;}
}

.row {
  flex: 1 0 40px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;

  background-color: $white;
  padding: 0;
  box-sizing: border-box;

  position: relative;

  transition: background-color .2s;

  &.headerRow {
    flex: 0 0 32px;
    height: 32px;
    background-color: $gray;

    .right {
      &.isOverflown {
        &:before {
          background: linear-gradient(to right, rgba($darkBlue, 0) 0%, rgba($darkBlue, 1) 100%);
        }
      }
    }
    .left {
      &.isOverflown {
        &:after {
          background: linear-gradient(to right, rgba($darkBlue, 1) 0%, rgba($darkBlue, 0) 100%);
        }
      }
    }
  }

  &.subHeaderRow {
    flex: 0 0 23px;
    height: 23px;
    background-color: $gray;
  }

  &.bodyRow {
    &:hover {
      background-color: $lightBlue;

      .isOverflown {
        &:before {
          background: linear-gradient(to right, rgba($lightBlue, 0) 0%, rgba($lightBlue, 1) 100%);
        }
        &:after {
          background: linear-gradient(to right, rgba($lightBlue, 1) 0%, rgba($lightBlue, 0) 100%);
        }
      }
    }
  }

  .left {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;

    padding-left: 10px;
    padding-right: 10px;

    position: relative;

    height: auto;

    &.isOverflown {
      &:after {
        position: absolute;
        top: 0px;
        right: 0;
        bottom: 0px;
        transform: translateX(100%);
        content: '';
        width: 10px;
        background: linear-gradient(to right, rgba($white, 1) 0%, rgba($white, 0) 100%);
        transition: background .2s;
      }
    }
  }
  .right {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-flow: row nowrap;

    padding-right: 0px;
    padding-left: 10px;
    position: relative;

    height: auto;

    // &.isOverflown {
    //   &:before {
    //     position: absolute;
    //     top: 0px;
    //     left: 0;
    //     bottom: 0px;
    //     transform: translateX(-100%);
    //     content: '';
    //     width: 10px;
    //     background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 1) 100%);
    //     transition: background .2s;
    //   }
    // }
  }
  .body {
    padding-left: 11px;
    padding-right: 11px;
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;

    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar { width: 0 !important; height: 0 !important;}

    height: auto;

    &.isOverflown {
      // padding-left: 0;
      // padding-right: 0;
    }
  }

  .rowItem {
    padding: 0;
    margin: 0;
    padding-right: 10px;
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    align-items: center;
    justify-content: flex-start;

    .toggle path {
      fill: $darkBlue;
    }
  }

  p.headerItem, span.headerItem {
    font-size: 12px;
    font-weight: 500;
    color: $darkBlue;
  }

  .bodyItem {
    padding-right: 10px;
    
    > p, > span, > p > p, > span > span {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 14px;
      color: $darkBlue;
      padding-right: 10px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  p.bodyItem, span.headerItem {
    margin: 0;
    padding: 0;
    padding-right: 10px;
    font-weight: 500;
    font-size: 14px;
    color: $darkBlue;
  }
}