@use "colors-legacy" as *;

.header {
  padding: 12px 15px;
  position: relative;
  background: $text_color;
  border-radius: 10px 10px 0 0;

  > div {
    font-weight: 500;
    color: $color_white;
  }

  .closeIcon {
    position: absolute;
    right: 15px;
    top: 17px;
    cursor: pointer;

    path {
      fill: $color_white;
    }
  }
}

.body {
  padding: 10px 15px 0;

  .label * {
    font-size: 13px;
  }

  input {
    padding: 0.25rem 0.75rem !important;
  }
}

.footer {
  padding: 0 15px 15px;

  .buttons {
    display: flex;
    justify-content: space-between;

    button {
      font-size: 15px;
      width: 120px;

      &.draft {
        margin-left: auto;
      }
    }
  }

  .schedule {
    display: flex;
    margin: 15px 0;

    * {
      font-size: 15px;
    }

    input::placeholder {
      font-size: 13px;
    }

    > div {
      display: inline-flex;
      margin-right: 10px;
      width: unset;
    }

    .date {
      width: 125px;

      > div {
        margin-bottom: 0;

        > div:first-of-type > div:last-of-type {
          bottom: 35px;
          transform: unset;
        }
      }
    }

    .time {
      width: 75px;

      > div {
        margin-bottom: 0;
      }
    }

    .publish {
      margin-left: auto;
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-top: 3px;
        margin-right: 8px;
        width: 12px;
        height: 12px;

        path {
          fill: $color_white;
        }
      }
    }
  }
}
