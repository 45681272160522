@use "ui-kit-old" as *;

.container {
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;

  background-color: $lightGrayTwo;

  border-radius: 7px;

  display: grid;

  grid-template-columns: 100%;
  grid-template-rows: auto 1fr 73px;
  grid-template-areas:
    "header"
    "inner"
    "footer";

  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow: hidden;

  transform: scale(1);
  opacity: 1;

  transition: transform 0.5s, opacity 0.5s;
  transform-origin: top right;
}

.header {
  padding-top: 10px;
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  flex-flow: row wrap;
  z-index: 50;

  width: 100%;
  height: auto;

  padding: 5px 0;
  box-sizing: border-box;

  min-height: 37px;

  background-color: $activeBlue;

  .closeIcon {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .topRow {
    padding-left: 16px;
    padding-right: 47px;
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .printButton {
      margin: 5px 0 0 auto;
    }
  }
}

.inner {
  grid-area: inner;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;

  overflow: auto;

  padding-bottom: 50px;
  box-sizing: border-box;

  .historicalBookingsRow {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
  }

  .noActiveBooking {
    height: 100%;
    justify-content: center;
  }

  .singleBookingContainer {
    width: 100%;
    height: auto;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column nowrap;

    border-radius: 7.5px;
    margin-top: 3px;
    margin-bottom: 5px;
    box-sizing: border-box;
    box-shadow: $boxShadow;

    .topRow {
      background-color: $darkBlue;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      width: 100%;
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 5px 16px;

      .bookingName {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        padding: 0;
        margin-right: 15px;
        color: $white;
      }

      .partnershipSelect {
        height: auto;
        margin-right: 15px;

        button {
          margin: 0;
        }
      }

      .timer {
        font-size: 16px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;

        opacity: 0;

        transition: opacity 0.2s;

        color: $white;

        margin-bottom: 0;

        @include touch {
          order: 4;
        }

        .timerIcon {
          flex: 0 0 15px;
          width: 15px;
          height: 15px;
          margin-right: 7px;
        }

        &.visible {
          opacity: 1;
        }
      }

      .statusContainer {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;

        .statusBox {
          margin-left: 10px;
          height: 24px;
          background-color: darken($darkBlue, 8);
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;

          .statusDot {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            margin-right: 5px;

            &.green {
              background-color: $green;
            }

            &.orange {
              background-color: $orange;
            }
          }

          .statusText {
            font-size: 14px;
            font-weight: 400;
            margin: 0;
            padding: 0;
            padding-bottom: 1px;
            color: $white;
          }
        }
      }

      .bell {
        width: 30px;
        height: 30px;
        background: $darkBlue;
        border-radius: 5px;
        box-shadow: $hardBoxShadow;
        margin-left: 15px;
        cursor: pointer;
      }
    }

    .actionsRowsOne {
      width: 100%;
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 21px;
      background-color: $white;
      border-top: 1px solid $lightBorder;
      border-bottom: 1px solid $lightBorder;
      position: relative;

      .playerControls {
        flex: 0 0 auto;
      }

      .bookingTotal {
        cursor: pointer;

        margin: 0;
        padding: 0;
        padding-right: 27px;

        margin-left: auto;
        font-weight: 500;
        font-size: 15px;
        color: $darkBlue;

        span {
          font-weight: 700;
        }
      }
    }

    .bookingFold {
      width: 100%;
      height: 0;
      pointer-events: none;
      opacity: 0;

      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-flow: row nowrap;

      transform: scaleY(0);
      transform-origin: top;

      transition: height 0.2s, transform 0.2s, opacity 0.2s;

      &.open {
        height: 300px;
        opacity: 1;
        pointer-events: all;
        transform: scaleY(1);
      }

      .paymentsBox {
        flex: 0 1 50%;
        height: 100%;
        margin-right: 1px;
      }
    }

    .bookingFooter {
      padding: 0 21px;
      height: 40px;
      flex: 0 0 40px;
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row wrap;

      background-color: $white;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top: none;

      position: relative;

      .leftContainer,
      .centerContainer,
      .rightContainer {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .leftContainer {
        margin-right: auto;
      }

      .rightContainer {
        position: absolute;
        top: 0;
        right: 15px;
        bottom: 0;
      }

      .openBookingWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -7px;

        cursor: pointer;

        .text {
          margin: 0;
          padding: 0;
          color: $darkBlue;
          font-size: 14px;
          font-weight: 600;
        }

        .icon {
          width: 10px;
          height: 10px;
          margin-top: 1px;
          margin-right: 11px;

          path {
            fill: $darkBlue;
          }

          transform: rotate(-90deg);

          transition: transform 0.2s;

          &.open {
            transform: rotate(-180deg);
          }
        }
      }

      .abortButton {
        flex: 0 0 auto;
        margin-left: 15px;
      }

      .confirmButton {
        flex: 0 0 auto;
        margin-left: 15px;
      }
    }

    &.isCancelled {
      .topRow {
        background-color: $gray;

        .bookingName {
          color: $darkBlue;
        }
      }

      .statusContainer {
        .statusBox {
          background-color: $white;

          .statusText {
            color: $darkBlue;
          }
        }
      }
    }
  }

  .noBookingsContainer {
    width: 100%;
    height: 100%;
    grid-area: bottom;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .noBookingsIcon {
      margin-bottom: 37px;
    }

    .noBookingsTitle {
      color: $blueMist;
      font-size: 34px;
      font-weight: 400;
    }
  }
}

.footer {
  grid-area: footer;
  margin-top: 1px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;

  background-color: $activeBlue;

  border-top: 1px solid lighten($silverBlue, 10);

  .newBooking {
    padding: 5px;
    margin-right: 40px;
  }
}

.loaderContainer {
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: $semiBold;

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
      padding-top: 15px;
    }
  }

  .noCancels {
    align-self: flex-start;
  }
}

@keyframes blinking {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.notesBox {
  flex: 0 0 50%;
  height: 100% !important;
  max-height: 300px;
}