@use "ui-kit-old" as old;
@use "ui-kit" as *;

.dropdownDoubleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.separator {
  width: 1px;
  background-color: rgba($white, 0);

  transition:
    color 0.2s,
    background-color 0.2s;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $white;

  border-width: 1px;
  border-style: solid;
  border-color: $white;
  border-radius: 5px;

  width: auto;
  padding: 0 20px;
  height: 24px;

  margin: 10px 0;

  padding-bottom: 4px;

  color: $black;
  font-size: 16px;
  font-weight: 500;

  box-shadow: none;
  outline: none;

  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  white-space: nowrap !important;
  box-sizing: border-box;

  &.smallSize {
    padding-bottom: 0;
  }

  &.hasDropdown {
    padding-right: 30px;
  }

  transition:
    background-color 0.2s,
    border-color 0.2s,
    color 0.2s;

  .dropdownCaret {
    position: absolute;
    top: 50%;
    transform: translateY(-30%);
    right: 10px;
    // margin-left: 7px;
  }

  &:hover {
    cursor: pointer;
  }
}

.dropdownContainer {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 150px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  box-sizing: border-box;

  z-index: 999;
  background-color: $white;
  border-radius: 5px;
  box-shadow: old.$hardBoxShadow;

  border: 1px solid old.$lightBorder;

  opacity: 0;

  pointer-events: none;

  transition: opacity 0.2s;

  &.dropdownContainerOpen {
    opacity: 1;
    pointer-events: all;
  }

  .searchInput {
    width: 100%;

    > div {
      margin-bottom: 0;
      padding: 5px;

      > div:first-of-type {
        > div:first-of-type {
          background: none;
          padding-right: 0;
          border-color: transparentize($black, 0.7) !important;

          svg path {
            fill: transparentize(old.$darkBlue, 0.3);
          }
        }

        input {
          border-color: transparentize($black, 0.7) !important;
        }
      }
    }
  }

  .dropdownMenu {
    overflow: hidden;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;

    .dropdownItem {
      width: 100%;
      padding: 5px 10px;
      margin: 0;
      text-align: start;
      font-size: 15px;
      font-weight: 400;
      color: old.$darkBlue;

      background-color: $white;

      transition:
        background-color 0.2s,
        font-weight 0.2s;

      &:hover {
        cursor: pointer;
      }

      &.selected {
        font-weight: 700;
      }

      &.focused {
        background-color: lighten($blue, 25);
      }

      &:first-of-type {
        padding-top: 10px;
      }
      &:last-of-type {
        padding-bottom: 10px;
      }

      &:hover {
        background-color: lighten($blue, 25);
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      span {
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin: 0 6px 1px 0;

        &.active {
          background: old.$green;
        }

        &.inActive {
          background: $orange;
        }
      }

      label {
        font-weight: 400;
        font-style: italic;
        margin-left: 10px;
      }
    }
  }
}

.dropdownDouble {
  &:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.dropdownSimple {
  justify-content: space-between;

  &.loading {
    justify-content: center;
  }
}

.selected {
  color: $white !important;
  background-color: old.$activeBlue !important;
  border-color: old.$activeBlue !important;

  &:hover {
    background-color: darken(old.$activeBlue, 10);
    border-color: darken(old.$activeBlue, 10);
  }

  &:active {
    background-color: lighten(old.$activeBlue, 10);
    border-color: lighten(old.$activeBlue, 10);
  }
}

.defaultSize {
  height: 24px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 10px;
  padding-bottom: 0px;

  .dropdownCaret {
    // margin-top: 2px;
    height: auto;
    width: 10px;
  }

  &.rounded {
    border-radius: 12px;
  }

  .spinner {
    width: 15px;
    height: 15px;
  }

  .pulseLoaderClassName {
    min-width: unset;
  }

  .pulseLoaderContentClassName {
    padding: 0 10px;
  }

  .pulseLoaderDot {
    height: 5px;
    width: 5px;
  }
}

.mediumSize {
  height: 28px;
  font-size: 16px;
  padding: 0 12px;

  .dropdownCaret {
    // margin-top: 2px;
    height: auto;
    width: 9px;
  }

  &.rounded {
    border-radius: 14px;
  }

  .spinner {
    width: 17px;
    height: 17px;
  }

  .pulseLoaderDot {
    height: 9px;
    width: 9px;
  }

  .dropdownItem {
    padding: 2.5px 10px;
    font-size: 15px;

    &:first-of-type {
      padding-top: 5px !important;
    }
    &:last-of-type {
      padding-bottom: 5px !important;
    }
  }
}

.largerSize {
  height: 32px;
  font-size: 18px;
  font-weight: 500;

  .dropdownCaret {
    // margin-top: 6px;
  }

  .spinner {
    width: 15px;
    height: 15px;
  }

  .pulseLoaderDot {
    height: 9px;
    width: 9px;
  }
}

.largeSize {
  height: 50px;
  font-size: 19px;
  font-weight: old.$normal;
}

// Default theme
.defaultTheme {
  border-color: $blue !important;
  color: $white !important;
  background-color: $blue !important;

  &.separator {
    background-color: lighten($blue, 5);
  }

  &:hover {
    background-color: darken($blue, 10);
    border-color: darken($blue, 10);
  }

  &:active {
    background-color: lighten($blue, 10);
    border-color: lighten($blue, 10);
  }

  .pulseLoaderDot {
    background-color: $white;
  }
}
.defaultTheme .text {
  &:hover {
    color: $white;
  }
}

// White outline theme
.whiteOutlineTheme {
  border-color: $white !important;
  background-color: transparent !important;
  color: $white !important;

  &.separator {
    background-color: lighten($white, 5);
  }

  .dropdownCaret {
    * {
      color: $white;
      fill: $white;
    }
  }

  &:hover {
    background-color: darken($blue, 10);
    border-color: darken($blue, 10);
    color: $white;

    .spinner {
      stroke: $white;
    }

    .pulseLoaderDot {
      background-color: $white;
    }

    .dropdownCaret {
      * {
        color: $white;
        fill: $white;
      }
    }
  }

  &:active {
    background-color: lighten($blue, 10);
    border-color: lighten($blue, 10);
    color: $white;
  }

  .spinner {
    stroke: $white;
  }
  .pulseLoaderDot {
    background-color: $white;
  }
}
.whiteOutlineTheme .text {
  &:hover {
    color: $blue;
  }
}

// Default theme outline
.defaultThemeOutline {
  border-color: $blue !important;
  background-color: transparent !important;
  color: $blue !important;

  &.separator {
    background-color: lighten($blue, 5);
  }

  .dropdownCaret {
    * {
      color: $blue;
      fill: $blue;
    }
  }

  &:hover {
    background-color: darken($blue, 10);
    border-color: darken($blue, 10);
    color: $white;

    .spinner {
      stroke: $white;
    }

    .pulseLoaderDot {
      background-color: $white;
    }

    .dropdownCaret {
      * {
        color: $white;
        fill: $white;
      }
    }
  }

  &:active {
    background-color: lighten($blue, 10);
    border-color: lighten($blue, 10);
    color: $white;
  }

  .spinner {
    stroke: $blue;
  }
  .pulseLoaderDot {
    background-color: old.$midBlue;
  }
}
.defaultThemeOutline .text {
  &:hover {
    color: $blue;
  }
}

// Main theme outline
.mainThemeOutline {
  border-color: $mainBlue;
  background-color: transparent;
  color: $mainBlue;

  &.separator {
    background-color: lighten($mainBlue, 5);
  }

  .dropdownCaret {
    * {
      color: $mainBlue;
      fill: $mainBlue;
    }
  }

  &:hover {
    background-color: darken($mainBlue, 10);
    border-color: darken($mainBlue, 10);
    color: $white;

    .spinner {
      stroke: $white;
    }

    .pulseLoaderDot {
      background-color: $white;
    }

    .dropdownCaret {
      * {
        color: $white;
        fill: $white;
      }
    }
  }

  &:active {
    background-color: lighten($mainBlue, 10);
    border-color: lighten($mainBlue, 10);
    color: $white;
  }

  .spinner {
    stroke: $blue;
  }
  .pulseLoaderDot {
    background-color: old.$midBlue;
  }
}
.defaultThemeOutline .text {
  &:hover {
    color: $mainBlue;
  }
}

// Black outline theme
.blackOutlineTheme {
  border-color: old.$blueMist !important;
  background-color: transparent !important;
  color: $black !important;

  width: 100%;
  padding: 17px 10px;
  font-size: 18px;
  border: 2px solid $black !important;
  border-radius: 8px;
  margin: 0;

  &.separator {
    background-color: lighten(old.$blueMist, 5);
  }

  .dropdownCaret {
    * {
      color: $black;
      fill: $black;
    }
  }

  &:hover {
    background-color: darken($black, 10);
    border-color: darken($black, 10);
    color: $white;

    .spinner {
      stroke: $white;
    }

    .pulseLoaderDot {
      background-color: $white;
    }

    .dropdownCaret {
      * {
        color: $white;
        fill: $white;
      }
    }
  }

  &:active {
    background-color: lighten(old.$gray, 10);
    border-color: lighten(old.$gray, 10);
    color: $white;
  }

  .spinner {
    stroke: $black;
  }
  .pulseLoaderDot {
    background-color: $black;
  }
}
.blackOutlineTheme .text {
  &:hover {
    color: $white;
  }
}

// Danger theme
.dangerTheme {
  border-color: $orange !important;
  background-color: $orange !important;
  color: $white !important;

  &.separator {
    background-color: darken($orange, 10);
  }

  &:hover {
    background-color: darken($orange, 10);
    border-color: darken($orange, 10);
  }

  &:active {
    background-color: lighten($orange, 10);
    border-color: lighten($orange, 10);
  }
}
.dangerTheme .text {
  &:hover {
    color: $white;
  }
}

// Danger theme outline
.dangerThemeOutline {
  border-color: $orange !important;
  background-color: transparent !important;
  color: $orange !important;

  &.separator {
    background-color: darken($orange, 10);
  }

  &:hover {
    background-color: darken($orange, 10);
    border-color: darken($orange, 10);
    color: $white;
  }

  &:active {
    background-color: lighten($orange, 10);
    border-color: lighten($orange, 10);
    color: $white;
  }
}
.dangerThemeOutline .text {
  &:hover {
    color: $white;
  }
}

// Whitebg gray outline theme
.whiteBgGrayOutline {
  border-color: old.$lightGrayThree !important;
  background-color: $white !important;
  color: old.$darkBlue !important;

  &.separator {
    background-color: old.$lightGrayThree;
  }

  &:hover {
    background-color: darken($white, 10);
    border-color: darken(old.$lightGrayThree, 10);
    color: old.$darkBlue;
  }

  &:active {
    background-color: lighten($white, 10);
    border-color: lighten(old.$lightGrayThree, 10);
    color: old.$darkBlue;
  }
}
.whiteBgGrayOutline .text {
  &:hover {
    color: old.$darkBlue;
  }
}

// Green theme
.greenTheme {
  border-color: old.$green !important;
  background-color: old.$green !important;
  color: $white !important;

  &.separator {
    background-color: darken(old.$green, 10);
  }

  &:hover {
    background-color: darken(old.$green, 10);
    border-color: darken(old.$green, 10);
  }

  &:active {
    background-color: lighten(old.$green, 10);
    border-color: lighten(old.$green, 10);
  }
}
.greenTheme .text {
  &:hover {
    color: $white;
  }
}

// Gray theme
.grayTheme {
  border-color: old.$silverBlue !important;
  background-color: old.$gray !important;
  color: old.$darkBlue !important;

  &.separator {
    background-color: darken(red, 10);
  }

  &:hover {
    background-color: darken($white, 0);
    border-color: darken(old.$blueMist, 0);
  }

  &:active {
    background-color: lighten(old.$activeBlue, 0);
    border-color: lighten(old.$activeBlue, 0);
    color: $white !important;
  }

  .pulseLoaderDot {
    background-color: old.$darkGray;
  }

  .dropdownSeparator::after {
    border-right: 1px solid old.$darkBlue;
  }

  .caretContainer {
    height: 100%;
    border-right: 1px solid old.$silverBlue;
    margin-left: 10px;

    .dropdownCaret {
      * {
        color: old.$darkBlue;
        fill: old.$darkBlue;
      }
    }
  }
}

.grayTheme .text {
  &:hover {
    color: old.$darkBlue;
  }
}

//Light gray outline theme
.lightGrayOutlineTheme {
  border-color: old.$lightGrayTen !important;
  background-color: rgba(old.$lightGrayTen, 0) !important;
  color: $sweetspotBlack !important;

  &.separator {
    background-color: darken(old.$green, 10);
  }

  &:hover {
    background-color: darken(rgba(old.$lightGrayTen, 0.5), 10);
    border-color: darken(old.$lightGrayTen, 10);
    color: $white;
  }

  &:active {
    background-color: lighten(rgba(old.$lightGrayTen, 0.5), 10);
    border-color: lighten(old.$lightGrayTen, 10);
    color: $white;
  }
  .pulseLoaderDot {
    background-color: old.$lightGrayTen;
  }

  .caretContainer {
    height: 100%;
    margin-left: 10px;

    .dropdownCaret {
      * {
        color: old.$lightGrayTen;
        fill: old.$lightGrayTen;
      }
    }
  }
}
.lightGrayOutlineTheme .text {
  &:hover {
    color: $white;
  }
}

// Gray outline themes
.grayOutlineTheme {
  border-color: old.$blueMist !important;
  background-color: rgba(old.$blueMist, 0) !important;
  color: old.$blueMist !important;

  &.separator {
    background-color: darken(old.$green, 10);
  }

  &:hover {
    background-color: darken(rgba(old.$blueMist, 0.5), 10);
    border-color: darken(old.$blueMist, 10);
    color: $white;
  }

  &:active {
    background-color: lighten(rgba(old.$blueMist, 0.5), 10);
    border-color: lighten(old.$blueMist, 10);
    color: $white;
  }
  .pulseLoaderDot {
    background-color: old.$darkGray;
  }
}
.grayOutlineTheme .text {
  &:hover {
    color: $white;
  }
}

.disabledGrayOutlineTheme {
  border-color: old.$blueMist !important;
  background-color: rgba(old.$blueMist, 0) !important;
  color: old.$blueMist !important;

  &.dropdownDouble {
    &:first-of-type {
      border-right: 0;
    }
    &:last-of-type {
      border-left: 0;
    }
  }

  &.separator {
    background-color: old.$blueMist;
  }

  &:hover {
    border-color: old.$blueMist;
    background-color: rgba(old.$blueMist, 0);
    color: old.$blueMist;
    cursor: not-allowed;
  }

  &:active {
    border-color: old.$blueMist;
    background-color: rgba(old.$blueMist, 0);
    color: old.$blueMist;
  }
}
.disabledGrayOutlineTheme .text {
  &:hover {
    color: old.$blueMist;
  }
}

// None themes

.noneTheme {
  border-color: rgba(old.$darkBlue, 0) !important;
  background-color: rgba(old.$darkBlue, 0) !important;
  color: old.$darkBlue !important;

  &.separator {
    background-color: darken(rgba(old.$darkBlue, 0.1), 10);
  }

  &:hover {
    background-color: darken(rgba(old.$darkBlue, 0.1), 10);
    border-color: darken(rgba(old.$darkBlue, 0.1), 10);
  }

  &:active {
    background-color: lighten(rgba(old.$darkBlue, 0.1), 10);
    border-color: lighten(rgba(old.$darkBlue, 0.1), 10);
  }
}
.noneTheme .text {
  &:hover {
    color: $white;
  }
}

// Disabled default theme
.disabledDefaultTheme {
  background-color: $white !important;
  border-color: old.$blueMist !important;
  color: old.$blueMist !important;

  &.dropdownDouble {
    &:first-of-type {
      border-right: 0;
    }
    &:last-of-type {
      border-left: 0;
    }
  }

  &.separator {
    background-color: old.$blueMist;
  }

  &:hover {
    background-color: $white;
    color: old.$blueMist;
    border-color: old.$blueMist;
    cursor: not-allowed;

    .dropdownCaret {
      * {
        color: old.$blueMist;
        fill: old.$blueMist;
      }
    }
  }

  &:active {
    background-color: $white;
    border-color: old.$blueMist;
    color: old.$blueMist;
  }

  &.webAppTheme {
    background-color: old.$grayWebApp !important;
    border-color: old.$grayWebApp !important;
    color: $white !important;

    &.dropdownDouble {
      &:first-of-type {
        border-right: 0;
      }
      &:last-of-type {
        border-left: 0;
      }
    }

    &.separator {
      background-color: old.$grayWebApp;
    }

    &:hover {
      background-color: old.$grayWebApp;
      color: $white;
      border-color: old.$grayWebApp;
      cursor: not-allowed;

      .dropdownCaret {
        * {
          color: $white;
          fill: $white;
        }
      }
    }

    &:active {
      background-color: old.$grayWebApp;
      border-color: old.$grayWebApp;
      color: $white;
    }
  }
}
.disabledDefaultTheme .text {
  &:hover {
    color: old.$blueMist;
  }
}
.disabledDefaultTheme .dropdownCaret {
  * {
    color: old.$blueMist;
    fill: old.$blueMist;
  }
}
