@use "ui-kit-old" as *;

.actionsContainer {
  position: absolute;
  left: 0;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  padding: 0px;

  max-height: 0px;
  opacity: 0;
  height: auto;

  z-index: 199;
  background-color: rgba($white, 0);

  transition: max-height .2s, opacity .2s, padding .2s;

  pointer-events: none;

  &.visible {
    opacity: 1;
    max-height: 50px;
    pointer-events: all;
  }

  &.positionBottom {
    bottom: -4px;
    transform: translateY(100%);
  }

  &.positionTop {
    top: -4px;
    transform: translateY(-100%);
  }

  &.anchorRight {
    left: unset;
    right: 0;
  }

  .button {
    margin: 0!important;
    width: auto;
    flex: 0 0 auto;
    overflow: visible!important;
    box-shadow: 0px 1px 3px rgba($black, .16)!important;

    opacity: 0;

    transition: opacity .2s;

    &:not(:last-child) {
      margin-right: 4px!important;
    }

    &.visible {
      opacity: 1;
    }
  }
}