@use "ui-kit-old" as *;

$positioningMargin: 2px;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  position: relative;

  width: 130px;

  input {
    cursor: pointer;
  }

  .calendarContainer {
    user-select: none;
    position: absolute;
    top: 35px;
    z-index: 100;
    background: $white;
    border-radius: 8px;
    box-shadow: $boxShadow;
  }

  .oldCalendarContainer {
    top: 0;
    box-shadow: unset;
  }

  .rightPos {
    left: calc(100% + #{$positioningMargin});
  }

  .leftPos {
    right: calc(100% + #{$positioningMargin});
  }

  .topPos {
    bottom: calc(100% + #{$positioningMargin});
  }

  .bottomPos {
    top: calc(100% + #{$positioningMargin});
  }

  .inputContainer {
    width: 185px;
  }

  .newInput {
    display: none;
  }

  .newInputIcon {
    border: 0;
    padding-right: 4px;
    padding-left: 12px;
  }

  .bottomRightPos {
    top: calc(100% + #{$positioningMargin});
    left: 0;
  }
}
