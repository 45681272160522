@use "ui-kit" as *;

.container {
  border-radius: 3px;
  background-color: $backgroundGrayTwo;
  border: 1px solid $strokeGrayTwo;
  padding: 14px;

  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  margin-bottom: 9px;

  height: auto;
  min-height: 82px;
  box-sizing: border-box;

  .leftWrapper {
    flex: 1 1 50%;
  }
  .rightWrapper {
    height: 100%;
    flex: 0 0 100px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-flow: row nowrap;
  }

  .infoWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    p {
      @include resetMarginPadding;
      font-family: $fontSofia;
      font-size: 14px;
      font-weight: 600;
      color: $dark;
    }
  }

  .note {
    @include resetMarginPadding;
    font-family: $fontSofia;
    font-size: 14px;
    font-weight: 400;
    color: $dark;
  }

  .dropdownToggle {
    transform: scale(1);
    transition: transform 0.2s;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}
