@use "colors-legacy" as *;

.emailForm {
  position: relative;
  padding-top: 25px;

  > div:first-of-type {
    .header {
      position: relative;

      > hr {
        margin: 10px 0;
      }

      .courseList {
        margin-top: 10px;
      }

      .selectPeriod {
        > div:first-of-type {
          font-size: 16px;
          margin-bottom: 5px;
        }

        > div:last-of-type {
          font-size: 13px;
          margin-bottom: 15px;
        }
      }

      .dateTime {
        display: flex;

        .datepicker {
          margin: 0 30px 5px 0;
  
          .date > div {
            font-size: 13px;
          }
      
          input {
            width: 120px;
          }
        }
      }

      .recipients {
        margin-left: auto;
      }
    }

    .body {
      margin-top: 15px;

      .label * {
        font-size: 13px;
      }

      input {
        padding: 0.25rem .75rem !important;
        font-weight: 500;
        font-size: 14px;
      }

      textarea {
        font-weight: 500;
        font-size: 14px;
      }
    }

    .footer {
      margin-top: 15px;

      .buttons {
        display: flex;
        justify-content: space-between;

        * {
          font-size: 15px;
        }
      }
    }
  }

  .closeBtn {
    font-size: 40px;
    position: absolute;
    right: 0;
    top: -25px;
    cursor: pointer;
  }

  .recipients {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    flex: 1;
    max-width: 220px;

    * {
      font-size: 13px !important;
      font-weight: 500 !important;
    }

    > div {
      display: inline-block;

      &:last-of-type {
        display: flex;
        align-items: center;
        height: 30px;
        background: $very_light_green;
        border-radius: 5px;
        padding-left: 10px;
      }
    }
  }
}

.regular {
  font-weight: 500;
}

.light {
  font-weight: 400;
  font-size: 14px;
}