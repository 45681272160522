@use "shared-legacy" as *;

$orange: $settings_orange;

.container {
  width: 100%;
  flex: 0 0 auto;
  padding: 15px 30px;
}

.name {
  font-size: 18px;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: 600;
  color: $text_color;
  text-align: center;
}

.state {
  color: $input_field_gray;
  font-size: 18px;
  font-weight: 600;
}