@use "ui-kit" as *;

.addBtnIcon {
  width: 8.33px;
  height: 8.33px;
  margin-right: 7px;
}

.add {
  font-size: 12px;
  font-weight: 500;
  color: $mainBlue;
  background-color: $backgroundGray;
  border-color: $backgroundGray;

  transform: scale(1);
  transition: transform 0.2s;

  span {
    margin-right: 5px;
  }
}

.animation {
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

.popover {
  right: 0;
  left: auto;
  transform-origin: top right;
}