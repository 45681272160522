@use "ui-kit-old" as *;

.container {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  position: relative;
  width: 200px;

  input {
    cursor: pointer;
  }
}
