@use "shared-legacy" as *;

$gray: $progress_line_gray;

.container {
  height: 45px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 45px;
  padding: 0 30px;
  box-sizing: border-box;
  user-select: none;
}

.container * {
  color: unset;
}

.icon {
  color: $gray;
  fill: currentColor;
  position: absolute;
  top: 50%;
  height: 13px;
  width: auto;
  transform: translateY(-50%);
  
  &:hover {
    cursor: pointer;
  }
}

.backIcon {
  left: 30px;
}
.forwardIcon {
  right: 30px;
}
.title {
  text-align: center;
  color: $text_color;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  line-height: 18px;
}