@use "shared-legacy" as *;
@use "ui-kit-old" as ui-kit;

.modalWrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.deletedContainer {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
}
.deletedCheckSvgWrapper {
  width: 63px;
  height: 63px;
  margin-bottom: 75px;
}
.deletedSvg {
  width: 63px;
  height: 63px;
}
.deletedTitle {
  width: auto;
  display: inline-block;
  font-size: 22px;
  font-weight: 300;

  margin: 0;
  padding: 0;
  margin-bottom: 91px;
}
.deletedTitleClubName {
  font-weight: 600;
}
.deletedOk {
  width: auto;
  display: inline-block;
  font-size: 26px;
  font-weight: 600;

  margin: 0;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
}

.tabsContainer {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
}

.membershipTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;

  span {
    width: 21vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: white;
    font-weight: $semi_bold;
  }

  svg {
    height: 30px;
    width: 30px;
    margin-right: 10px;

    path,
    circle {
      fill: $text_color_light !important;
    }
  }
}

.emptyTabContainer {
  height: 420px;
}

.uuidCopy {
  color: $text_color_light;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  font-size: 0.875rem;

  &:hover {
    color: ui-kit.$silverBlue;
  }

  .tooltip {
    background-color: ui-kit.$darkBlue;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 1px 4px -1px;
    border: 1px solid ui-kit.$darkBlue;
    border-radius: 0.25rem;
    z-index: 100;
    font-weight: 500;
    font-size: 0.875rem;
    color: ui-kit.$white;
    opacity: 1;
  }
}

.checkoutLinkCopy {
  color: $text_color_light;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  font-size: 0.875rem;

  &:hover {
    color: ui-kit.$silverBlue;
  }

  .tooltip {
    background-color: ui-kit.$darkBlue;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 1px 4px -1px;
    border: 1px solid ui-kit.$darkBlue;
    border-radius: 0.25rem;
    z-index: 100;
    font-weight: 500;
    font-size: 0.875rem;
    color: ui-kit.$white;
    opacity: 1;
  }
}
