@use "colors-legacy" as *;

.wrapper {
  padding: 25px;
  padding-top: 10px;
  width: 50%;
  max-width: 750px;
  min-width: 500px;
}

.label {
  div {
    display: inline-block;
    font-size: 24px;
    font-weight: 600;
    color: darken($semi_medium_grey, 10%);
    padding-bottom: 10px;
  }
}

.input {
  margin: 15px 0;
}

.btnGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}