@use "ui-kit-old" as *;

.container {

  .incompatibleContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: row wrap;

    .row {
      width: 100%;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 8px;

      &:not(:first-child) {
        border-top: 1px solid $silverBlue;
      }

      &:last-child {
        margin-bottom: 3px;
      }

      &:only-child {
        border-bottom: 1px solid $silverBlue;
        margin-bottom: 8px;
      }

      .iconContainer {
        flex: 0 0 33px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 4px;
        padding-bottom: 8px;

        box-sizing: border-box;

        .icon {
        }
      }

      .pillsContainer {
        flex: 1 1 100%;
        flex-flow: row wrap;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .miniPill {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: auto;
          margin-right: 8px;
          margin-bottom: 8px;
          justify-content: space-between;
          background: $activeBlue;
          color: $white;
          border-radius: 15px;
          font-size: 14px;
          height: auto;
          min-height: 20px;
          padding: 0px 10px;

          &:hover {
            cursor:  default;
            background: $activeBlueLight;
          }

          span {
            margin-top: -2px;
            font-weight: 400;
            color: $white;
          }

          .closeButton {
            margin-top: -3px;
            margin-left: 8px;
            font-weight: bold;
            cursor: pointer;

            svg {
              height: 8px;

              path {
                fill: $white;
              }
            }
          }
        }
      }
    }
  }

  .buttonsRows {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: row wrap;

    > div {
      flex: 0 0 auto;
      margin-right: 8px;
    }
  }
}
