@use "ui-kit" as *;
$bg-img: url("../../resources/images/360_login_image.jpg");

.container {
  width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;

  background-image: $bg-img;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .logo {
    height: 50px;
    width: auto;
  }

  .formWrapper {
    background-color: $white;
    border-radius: 10px;
    padding: 19px 27px;
    max-width: 500px;
    width: 100%;

    .languageSwitch {
      text-align: right;
      font-size: 12px;
      font-family: $fontRoboto;
      span {
        cursor: pointer;

        &.underline {
          text-decoration: underline;
        }
      }
    }

    .footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-flow: row nowrap;

      .errorText {
        @include resetMarginPadding;
        margin-right: auto;
        font-family: $fontRoboto;
        font-size: 14px;
        color: $red;
      }
    }
  }
}