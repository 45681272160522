@use "ui-kit" as *;

.container {
  display: contents;

  >div:last-child {
    padding-right: 20px;
  }

  .cell {
    display: grid;
    line-height: 1.5em;
    padding-left: 20px;
    padding-top: calc((50px - 1.5em) / 2);
    padding-bottom: calc((50px - 1.5em) / 2);
    grid-template-rows: 1.5em;
    align-items: start;

    >div {
      min-width: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &.open {
      grid-template-rows: minmax(1.5em, auto);

      >div {
        min-width: 0;
        text-overflow: initial;
        white-space: initial;
        overflow: initial;
      }
    }

    .paid {
      color: $accentGreen;
    }

    .notPaid {
      color: $red;
    }
  }

  .even {
    background-color: $backgroundGray;
  }

  &:hover>div {
    background-color: $lightBlue;
  }

  a {
    color: $blue;
  }
}