@use "ui-kit-old" as *;

.container {
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  flex-flow: column nowrap;

  background-color: $lightGray;
  border: 1px solid $lightGraySix;
  border-radius: 8px;
  overflow: hidden;



  .titleContainer {
    height: 28px;
    width: 100%;
    padding: 0 12px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid $lightGraySix;

    .title {
      @include resetMP;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      vertical-align: middle;
      display: inline-block;
      color: $black;
    }
  }
}