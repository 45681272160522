@use "colors-legacy" as *;
@use "sizes-legacy" as *;

.priceListing {
  margin-top: 10px;
  font-size: $default_font_size;

  .row:nth-child(2) input {
    margin-bottom: 0;
  }

  .row:nth-child(7) {
    padding-top: 0;
  }

  .row:nth-child(7), .row:nth-child(8) {
    align-items: flex-end;
    padding-bottom: 15px;

    span {
      color: $color_black;
    }
  }

  > hr {
    margin: 10px 0;
  }

  .row {
    padding: 3px 0;
    margin: 0 -10px;

    > div {
      text-align: center;
      padding: 0 10px;
    }

    .infoIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color_white;
      background: $semi_medium_grey;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      font-weight: bold;
      font-size: 11px;
      margin-right: 5px;
    }

    .text {
      font-size: $default_font_size;
      font-weight: $font_weight_semibold;
      color: transparentize($color_black, .44);
      text-align: center;
    }

    .symbol {
      color: transparentize($color_black, .59);
    }

    .firstCol {
      display: flex;
      align-items: baseline;
      margin-top: 5px;
      padding: 0 15px;

      div {
        color: transparentize($color_black, .15);
      }

      svg {
        margin-right: 5px;

        path {
          color: $semi_medium_grey;
        }
      }
    }

    input {
      font-size: $default_font_size;
      font-weight: $font_weight_default;
      padding: .25rem;
      text-align: center;
      width: 50px;
      margin: auto;
    }
  }

  .priceRounding {
    margin: 0 20px 20px;
    width: fit-content;

    input {
      font-weight: bold;
    }
  }
}
