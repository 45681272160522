@use "colors-legacy" as *;

.container {
  > div:first-of-type > div:first-of-type > div:first-of-type {
    height: 42px;
  }

  .header {
    margin-left: 20px;
    width: 100%;
    color: $color_white;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 20px;
  }
}
