@use "ui-kit-old" as *;

.container {
  width: 100%;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  .row {
    margin-left: -12px;
    width: calc(50% + 12px);

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row nowrap;
  }
}