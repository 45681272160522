@use "ui-kit" as *;

.container {
  width: 100%;
  height: auto;
  background-color: $white;
  max-height: 0;
  opacity: 0;

  padding: 0 24px !important;
  margin-bottom: 0 !important;

  transition: max-height .2s, opacity .2s, padding .2s, margin-bottom .2s;
  overflow: hidden;

  &.open {
    max-height: 800px;
    opacity: 1;
    overflow: visible;
    padding: 17px 0px !important;
    border: none;
    &:not(:last-child) {
      margin-bottom: 12px !important;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;

    .title {
      color: $black;
      font-size: 16px;
      margin: 0;
      padding: 0;
      font-weight: 700;
    }
  }
}
