@use "colors-legacy" as *;

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  
  div {
    font-weight: 500;
  }
  
  .image {
    margin-right: 7px;
    height: 20px;
    width: 20px;
  }

  &.hide {
    div {
      visibility: hidden;
    }
  }
}

