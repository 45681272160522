@use "ui-kit" as *;

.container {
  height: 100%;

  display: grid;
  position: relative;

  .showOnHover {
    pointer-events: all;
  }

  .customCell {
    pointer-events: all!important;

    .customCellText, .customCellText > div {
      cursor: pointer!important;
      @include resetMarginPadding;
      color: $blue;
    }
  }
}