@use "ui-kit-old" as *;

.container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;


  .content {
    font-size: 22px;
    margin-right: 15px;

    svg {
      width: 32px;
    }

    path {
      color: $blue;
      cursor: pointer;
    }
    &:hover path {
      color: darken($blue, 15%);
    }
  }

  .inputContainer {
    position: relative;
    user-select: none;

    input {
      height: 24px;
      user-select: none;
      cursor: pointer;
      color: transparent;
      text-shadow: 0 0 0 $blue !important;
    }

    input:hover {
      text-shadow: 0 0 0 darken($blue, 15%) !important;
    }

    input:focus:not([readonly]) {
      border: 1px solid $midGray;
    }

    input[readonly]:focus {
      border: 1px solid $lightGrayThree;
    }

    input:read-only {
      background-color: white;
    }

    &.fixedWidth {
      width: 109px;
    }

    &.w-full {
      flex: 1;
    }

    &.open {
      input {
        border-bottom-right-radius: 0;
      }
    }

    &.inputForm {
      input {
        font-size: 15px;
        color: $darkBlue;
        border-radius: 5px;
        text-shadow: none !important;

        &::placeholder {
          font-size: 13px;
          color: $silverBlue;
        }

        &:hover {
          text-shadow: none !important;
        }
      }
    }

    &.inputError {
      input {
        border-color: $orange;
      }
    }
  }

  .errorContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 100;
    top: 25px;
    left: 0;
    background-color: white;
    min-height: 28px;
    min-width: 120px;
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba($black, .17);

    p {
      @include resetMP;
      color: $orange;
      font-size: 12px;
    }
  }

  .calendarContainer {
    user-select: none;
    position: absolute;
    top: 23px;
    right: -53px;
    z-index: 100;

    div {
      border-radius: 8px;
    }
  }
}
