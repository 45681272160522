.container {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  overflow-y: auto;

  .headerText {
    > div {
      font-weight: 600;
    }
  }

  .buttonGroup {
    margin: 5px 0;
    width: 100%;

    > button {
      margin-top: 0;
      height: 30px;
    }
  }
}
