@use "abstract/colors-old" as s;
@use "ui-kit" as *;

.container {
  width: 100%;
  height: auto;
  background-color: $white;
  padding: 0 !important;
  max-height: 800px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $strokeGray;
    padding: 18px 22px 14px;

    .title {
      @include resetMarginPadding;
      color: $black;
      font-size: 16px;
      margin-right: 12px;
    }
  }
}

.addNoteContainer {
  width: 100%;
  background-color: $white;
  padding: 15px 20px 0;

  .edit {
    width: 100%;
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -20px;
    margin-top: 4px;

    &.focused {
      margin-top: 4px;
    }

    p {
      margin: 2px 11px 0;
      padding: 0;
      color: s.$gray_300;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }

    .closeEdit {
      height: 10px;
      width: 10px;
      margin-right: 5px;
      margin-top: 2px;
      cursor: pointer;
    }
  }

  .textContainer {
    margin: 0;
  }

  .inputContainer {
    textarea {
      min-height: 50px;
      max-height: 160px;
      color: black;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      border-radius: 4px;
      padding-left: 10px !important;
      padding-bottom: 10px !important;
      padding-right: 10px !important;
      resize: vertical;
      overflow-wrap: break-word;
    }

    &.editing {
      textarea {
        padding-top: 22px;
      }
    }

    &.error {
      textarea {
        border-color: s.$redError;
      }
    }
  }

  .infoText {
    padding: 10px 0;
    color: s.$gray_300;
    cursor: default;
    user-select: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    &.focused {
      color: $mainBlue;
    }
    &.error {
      color: s.$redError;
    }

    .length {
      color: s.$gray_300;
      margin-top: -14px;
      text-align: right;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;

      &.error {
        color: s.$redError;
      }
    }
  }
}

.sortBox {
  padding: 15px 20px 0;
}

.removeBox {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  position: absolute;
  margin-top: -95px;
  margin-left: -35px;
  z-index: 10;
  width: 145px;
  height: 90px;
  background-color: white;
  cursor: default;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  &.sv {
    margin-top: -95px;
    margin-left: -25px;
    width: 152px;
  }

  strong {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  .removeArea {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 13px;

    &.sv {
      padding-top: 8px;
    }
  }

  .button {
    height: 30px;
    padding-top: 1px;

    &.no {
      margin-right: 4px;
    }
    &.yes {
      margin-left: 4px;
    }
  }
}

.notesContainer {
  width: 100%;
  max-height: 310px;
  overflow-y: auto;
  margin-bottom: 3px;
  margin-top: 3px;

  .scrollElement {
    flex: 0 0 0;
    height: 0;
    width: 100%;
  }

  .note {
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    word-break: break-word;

    &.active {
      background-color: $backgroundGray;
    }

    .headerArea {
      width: 100%;
      display: flex;
      flex-direction: row;
    }

    .buttonArea {
      display: flex;
      flex-direction: row;
      margin-left: auto;

      p:first-child {
        margin-right: 15px;
      }
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: s.$gray_300;

      &.text {
        font-size: 14px;
        line-height: 18px;
        color: black;
        margin-top: 5px;
      }

      &.author {
        font-weight: 500;
        margin-right: 5px;
      }

      &.time {
        font-weight: 300;
      }

      &.button {
        color: s.$blue_400;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}
