@use "colors-legacy" as *;
@use "sizes-legacy" as *;


.wrapper {
  display: grid;
  grid-template-rows: auto;
  padding: 20px 25px 25px 35px;
  min-width: 500px;

  form {
    margin-top: 25px;
    max-width: 600px;
  }

  .percentageInput {
    width: 15%;
  }

  .golfcourseList {
    ul {
      padding: 0;
      margin-top: 10px;
      margin-left: 10px;
      list-style: none;

      .selectedCourse {
        margin-top: 10px;
        width: 275px;
        display: flex;
        justify-content: space-between;
      }

      .partnerCourse {
        margin-top: 10px;
        width: 275px;
        display: flex;
        justify-content: space-between;
        font-weight: $semi_bold;
      }

      button {
        height: 30px;
        width: 30px;
        margin: 0;
        font-weight: $bold;
        padding-left: 10px;
      }
    }
  }
}

.formButtons {
  display: flex;
  justify-content: flex-end;
  margin: 30px 0;
}

.label {
  div {
    display: inline-block;
    font-size: 24px;
    font-weight: $semi_bold;
    color: darken($semi_medium_grey, 10%);
  }
}

.golfClubForm {
  label {
    font-size: 16px !important;
  }
}

.section {
  margin-bottom: 20px;

  div {
    color: $text_color;
  }

  label {
    font-weight: $semi_bold !important;
    font-size: 16px;
    margin-bottom: 7px;
  }

  .revenueSettings label {
    font-size: 12px !important;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-items: center;
    margin-bottom: 20px;

    .title {
      div {
        font-size: 18px;
        font-weight: $semi_bold;
      }
    }
  }

  .columnContainer {
    display: flex;
    flex-direction: row;
    height: 50px;
    margin-left: 10px;

    > div {
      font-size: 15px;
      font-weight: 500;
      color: transparentize($color_black, 0.44);
    }

    .text {
      margin-right: 10px;
      padding-left: 12px;
    }

    .timePicker {
      width: 80px;
      margin-bottom: 20px;

      input {
        margin-left: 30px;
        height: 30px;
        font-size: 16px;
        color: $text_color;
      }
    }
  }
}
