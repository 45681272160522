@use "ui-kit-old" as *;

.container {
  height: 100%;
  //max-width: 768px;
  width: 100%;
  align-self: flex-start;
  padding: 16px;
  padding-right: 0;
  max-height: 100%;
  min-height: 620px;
  overflow: hidden;


  box-sizing: border-box;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;

  .headerRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding-right: 20px;
    margin-bottom: 16px;

    .title {
      @include resetMP;
  
      font-size: 18px;
      font-weight: 600;
      margin-right: 30px;
    }

    .filtersContainer {
      // Hide temporary until filters are implemented
      visibility: hidden;

      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 32px;
      background-color: $lightGray;
      border-radius: 3px;
      padding: 0 15px;

      .filterText {
        @include resetMP;
        margin-right: 16px;
        font-size: 14px;
        font-weight: 600;
        color: $darkBlue;
      }

      .checkbox {
        width: auto;
        flex: 0 0 auto;
        margin-bottom: 0;
        
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }

  .loaderContainer {
    flex: 0 0 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .noExist {
    @include resetMP;

    font-size: 18px;
    font-weight: 600;
    color: $blueMist;
    margin-bottom: 16px;
  }

  .policiesContainer {
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    padding: 0 20px 10px 0;

    overflow: auto;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column nowrap;

    .singlePolicyWrapper {
      width: 100%;
      height: auto;
      //max-width: 768px;
      box-sizing: border-box;
      margin-bottom: 8px;
    }
  }
}