@use "colors-legacy" as *;

.container {
  padding-right: 12px;
  overflow: auto;

  .elementContainer {
    background: $color_white;
    margin-bottom: 7px;
    padding: 8px 10px;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba($color_black, 0.16);
    position: relative;
    cursor: pointer;

    &.selected {
      border: 2px solid $slightly_light_blue;
      padding: 6px 8px;

      .removeIcon {
        right: 4px;
        top: 6px;
      }

      .minusIcon {
        right: 6px;
        top: 8px;
      }
    }
    &.gap {
      display: flex;
      align-items: center;
      background: $settings_orange;
      color: $color_white;
      font-size: 13px;
      font-weight: 500;
      padding: 6px 10px;

      &.active {
        border: 2px solid $slightly_light_blue;
        padding: 4px 8px;
      }

      svg {
        font-size: 14px;
        width: 14px;
        height: 14px;
        margin-right: auto;

        path {
          fill: $color_white;
        }
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .name {
      font-size: 14px;
      margin-bottom: 5px;
      width: calc(100% - 20px);

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          display: -webkit-box;
          font-size: 14px;
          font-weight: 600;
          color: $text_color_light;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          line-break: anywhere;
        }
        span:last-child {
          opacity: 0.8;
          font-size: 0.7rem;
        }
      }
    }

    .dates {
      font-size: 14px;
      font-weight: 500;
      color: $text_color;

      & > div {
        display: grid;
        grid-template-columns: 1fr 4fr;
        grid-gap: 5px;

        &:first-of-type {
          padding-bottom: 2px;
        }

        & > div:first-of-type {
          color: transparentize($color_black, 0.64);
        }

        & > div:nth-child(2) {
          color: $color_black;
        }
      }
    }

    .iconContainer {
      text-align: center;
      font-size: 22px;
      cursor: pointer;

      &.remove {
        path {
          color: $medium_orange;
        }
      }

      &:hover {
        path {
          color: $medium_blue;
        }
      }

      &.locked {
        cursor: auto;

        path {
          color: $medium_orange;
        }
      }
    }

    .removeIcon {
      position: absolute;
      width: 18px;
      height: 18px;
      right: 6px;
      top: 8px;
    }

    .minusIcon {
      position: absolute;
      width: 15px;
      height: 15px;
      right: 8px;
      top: 10px;
    }
  }
}
