@use "colors-legacy" as *;

.wrapper {
  padding: 10px 0 0 10px;

  > div {
    margin: 0 !important;
  }

  .label {
    margin-bottom: 20px;

    div {
      font-size: 20px;
      font-weight: 600;
      color: transparentize($text_color, 0.56);
    }
  }

  .formLabel {
    font-size: 15px;
  }

  .formLabel2 {
    font-size: 15px;

    * {
      color: $danger_red;
    }
  }

  .logoType {
    text-align: center;

    .formLabel {
      font-size: 12px;
    }
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    * {
      font-weight: 500;
    }

    > div:last-of-type {
      width: 140px;

      span div {
        font-size: 14px;
      }
    }
  }

  .bgColor {
    display: flex;
    height: 35px;
    border: 1px solid $light_grey;
    border-radius: 4px;
    cursor: pointer;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100%;

      &:first-of-type {
        border-radius: 4px 0 0 4px;
      }
    }
  }

  .colorPicker {
    position: absolute;
    width: 100%;

    > div {
      width: 100% !important;
    }
  }

  .actionBtn {
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
  }

  .prevContainer {
    height: 100%;

    .preview {
      display: flex;
      justify-content: space-between;
      height: 700px;
      width: 720px;
      padding-right: 30px;

      > div {
        width: 48.5%;
        background: $color_white;
        box-shadow: 0px 0px 6px transparentize($color_black, 0.71);
        border-radius: 20px;
        height: 100%;

        &:first-of-type {
          display: flex;
          flex-direction: column;

          .header {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 70px;
            border-radius: 20px 20px 0 0;

            img {
              height: 50%;
              margin-top: 5px;
            }
          }

          .banner {
            height: 150px;
            position: relative;
            background-color: $light_grey;
            background-repeat: no-repeat;
            background-size: cover;

            > div {
              position: absolute;
              bottom: 10px;
              width: 100%;
              display: flex;
              justify-content: space-around;

              > div {
                background: white;
                border-radius: 15px;
                text-align: center;
                padding: 5px 30px;
                width: 40%;

                * {
                  font-size: 12px;
                  font-weight: 500;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }
            }
          }

          .body {
            flex: 1;
            background-image: url('../../../../resources/images/settings_back_layer.png');
            background-size: contain;
            margin: 10px 10px 0;
          }

          .footer {
            display: flex;
            align-items: center;
            justify-content: space-around;
            height: 55px;

            .footerItem {
              width: 30px;
              height: 30px;
              border-radius: 5px;
              background: $very_light_green;
            }

            .footerIcon {
              display: flex;
              flex-direction: column;
              align-items: center;

              span {
                font-size: 9px;
                font-weight: 500;
                color: $tee_sheet_blue;
              }
            }
          }
        }

        &:last-of-type {
          display: flex;
          flex-direction: column;
          overflow-y: auto;

          .header {
            position: relative;
            text-align: center;
            padding-top: 60px;
            font-size: 20px;

            span {
              position: absolute;
              top: -15px;
              left: 15px;
              font-size: 50px;
            }
          }

          .body {
            flex: 1;
            padding: 50px 25px;
            white-space: pre-line;
            line-height: 22px;
            word-break: break-word;
          }

          .footer {
            padding-bottom: 50px;
            text-align: center;
            font-size: 14px;

            > div,
            span {
              display: inline-block;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}
