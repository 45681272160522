@use "colors-legacy" as *;

.container {
  padding: 10px 0 0 10px;
  margin: 20px 0 0 20px;

  .title > div {
    font-size: 16px;
    font-weight: 600;
    color: $text_color_light;
  }

  form {
    max-width: 250px;
    margin-top: 30px;
    font-size: 15px;

    label {
      margin-bottom: 5px;
    }

    .formButtons {
      width: 100%;
    }
  }
}