@use "shared-legacy" as *;
@use "colors-legacy" as *;
@use "ui-kit-old" as *;

.container {
  width: 100%;
  height: 650px;
  overflow-y: auto;
  padding: 16px;
  padding-bottom: 5px;
  flex: 1 0 auto;

  .periodContainer{
    display: grid;
    grid-template-columns: repeat(2, minmax(130px, auto)) 1fr;
    grid-template-rows: auto 1fr;
    grid-auto-flow: column;
    column-gap: 20px;
    margin-bottom: 13px;
    width: auto;

    .toggleEndDate {
      grid-area: 2 / 3;
      margin-bottom: 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    // > div:not(:last-child) {
    //   margin-right: 20px;
    // }

    .dateInput {
      min-width: 130px;
    }
  }
}
.descriptionLabelRow {
  width: 85%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.extraSettingsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
  box-sizing: border-box;
}

.gitSyncContainer {
  flex: 0 0 50%;
  width: 50%;
  margin-bottom: 0px !important;
  padding-top: 14px;
}

.spacing {
  margin-bottom: 20px;
}

.toggleWrapper {
  width: 140px;
}

.membershipFieldsContainer {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .membershipField {
    display: flex;
    flex-direction: column;
    margin: 0 10px 20px 0;

    .membershipInputWithSuffix {
      display: flex;
      align-items: center;

      input {
        text-align: right;
        margin-right: 7px;
        width: 55px;
      }

      label {
        font-size: 15px;
        margin: 0;
      }
    }

    .emailToMemberLabelContainer {
      display: flex;
      align-items: center;

      .emailToMemberTooltipContainer {
        display: flex;
        align-items: center;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.migrateActionContainer {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  button {
    width: fit-content;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 14px;

    svg {
      width: 16px;
      height: 16px;
      stroke: $grayDarkWebApp;
      margin-left: 8px;
    }
  }

  .migrateInfoContainer {
    color: #A6ACB5;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    flex: 1;
    span {
      color: $orange;
      margin-right: 3px;

      &.completed {
        color: $green;
      }
    }
  }
}
