@use "colors-legacy" as *;
@use "sizes-legacy" as *;

.container {
  .nameContainer {
    height: 50%;
    min-width: 300px;

    &:hover {
      cursor: pointer;
    }

    .textContainer {
      .container {
        position: relative;
        height: 50px;
        margin-left: 40px;
        text-align: center;

        .listContainer {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          max-height: 200px;
          border: 1px solid $light_grey;
          border-top: none;
          background: white;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          overflow: scroll;
          overflow-x: hidden;
          overflow-y: auto;
          z-index: 100;

          .list {
            height: 100%;
            overflow: scroll;
            overflow-x: hidden;
            overflow-y: auto;

            .listItem {
              height: 35px;
              font-size: 22px;
              display: block;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              padding: 0 10px;
              cursor: pointer;

              &:hover {
                background: $very_light_grey;
              }
            }
          }
        }
      }

      input {
        font-size: 24px;
        font-weight: $bold;
        color: $tee_sheet_blue;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 35px;
        padding-bottom: 10px;
      }

      i {
        padding: 3px;
        font-size: 18px;
        top: 22px;
        right: 10px;

      }
    }
  }

  .courseText {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .timezone {
    margin-top: 10px;
    margin-left: 0;

    >div {
      svg {
        margin-top: 0;
      }
    }
  }
}