@use "colors-legacy" as *;


.content {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 20px 1fr;
  grid-gap: 10px;
  border-radius: 4px;
  overflow: auto;
  padding: 10px 0 0 10px;

  .title {
    div {
      display: inline-block;
      font-size: 24px;
      font-weight: 600;
      padding: 5px 0 5px 80px;
      color: darken($semi_medium_grey, 10%);
    }
  }
}
