@use "ui-kit-old" as *;

.container {

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: flex-start;

  .inputContainer {
    display: flex;
    flex-direction: column;
    margin: 2px 6px 0 0;

    .label {
      color: $white;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 5px;
    }

    &.slots {
      div {
        background-color: transparent;
      };
      width: 45px;
    }

    .statusContainer {
      * {
        color: $white;
      }
    }

    .dropdown {
      &:hover {
        cursor: pointer;
      }
    }


    > div {
      display: inline-block;
      margin-bottom: 0;

      input {
        height: 24px;
        font-size: 14px;
        color: $white;
        padding-right: 0;

        &#slotsInput {
          height: 26px;
        }
      }

      > svg {
        top: 8px;
        * {
          fill: $white;
        }
      }
    }
  }

  .actionButton {
    margin: 0;
    height: 26px;
    margin-right: 15px;

    pointer-events: none;
    opacity: 0;

    transition: opacity .2s;

    &.cancelButton {
      color: $white;
    }

    &.saveButton {
    }

    &:first-of-type {
      margin-left: 10px;
    }

    &.visible {
      pointer-events: all;
      opacity: 1;
    }
  }
}
