@use "ui-kit-old" as *;

.subtitle {
  @include resetMP;

  font-size: 12px;
  font-weight: 400;
  color: $darkBlue;

  margin-bottom: 8px;

  display: block;
  vertical-align: middle;
  line-height: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}