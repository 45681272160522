@use "colors-legacy" as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 28px;
  height: 40px;
  width: 40px;
  cursor: pointer;

  &:hover {
    path {
      color: $price_color_red_medium;
    }
  }

  path {
    color: $market_grey;
  }
}