@use "ui-kit-old" as oldKit;
@use "ui-kit" as *;

.container {
  width: auto;
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;

  .icon {
    margin-right: 7px;
  }

  .disabled {
    background-color: oldKit.$gray !important;
    pointer-events: none;
    user-select: none;
  }

  .disabledIcon {
    path {
      fill: oldKit.$darkGray;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  .box {
    display: none;
    position: absolute;
    top: calc(100% - 10px);
    left: 0;

    transform: scale(0);
    transform-origin: top left;
    pointer-events: none;

    transition:
      opacity 0.2s,
      transform 0.2s;

    background-color: $white;
    box-shadow: 0px 4px 24px rgba($black, 0.1);
    border-radius: 4px;
    width: 292px;
    height: auto;
    padding: 14px 21px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column nowrap;
    box-sizing: border-box;

    z-index: 2;

    &.visible {
      display: block;
      transform: scale(1);
      pointer-events: all;
    }

    >div {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    .error {
      @include resetMarginPadding;
      font-size: 12px;
      color: $red;
    }

    .buttonRow {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 8px;

      >button {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  .dropdown {
    .groupTitle {
      font-style: italic;
      font-weight: 500;
      font-size: 12px !important;
    }

    .disabledOptions>p {
      color: #9f9f9f !important;
    }
  }
}