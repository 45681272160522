@use "colors-legacy" as *;


.content {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 1fr;
  grid-gap: 10px;
  padding: 10px 20px;
  overflow: hidden;
  position: relative;

  .title {
    div {
      margin-left: 5px;
      font-size: 25px;
      font-weight: 500;
    }
  }

  .modalWrapper {
    position: absolute;
    top: 10px;
    right: 20px;
    bottom: 10px;
    left: 20px;
    z-index: 9;
  }
}
