@use "colors-legacy" as *;

$header_height: 48px;
$day_height: 28px;
$day_width: 32px;

$hover_background: #F2F2F2;

.container {
  display: inline-block;
  overflow: hidden;
  z-index: 100;
  background: white;
  border-radius: 8px 8px 0 0;

  .headerContainer {
    height: 48px;
    background: white;
    display: grid;
    grid-template-columns: 48px 1fr 48px;

    .arrowContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 16px !important;

      .arrow {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $color_black;
        font-size: 16px;

        path {
          color: $color_black;
        }

        &:hover {
          path {
            color: $color_black;
          }
        }
      }
    }

    .monthContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .month {
        font-size: 16px;
        text-align: center;
        color: $color_black;
        padding-bottom: 2px;
      }
    }
  }

  .calendarContainer {
    background: $color_white;

    .textUpperCase {
      text-transform: uppercase;
    }

    .calendarLabels {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      background: $color_white;
      color: $color_black;
      border-bottom: 1px solid hsla(0, 0%, 89.4%, .6);
      border-radius: 0px;
      text-align: center;
      padding-bottom: 24px;
      padding-left: 8px;
      padding-right: 8px;
      height: 22px;
      font-size: 16px !important;

      p {
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        margin-block-start: 0em;
        margin-block-end: 0em;
      }

      div {
        font-size: 14px;
        text-align: center;
        font-weight: 600;
      }
    }

    .calendarDays {
      padding: 10px;
      display: grid;
      grid-template-columns: repeat(7, 45px);
      grid-template-rows: repeat(6, 45px);
      font-size: 16px !important;

      .dayPanel {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-bottom: 1px;
        cursor: pointer;
        color: $color_black;

        &:hover {
          background: $very_light_green;
          border-radius: 360px;
        }

        &.inactive {
          div {
            color: $disabled;
          }
        }

        &.disabled {
          cursor: default;
          div {
            color: $disabled;
          }
        }

        &.selected {
          background: $darkBlue;
          div {
            color: $color_white;
          }
          border: 1px solid transparentize($color_black, .7);
          border-radius: 50%;
        }

        &.today {
          background-color: rgba($disabled, 0.2);
          border-radius: 50%;
          border: 0;
          .dayText {
            font-weight: 500;
            color: $dark_brown;
          }
        }

        .dayText {
          text-align: center;
        }
      }
    }
  }

}

.container {
  &.blueStyle {
    margin-top: 3px;
    border: 1px solid $tee_sheet_blue;
    .headerContainer {
      background: white;
      .month {
        color: $tee_sheet_blue;
      }
      .arrow {
        path {
          color: $tee_sheet_blue;
        }
      }
    }
    .calendarContainer {
      background: white;
      .calendarLabels {
        background: white;
        border-bottom: 1px solid transparentize($light_grey, .4);
      }
      .dayPanel {
        &:hover {
          background: transparentize($tee_sheet_blue, .8);
          border-radius: 50%;
          border: none;
          div {
            color: $tee_sheet_blue;
          }
        }

        &.selected {
          background: transparentize($tee_sheet_blue, .8);
          border-radius: 30px;
          border: none;
          div {
            color: $tee_sheet_blue;
          }
        }
      }
    }
  }
}
