@use "ui-kit" as *;

.container {
  width: 100%;

  box-sizing: border-box;

  transition: background-color 0.2s;
  border: 1px solid;

  .inner {
    width: 100%;
    height: 100%;
    border-radius: 2px;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: column nowrap;

    padding: 6px;

    box-shadow: 5px 0px 14px rgba($iconGray, 0);

    transition:
      background-color 0.2s,
      box-shadow 0.2s;

    user-select: none;

    position: relative;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }

    &.hoverStyle {
      box-shadow: 5px 0px 14px rgba($iconGray, 0.3);
    }

    .headerSection {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 3px;
    }

    .header {
      width: 100%;
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      justify-content: space-between;
      box-sizing: border-box;

      .time {
        flex: 0 0 auto;
        @include resetMarginPadding;
        color: $white;
      }

      .left {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-flow: row wrap;
      }

      .iconsContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
      }


      .image {
        width: 16px;
        height: 16px;
      }
    }

    .tag {
      height: 15px;
      background-color: $white;
      border-radius: 2px;
      display: flex;
      align-items: center;
      padding: 0 5px;

      &:not(:last-child) {
        margin-right: 4px;
      }

      p {
        @include resetMarginPadding;
        color: $dark;
        font-size: 10px;
        font-family: $fontRoboto;
        font-weight: 500;
      }
    }

    .name {
      @include resetMarginPadding;
      color: $white;
      height: auto;
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      word-break: break-word;
      white-space: normal;
    }

    .name:hover {
      display: inline;
      text-overflow: clip;
      overflow: visible;
    }

    .footer {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-flow: column;

      .price {
        align-self: flex-start;
        flex: 0 0 auto;
        @include resetMarginPadding;
        color: $white;
        justify-self: flex-start;
      }

    }
  }

  &.selectionInProgress {
    .inner {
      background-color: rgba($strokeGray, 0.5) !important;

      .member {
        background-color: $strokeGray;
      }

      &:hover {
        cursor: not-allowed;
      }
    }

    p {
      color: $iconGray !important;
    }
  }
}
