@use "ui-kit-old" as *;

.container {
  display: grid;
  grid-template-columns: 1fr minmax(30px, auto) minmax(20px, auto);
  grid-auto-rows: auto;
  column-gap: 10px;
  row-gap: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 0.75rem;

  > div {
    color: $midGray;

    &:nth-child(1) {
      justify-self: start;
      width: 100%;
    }
  }

  .button {
    justify-self: start;
    min-width: 80px;
    height: 20px;
    column-gap: 1ch;
    font-size: 0.75rem;
    grid-column-start: 1;

    color: $darkBlue;
    font-weight: 600;

    svg,
    path {
      color: $blue;
    }
  }
}
