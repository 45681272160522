@use "colors-legacy" as *;
@use "sizes-legacy" as *;

.priceControl {
  margin-bottom: 20px;

  .createBtn {
    float: right;
  }

  .selectPeriod {
    .title {
      display: flex;
      align-items: center;
      margin-right: 15px;

      svg {
        font-size: $default_font_size;
        margin: 5px 5px 0 5px;
        cursor: pointer;

        path {
          color: $slightly_light_blue;
        }
      }
    }

    input {
      font-size: 18px;
      font-weight: bold;
      color: transparentize($color_black, .39);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    
    i {
      top: 13px;
    }

    .formGroup {
      padding-left: 10px;

      .text {
        font-size: $default_font_size;
        color: transparentize($color_black, .5);
      }

      input {
        font-size: $default_font_size;
        font-weight: $font_weight_default;
        background-color: transparentize($light_grey, .88);
        border: 1px solid transparentize($color_black, .88);
        border-radius: 5px;
        padding: .25rem;

        &:focus {
          text-align: left;
        }
      }
      
      .datepicker {
        width: 100%;
      }
    }
    
    .dates {
      font-size: 13px;
      font-weight: bold;
      margin-left: 10px;
    }
  }

  .btnGroup {
    text-align: right;

    > div {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
  }

  .btnGroup2 {
    display: flex;
    justify-content: space-between;
  }
}