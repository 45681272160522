@use "colors-legacy" as *;

.container {
  position: relative;

  &.open > div > div {
    margin-bottom: 2px;
  }

  .searchInput {
    margin-top: 10px;

    > div > div:first-of-type {
      > div {
        background: unset;
  
        &:first-of-type {
          padding: 0;
        }
      }
  
      > input {
        padding: 5px;
      }
    }
  }

  .listContainer {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid $light_grey;
    border-top: none;
    background: $color_white;
    box-shadow: 0px 1px 6px transparentize($color_black, 0.71);
    z-index: 1000;

    .list {
      height: 100%;
      overflow: scroll;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 200px;

      .listItem {
        font-size: 14px;
        font-weight: 400!important;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          background: $medium_light_blue;
        }
      }
    }
  }
}