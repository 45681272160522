.content {
  height: 100%;
  display: grid;
  position: relative;

  .title {
    font-size: 20px;
  }

  .noAccess {
    div {
      font-weight: bold;
      font-size: 22px;
    }
  }  
}